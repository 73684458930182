import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {cancelDeposit, fetchDepositTransaction} from '../../reducers/tools/Transaction'
import ModalApp from "../../components/Modal/ModalApp";
import moment from 'moment';
import ConfirmDeposit from '../../components/Transaction/ConfirmDeposit';
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";

const Deposit = ({status}) => {
    const dispatch = useDispatch();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);

    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });
    const modalRef = useRef(null);

    const depositList = useSelector((state) => state.trans.depositList);

    useEffect(() => {
        dispatch(fetchDepositTransaction(1, 10, '', '', status));
    }, [dispatch])

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }

    function onCancel(id) {

        if (window.confirm("ยืนยันการยกเลิก")) {
            dispatch(cancelDeposit(id, 1, 15, '', ''));
        }
    }


    return (

        <div className="table-responsive">
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />
            <table className="table table-sm table-striped table-bordered">
                <thead>
                <tr>
                    <th width={140}>วันเวลา</th>
                    <th>ผู้ใช้งาน</th>
                    <th>รายละเอียด</th>
                    <th className="text-right">จำนวน</th>
                    <th>สถานะ</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {depositList.data.length > 0 ? depositList.data.map(item => (
                    <tr key={item.id}>
                        <td className="text-center"><Moment format="YYYY-MM-DD HH:mm">{item.txnTime}</Moment></td>
                        <td>{item.userId}</td>
                        <td>
                            <div><strong>จากบช :</strong> {item.fromAccId.fullName}</div>
                            <div><strong>เข้าบช :</strong> {item.toAccId.fullName}</div>
                            <div><strong>หมายเหตุ :</strong> {item.note}</div>
                        </td>
                        <td className="text-right">
                            <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            {item.status === 'success' &&
                            <span className="trans-status badge badge-pill badge-success">Success</span>}

                            {item.status === 'pending' &&
                            <span className="trans-status badge badge-pill badge-warning">Pending</span>}

                            {item.status === 'canceled' &&
                            <span className="trans-status badge badge-pill badge-danger">Cancel</span>}

                            {item.status === 'failed' &&
                                <span className="trans-status badge badge-pill badge-danger">Failed</span>}

                            {(item.status !== 'pending' || item.status !== 'success') ??
                            <span className="trans-status badge badge-pill badge-danger">{item.status}</span>}
                        </td>
                        <td>
                            {(item.status === 'pending' || item.status === 'failed') && (
                                <>
                                    <button className="btn btn-success btn-block mb-2 btn-sm" onClick={() =>
                                        onOpenModalApp(
                                            {
                                                title: "ยืนยันรายการ",
                                                size: "sm",
                                            },
                                            <ConfirmDeposit pageNumber={activePage} itemPerPage={itemPerPage} start={moment(selectedStartDate).format()} end={moment(selectedEndDate).format()} txnId={item.id} username={item.userId} amount={item.amount}/>
                                        )
                                    }>
                                        Confirm
                                    </button> <button className="btn btn-danger btn-block btn-sm" onClick={e => onCancel(item.id)}>Cancel</button>
                                </>)}

                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default Deposit;
