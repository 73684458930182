import React, {useEffect, useState} from 'react';
import SidebarItem from "./SidebarItem";
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchDepositePendingCount,
    fetchDepositTransaction, fetchWithdrawPendingCount,
    fetchWithdrawTransaction, getDepositTotal
} from "../../reducers/tools/Transaction";
import LocalStorageService from "../../services/LocalStorageService";
import moment from "moment";

const localStorageService = LocalStorageService.getService()
const Sidebar = ({opened, routes, setOpenSideBar, openSideBar}) => {
    const me = localStorageService.getMe();
    const dispatch = useDispatch();
    const history = useHistory()

    const location = useLocation();
    const [activeRoute, setActiveRoute] = useState(undefined);
    const [isReport, setIsReport] = useState(false);
    const setActiveRouter = (index) => {
        setActiveRoute(index)
    }

    useEffect(() => {
        dispatch(fetchDepositePendingCount());
        dispatch(fetchWithdrawPendingCount());
    }, [dispatch])

    const withdrawCount = useSelector((state) => state.trans.withdrawPendingAmount);
    const depositCount = useSelector((state) => state.trans.depositPendingAmount);

    useEffect(() => {
        history.listen((location) => {
            console.log(`You changed the page to: ${location.pathname}`)
            setOpenSideBar(false)
        })
    }, [history])

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let foo = params.get('report');
        if (foo) {
            setIsReport(true)
        }

    }, [window.location.search])

    const handleSetOpenSideBar = (e) => {
        e.preventDefault()
        setOpenSideBar(openSideBar ? false : true)
    }

    const menu = (
        <div className={`sidebar sidebar-dark sidebar-main sidebar-expand-md ${openSideBar ? `active` : ``}`}>
            <div className="sidebar-mobile-toggler text-center">
                <a href="/" onClick={e => handleSetOpenSideBar(e)} className="sidebar-mobile-main-toggle">
                    <i className="icon-arrow-left8"></i>
                </a>
                {/*MENU*/}
            </div>
            <div className="sidebar-content">
                <div className="sidebar-user">
                    <div className="card-body">
                        <div className="media">
                            <div className="mr-3">
                                <a href="/"><img src="/images/avatar.png" width="38" height="38" alt="" className="rounded-circle"/></a>
                            </div>
                            <div className="media-body">
                                <div className="media-title font-weight-semibold">{me?.fullName}</div>
                                <div className="font-size-xs opacity-50">
                                    <i className="icon-pin font-size-sm"></i> Role : {me?.role}
                                </div>
                            </div>
                            <div className="ml-3 align-self-center">
                                <a href="/" className="text-white"><i className="icon-cog3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-sidebar-mobile">
                    <ul className="nav nav-sidebar" data-nav-type="accordion">
                        {routes.map((route, index) => {
                                const checkUri = ((location.pathname.indexOf(route.path) > -1 && route.path !== '/') || route.path === location.pathname)
                                let isCurrentPath = checkUri ? true : false;
                                if (isReport) {
                                    isCurrentPath = (typeof route.report != 'undefined' && route.report && checkUri) ? true : false
                                }
                                return (
                                    <SidebarItem
                                        withdrawCount={withdrawCount}
                                        depositCount={depositCount}
                                        key={index}
                                        index={index}
                                        route={route}
                                        activeRoute={activeRoute}
                                        currentPath={isCurrentPath}
                                        setActiveRouter={setActiveRouter}
                                    />
                                );
                            }
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );


    return menu;
};


export default Sidebar;

