import React, {createContext, useContext, useReducer} from "react";
import {ROLE_ACTION_STATUS, ROLE_ADD, ROLE_EDIT, ROLE_FETCH, ROLE_REMOVE} from "../actions/type";
import RoleService from "../services/RoleService";
import RoleReducer from "../reducers/RoleReducer";
import {ModalContext} from "./ModalContext";

const initialState = {
    roles: [],
    actionStatus: ""
}

export const RoleContext = createContext(initialState);

export const RoleProvider = ({children}) => {
    const [state, dispatch] = useReducer(RoleReducer, initialState);
    const {dispatchModal} = useContext(ModalContext)

    function getRoles() {
        RoleService.getAll().then(response => {
            console.log(response.data);
            dispatch({
                type: ROLE_FETCH,
                payload: response.data
            });

        }).catch(e => {
            console.log(e);
        });

    }

    function getRole(){

    }


    function removeRole(id) {
        dispatch({
            type: ROLE_REMOVE,
            payload: id
        });
    }


    function addRole(data) {
        RoleService.create(data).then(response => {
            dispatch({
                type: ROLE_ADD,
                payload: response.data
            });
            dispatch({
                type: ROLE_ACTION_STATUS,
                payload: 'role_add_success'
            });
            closeModal()
        }).catch(e => {
            dispatch({
                type: ROLE_ACTION_STATUS,
                payload: 'role_add_error'
            });
        })

    }

    function editRole(role) {
        dispatch({
            type: ROLE_EDIT,
            payload: role
        });
    }

    /*Utility*/
    function closeModal() {
        dispatchModal({
            type: 'MODAL_CLOSE',
            payload: true
        })
    }


    return (
        <RoleContext.Provider
            value={{
                roles: state.roles,
                actionStatus: state.actionStatus,
                removeRole,
                addRole,
                editRole,
                getRoles
            }}
        >
            {children}
        </RoleContext.Provider>
    );

}
