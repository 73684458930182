import {createSlice} from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
    name: "GlobalStateSlice",
    initialState: {
        loadingRedux: 0,
    },
    reducers: {
        setOnLoading: (state, action) => {
            // alert(1)
            return {
                ...state,
                loadingRedux: 1,
            }
        },
        setOnSuccess: (state, action) => {
            return {
                ...state,
                loadingRedux: 2,
            }
        },
        setOnWait: (state, action) => {
            return {
                ...state,
                loading: 0,
            }
        },
    },
});
export default GlobalSlice;
