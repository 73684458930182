import {Link, withRouter} from "react-router-dom";
import React from "react";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

const Dashboard = () => {
    return (
        <>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>Dashboard</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> แดชบอร์ด</Link>
                        </div>
                        <a href="/" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>รายการ รอเติมเงิน</h4>
                            </div>
                            <Deposit status={"failed,pending"}/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4>รายการ รอถอนเงิน</h4>
                            </div>
                            <Withdraw status={"failed,pending"}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Dashboard)
