import React, {useContext, useEffect, useState} from "react";
import {
    ChangeSettingDeposit,
    ChangeSettingWithdraw,
    GetDepositSetting,
    GetWithdrawSetting
} from "../../reducers/tools/MobileApp";
import {useDispatch, useSelector} from "react-redux";
import {ModalContext} from "../../context/ModalContext";
import {Modal} from "react-bootstrap";

const SettingWithdraw = ({appId}) => {
    const initData = {
        min: 0,
        max: 0,
        maxPerDay: 0,
        maxTimesPerDay: 0,
    }
    const dispatch = useDispatch();
    const [data, setData] = useState(initData)
    const {handleClose} = useContext(ModalContext);
    const handleData = (e) => {
        const name = e.target.name
        let value = e.target.value * 1
        // value = name == "maxTimesPerDay" ? parseInt(value) : parseFloat(value)
        // console.log(parseFloat(value))
        setData({
            ...data,
            [name]: value
        })
    }

    const handleCheckNumber = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    const submit = () => {
        let confirm = window.confirm("Are you sure do this?");
        if (confirm) {
            dispatch(ChangeSettingWithdraw(appId, data, handleClose));
        }
    }

    useEffect(() => {
        dispatch(GetWithdrawSetting(appId));
    }, [appId, dispatch])

    let setting = useSelector((state) => {
        return state.mobileApp?.withdrawSetting || {};
    });

    useEffect(() => {
        if (typeof setting.min != "undefined") {
            setData(setting)
        }
    }, [dispatch, setting])


    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">ต่ำสุด:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter Min Deposit"
                                    onKeyPress={e => handleCheckNumber(e)}
                                    pattern="[0-9]*"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) => handleData(e)}
                                    value={data.min}
                                    name="min"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">สูงสุด:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter Max Deposit"
                                    onKeyPress={e => handleCheckNumber(e)}
                                    pattern="[0-9]*"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) => handleData(e)}
                                    value={data.max}
                                    name="max"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">ถอนสูงสุดต่อวัน:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter Max Deposit"
                                    onKeyPress={e => handleCheckNumber(e)}
                                    pattern="[0-9]*"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) => handleData(e)}
                                    value={data.maxPerDay}
                                    name="maxPerDay"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">ถอน(ครั้ง) ต่อวัน:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter Max Deposit"
                                    onKeyPress={e => handleCheckNumber(e)}
                                    pattern="[0-9]*"
                                    onChange={(e) => handleData(e)}
                                    value={data.maxTimesPerDay}
                                    name="maxTimesPerDay"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer className="row">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={e => submit()}>
                    Save changes
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </>
    )
}

export default SettingWithdraw
