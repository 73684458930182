import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {RoleContext} from "../../context/RoleContext";
import {scopes} from "../../services/RoleService";
import LocalStorageService from "../../services/LocalStorageService";


const localStorageService = LocalStorageService.getService()
const initDataState = {
    name: "",
    agentId: "",
    scopes: [],
    status: "active",
    submited: false,
}


const NewRole = () => {
    const me = localStorageService.getMe()
    const [data, setData] = useState(initDataState);
    const {handleClose} = useContext(ModalContext)
    const {addRole, actionStatus} = useContext(RoleContext)

    useEffect(() => {
        setData({
            ...data,
            agentId: me.id
        })
    }, []);

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleScopes = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked) {
            setData({
                ...data,
                scopes: [...data.scopes, value]
            })
        } else {
            const scopes = data.scopes;
            var index = scopes.indexOf(value)
            if (index !== -1) {
                scopes.splice(index, 1);
                setData({
                    ...data,
                    scopes: scopes
                })
            }
        }
    }

    const submit = () => {
        setData({
            ...data,
            submited: true,
        })
        addRole(data)
    }
    return (
        <>
            {actionStatus == "role_add_error" && (
                <div className="alert alert-danger">ผิดพลาด ลองใหม่อีกครั้ง</div>
            )}
            <div className="form-group">
                <label className="h5" htmlFor="">ชื่อ</label>
                <input type="text" name="name" onChange={handleInput} className="form-control"/>
            </div>

            <div className="h5">เงื่อนไข</div>
            <div className="row">
                {scopes.map(scope => (
                    <>
                        <div className="col-lg-6 col-md-6">
                            <div className="h6 mb-0">{scope.name}</div>
                            <ul className="list-unstyled list-item list-item-inline">
                                <li><input value={scope.scopes.read} onClick={handleScopes} type="checkbox"/>
                                    <label>Read</label></li>
                                <li><input value={scope.scopes.write} onClick={handleScopes} type="checkbox"/>
                                    <label>Write</label></li>
                            </ul>
                        </div>
                    </>
                ))}
            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && actionStatus == 0} onClick={submit} className="btn btn-primary">Save changes</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </>
    );
}

export default NewRole;
