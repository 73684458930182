import * as http from "../http-common";


export function GetPromotions(prefix) {
    return http.get(`promotion/${prefix}`);
}


export function AddPromotion(model, prefix) {
    return http.post(`promotion/${prefix}`, model);
}

export function StatusUpdatePromotion(id, prefix) {
    return http.deleteHttp(`promotion/${prefix}/${id}`);
}

export function UpdatePromotion(id, model, prefix) {
    return http.put(`promotion/${prefix}/${id}`, model);
}

