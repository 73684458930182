import {ROLE_ACTION_STATUS, ROLE_ADD, ROLE_EDIT, ROLE_FETCH} from "../actions/type";

export default function RoleReducer(state, action) {
    switch (action.type) {
        case ROLE_FETCH:
            return {
                ...state,
                roles: action.payload
            };
        case ROLE_ADD:
            return {
                ...state,
                roles: [...state.roles, action.payload]
            };
        case ROLE_EDIT:
            const updatedRole = action.payload;

            const updatedRoles = state.roles.map(role => {
                if (role.id === updatedRole.id) {
                    return updatedRole;
                }
                return role;
            });

            return {
                ...state,
                roles: updatedRoles
            };
        case ROLE_ACTION_STATUS:
            return {
                ...state,
                actionStatus: action.payload
            };
        default:
            return state;
    }
}
