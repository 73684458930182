import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import ModalApp from "../../components/Modal/ModalApp";
import NewAg from "../../components/Agent/NewAg";
import {AgentContext} from "../../context/AgentContext";
import Topup from "../../components/Agent/Topup";
import ChangPassword from "../../components/Agent/ChangePassword";
import CurrencyFormat from 'react-currency-format';
import Moment from "react-moment";
// import {Alert} from 'react-bootstrap/Alert';


const Agents = () => {

    const {createAgentStatus, setCreatAgentStatus, getDownline, agents, clearAgents} = useContext(AgentContext)

    useEffect(() => {
        document.title = "เอเยนต์";

        setCreatAgentStatus()
        clearAgents()
        getDownline();
    }, []);


    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: '', size: '',
    });

    const [userTypeFrm, setUserTypeFrm] = useState('agent');


    function onOpenModalApp(content, component) {
        if (modalRef.current) {

            setModalComponent(component)
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            })
            modalRef.current.show()

        }
    }

    const modalRef = useRef(null)


    return (
        <>

            <ModalApp component={modalComponent} content={modalConent} ref={modalRef}/>

            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>เอเยนต์</h4>
                    </div>
                    <div>
                        <button onClick={() => onOpenModalApp({
                                title: 'เพิ่มผู้ใช้งานใหม่',
                                size: 'md'
                            },
                            <NewAg setUserTypeFrm={setUserTypeFrm} userTypeFrm={userTypeFrm}/>)} className="btn btn-success btn-sm mr-2">
                            <i className="icon-plus2"></i> เพิ่มเอเยนต์
                        </button>
                        {/*<button onClick={() => onOpenModalApp({title: 'บทบาท'},*/}
                        {/*    <NewRole/>)} className="btn btn-danger btn-sm">*/}
                        {/*    <i className="icon-plus2"></i> เพิ่มบทบาท*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> หน้าแรก</Link>
                            <span className="breadcrumb-item active">เอเยนต์ - ข้อมูลเอเยนต์</span>
                        </div>
                        <a href="/" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                </div>
            </div>

            <div className="content">
                {createAgentStatus === 200 && (
                    <div className="alert alert-success">บันทึกข้อมูลสำเร็จ</div>
                )}
                {createAgentStatus === 'reset_password_success' && (
                    <div className="alert alert-success">เปลี่ยนรหัสผ่านสำเร็จ</div>
                )}
                {createAgentStatus === 'topup_success' && (
                    <div className="alert alert-success">เติมเงินสำเร็จ</div>
                )}

                {createAgentStatus === 'revoke_success' && (
                    <div className="alert alert-success">ถอนเงินสำเร็จ</div>
                )}
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">ชื่อผู้ใช้งาน</label>
                                <input type="text" className="form-control form-control-sm" placeholder="ค้นหา"/>
                            </div>
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th scope="col" width={80}>Prefix</th>
                                <th scope="col" width={80}>ชื่อผู้ใช้</th>
                                <th scope="col" width={80}>ชื่อเล่น</th>
                                <th scope="col" width={80}>ระดับ</th>
                                <th scope="col" width={80}>Sharing</th>
                                <th scope="col" width={80} className="text-right">คงเหลือ</th>
                                <th scope="col" width={80} className="text-center">เติม</th>
                                <th scope="col" width={80} className="text-center">ถอน</th>
                                <th scope="col" width={80}>เปลี่ยนรหัสผ่าน</th>
                                <th scope="col" width={80}>วงเงินเดิมพันเริ่มต้น</th>
                                <th scope="col" width={80}>ดาวน์ไลน์</th>
                                <th scope="col" width={80}>วันที่สร้าง</th>
                                {/*<th>Created By</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {agents.length > 0 ? agents.map(agent => (
                                <tr key={agent.id}>
                                    <td>{agent.prefix}</td>
                                    <td>{agent.username}</td>
                                    <td>{agent.fullName}</td>
                                    <td>{agent.role}</td>

                                    <td className="text-center">{agent.sharing}</td>
                                    <td className="text-right">
                                        {/*{agent.credit}*/}
                                        <CurrencyFormat value={agent.credit} displayType={'text'} thousandSeparator={true}/>
                                        {/*<CurrencyFormat />*/}
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'เติมเงิน', size: 'sm'},
                                            <Topup type="deposit" agent={agent}/>)} className="btn btn-success btn-sm">
                                            <i className="icon-plus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'ถอนเงิน', size: 'sm'},
                                            <Topup type="revoke" agent={agent}/>)} className="btn btn-danger btn-sm">
                                            <i className="icon-minus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({
                                                title: 'เปลี่ยนรหัสผ่าน : ' + agent.username,
                                                size: 'sm'
                                            },
                                            <ChangPassword agent={agent}/>)} className="btn btn-primary btn-sm">
                                            <i className="icon-key"></i>
                                        </button>
                                    </td>
                                    <td>bet limit</td>
                                    <td>downline</td>
                                    <td>
                                        <Moment format="YYYY-MM-DD HH:mm">{agent.createdTime}</Moment>
                                    </td>
                                    {/*<td>create by</td>*/}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={20} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>

                {/*<MemberList  onOpenModalApp={onOpenModalApp}/>*/}
            </div>
        </>
    );
};

export default withRouter(Agents);
