import * as http from "../http-common";

export function GetAgent() {
    return http.get(`operator/downline`);
}

export function ApiQuotaBankOfAgent(scbAcc, prefix) {
    const model = {
        scbAcc: parseInt(scbAcc),
    };

    return http.post(`operator/bankacc/scb/${prefix}`, model);
}

// Enable withdraw bank agent
export function EnableBankWithdrawPUT(withdrawId) {
    return http.put(`operator/bankacc/withdraw/${withdrawId}`, {});
}

// Disable withdraw bank agent
export function DisableBankWithdrawDELETE(withdrawId) {
    return http.deleteHttp(`operator/bankacc/withdraw/${withdrawId}`);
}

// Enable deposit bank agent
export function EnableBankDepositPUT(depositId) {
    return http.put(`operator/bankacc/deposit/${depositId}`, {});
}

// Disable deposit bank agent
export function DisableBankDepositDELETE(depositId) {
    return http.deleteHttp(`operator/bankacc/deposit/${depositId}`);
}

// Add bank agent deposit
export function DepostBankOfAgent(model) {
    return http.post(`operator/bankacc/deposit`, model);
}

// Add bank agent withdraw
export function WithdrawBankOfAgent(model) {
    return http.post(`operator/bankacc/withdraw`, model);
}

// Pull list bank deposit agent
export function ApiDepostBankOfAgent() {
    return http.get(`operator/bankacc/deposit`);
}

// Pull list bank withdraw agent
export function ApiWithdrawBankOfAgent() {
    return http.get(`operator/bankacc/withdraw`);
}

///## App Mobile ##//

// Register device
export function ApiRegisterNew(model) {
    return http.post(`fasteasy/register`, model);
}

// Confirm OTP by ref
export function ApiConfirmOTP(appId, ref, model) {
    return http.post(`fasteasy/${appId}/${ref}/confirm`, model);
}

// Set deviceId for app
export function ApiSetDeviceId(appId, model) {
    return http.post(`fasteasy/${appId}/device`, model);
}

// Get all app list
export function ApiGetAppList() {
    return http.get(`fasteasy`);
}

// Get App Info
export function ApiGetAppInfo(appId) {
    return http.get(`fasteasy/${appId}`);
}

// Patch default withdraw
export function ApiDefaultWithdraw(appId) {
    return http.patch(`fasteasy/${appId}`);
}

// Disable for app
export function ApiDisableApp(appId) {
    return http.deleteHttp(`fasteasy/${appId}`);
}

// Api Change PIN app
export function ApiChangePIN(appId, model) {
    return http.patch(`fasteasy/${appId}/pin`, model);
}

// Api Change Phone app
export function ApiChangePhone(appId, model) {
    return http.patch(`fasteasy/${appId}/phone`, model);
}

// Api Enable Deposit
export function ApiEnableDeposit(appId, depositId) {
    return http.patch(`fasteasy/${appId}/deposit/${depositId}`, {});
}

// Api Disable Deposit
export function ApiDisableDeposit(appId, depositId) {
    return http.deleteHttp(`fasteasy/${appId}/deposit/${depositId}`);
}


// Api Setting Deposit
export function ApiGetSettingDeposit(appId) {
    return http.get(`fasteasy/${appId}/deposit/settings`);
}


export function ApiSettingDeposit(appId, model) {
    return http.patch(`fasteasy/${appId}/deposit/settings`, model);
}

// Api Setting Withdraw
export function ApiGetSettingWithdraw(appId) {
    return http.get(`fasteasy/${appId}/withdraw/settings`);
}

export function ApiSettingWithdraw(appId, model) {
    return http.patch(`fasteasy/${appId}/withdraw/settings`, model);
}

export function ApiGetBankBalance(appId) {
    return http.get(`fasteasy/${appId}`);
}
