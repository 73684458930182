const LocalStorageService = (function () {
    var _service;

    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(tokenObj) {
        localStorage.setItem('accessToken', tokenObj.accessToken);
        localStorage.setItem('refreshToken', tokenObj.refreshToken);
    }

    function _setMe(data) {
        localStorage.setItem('me', JSON.stringify(data));
    }

    function _getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    function _getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    function _getMe() {
        const me = localStorage.getItem('me');
        return JSON.parse(me);
    }

    function _clearToken() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('me');
    }

    return {
        getService: _getService,
        setToken: _setToken,
        setMe: _setMe,
        getMe: _getMe,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        clearToken: _clearToken
    }
})();
export default LocalStorageService;
