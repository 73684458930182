import React, {useContext} from "react";
import {Link} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";
import {logo} from "../../services/config";
import {setOpenModalContent} from "../../reducers/tools/Utility";
import UserProfile from "../User/UserProfile";
import {useDispatch} from "react-redux";
import ChangMePassword from "../Agent/ChangeMePassword";
import {ModalContext} from "../../context/ModalContext";
import CurrencyFormat from "react-currency-format";
import {Payment} from "@material-ui/icons";
import {UserMeInfo} from "../../reducers/tools/AccountUsers";

const localStorageService = LocalStorageService.getService()
const Header = ({setOpenSideBar, openSideBar}) => {
    const dispatch = useDispatch()
    const me = localStorageService.getMe();

    const {dispatchModal} = useContext(ModalContext);
    // console.log(me)
    const style = {
        height: `1.6rem`
    }

    const handleSetOpenSideBar = () => {
        setOpenSideBar(openSideBar ? false : true)
    }

    const handleReloadCredit = (e) => {
        e.preventDefault()
        dispatch(UserMeInfo());
    }
    return (
        <div className="navbar navbar-expand-md navbar-dark">
            <div className="navbar-brand">
                <a href="/" className="d-inline-block">
                    <img src={`/images/${logo}`} alt="" style={style}/>
                </a>
            </div>

            <div className="d-md-none">
                <button
                    className="navbar-toggler sidebar-mobile-main-toggle"
                    type="button"
                    onClick={() => handleSetOpenSideBar()}
                >
                    <i className="icon-paragraph-justify3"></i>
                </button>
            </div>

            <div className="collapse navbar-collapse" id="navbar-mobile">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a
                            href="/"
                            className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
                        >
                            <i className="icon-paragraph-justify3"></i>
                        </a>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a href="#" onClick={e => handleReloadCredit(e)} className="navbar-nav-link d-flex align-items-center">
                            <div className="mr-1"><Payment/></div>
                            <CurrencyFormat value={me?.credit} displayType={'text'} thousandSeparator={true}/>
                        </a>
                    </li>
                    <li className="nav-item dropdown dropdown-user">
                        <a
                            href="/"
                            className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                            data-toggle="dropdown"
                        >
                            <img
                                src="/images/avatar.png"
                                className="rounded-circle mr-2"
                                height="34"
                                alt=""
                            />
                            <span>{me?.fullName}</span>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right">
                            {/*<a*/}
                            {/*  href="/"*/}
                            {/*  className="dropdown-item"*/}
                            {/*>*/}
                            {/*  <i className="icon-user"></i> Edit Profile*/}
                            {/*</a>*/}
                            <a className="dropdown-item" onClick={() => dispatch(setOpenModalContent({
                                title: `เปลี่ยนรหัสผ่าน`,
                                size: `sm`,
                                content: <ChangMePassword/>,
                            }))}><i className="icon-key"></i> เปลี่ยนรหัสผ่าน</a>

                            <Link to="/logout" className="dropdown-item">
                                <i className="icon-switch2"></i> ออกจากระบบ
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Header;
