import React, { createContext, useReducer } from "react";

const initialState = {
  close: false,
  open: false,
};

const ModalReducer = (state, action) => {
  switch (action.type) {
    case "MODAL_OPEN":
      break;
    case "MODAL_CLOSE":
      return {
        ...state,
        close: action.payload,
      };
    default:
      return state;
  }
};

export const ModalContext = createContext(initialState);
export const ModalProvider = ({ handleClose, children }) => {
  const [state, dispatchModal] = useReducer(ModalReducer, initialState);
  // const [token, setToken] = useState(true);

  return (
    <ModalContext.Provider
      value={{
        closeModal: state.close,
        openModal: state.open,
        handleClose,
        dispatchModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
