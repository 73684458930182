import moment from "moment";

export const logoBank = bank => {
    if (typeof bank !== `undefined` && bank !== ``)
        return `/images/bank/${bank.toLowerCase()}.png?a=1`
}


export const startDateTimeFormat = (day) => {
    return moment(new Date(new Date(moment().subtract(day, 'day').format()).setHours(0, 0, 0, 0))).format()
}


export function rowRuning(i, page, pageSize) {
    // return pageSize
    if (typeof page == 'undefined' || page == 1 || page == 0 || page == null || isNaN(page)) {
        return i + 1
    } else {
        const n = (pageSize * page) - parseInt(pageSize) + 1
        return parseInt(n) + (parseInt(i))
    }
}
