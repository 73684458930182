import React, {createContext, useReducer} from "react";
import GameReducer from "../reducers/GameReducer";
import {GAME_FETCH} from "../actions/type";
import GameService from "../services/GameService";

const initialState = {
    games: []
}

export const GameContext = createContext(initialState);

export const GameProvider = ({children}) => {
    const [state, dispatch] = useReducer(GameReducer, initialState);
    // const [token, setToken] = useState(true);

    function getGames() {
        GameService.getAll().then(response => {
            // console.log(response.data);
            dispatch({
                type: GAME_FETCH,
                payload: response.data
            });
        }).catch(e => {
            console.log(e);
        });
    }


    return (
        <GameContext.Provider
            value={{
                games: state.games,
                getGames
            }}
        >
            {children}
        </GameContext.Provider>
    );

}
