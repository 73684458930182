import React, {createContext, useReducer} from "react";
import GlobalReducer from "../reducers/GlobalReducer";

const initialState = {
    loading: 0,
}

export const GlobalContext = createContext(initialState);

export const GLobalProvider = ({children}) => {
    const [state, dispatchGlobal] = useReducer(GlobalReducer, initialState);
    return (
        <GlobalContext.Provider
            value={{
                loading: state.loading,
                dispatchGlobal
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
