import React, {useContext, useEffect, useRef, useState} from "react";
import {Modal, Tab, Tabs} from "react-bootstrap";
import {GameContext} from "../../context/GameContext";
import {AgentContext} from "../../context/AgentContext";
import {ModalContext} from "../../context/ModalContext";
import LocalStorageService from "../../services/LocalStorageService";
import {Timer} from "@material-ui/icons";
import {GameProviderContext} from "../../context/GameProviderContext";
import SaveIcon from "@material-ui/icons/Save";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import {useDispatch, useSelector} from "react-redux";

import {
    QuotaBankOfAgent,
    AddDepositBankOfAgent,
    AddWithdrawBankOfAgent,
} from "../../reducers/tools/AgentBankaccount";

const AccountAg = (props) => {
    const dispatch = useDispatch();
    const {prefix} = props;

    let BankList = useSelector((state) => {
        return state.agentBank?.bankList || [];
    });

    let MeInfo = useSelector((state) => {
        return state.accountUser.accountInfo || {};
    });

    const [tab, setTab] = useState("withdraw");

    // const [data, setData] = useState(initDataState);
    // const { getGames, games } = useContext(GameContext);
    // const { getProviders, providers } = useContext(GameProviderContext);
    const {handleClose} = useContext(ModalContext);
    const {
        // createAgent,
        createAgentStatus: createStatus,
    } = useContext(AgentContext);

    useEffect(() => {
        setScbAcc(MeInfo.scbAcc);
    }, [MeInfo.scbAcc]);

    let [scbAccValue, setScbAcc] = useState(1);
    let [AccountNumber, setAccountNumber] = useState("");
    let [Bankcode, setBankCode] = useState("SCB");
    let [FullnameTH, setFullnameTH] = useState("");
    let [FullnameEN, setFullnameEN] = useState("");
    let [Newmember, setNewmember] = useState(false);

    const submit = () => {
        if (tab === "deposit") {
            const model = {
                accountNumber: AccountNumber,
                bank: Bankcode,
                fullnameTH: FullnameTH,
                fullnameEN: FullnameEN,
            };
            dispatch(AddDepositBankOfAgent(model, handleClose));
        }

        if (tab === "withdraw") {
            const model = {
                accountNumber: AccountNumber,
                bank: Bankcode,
                fullnameTH: FullnameTH,
                fullnameEN: FullnameEN,
                newMember: Newmember,
            };
            dispatch(AddWithdrawBankOfAgent(model, handleClose));
        }
    };

    return (
        <>
            {createStatus === 400 && (
                <div className="alert alert-danger">
                    ผิดพลาดในการประมวลผล กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง
                </div>
            )}

            {MeInfo.role === "admin" && (
                <div className="row">
                    <div className="col-lg-12">
                        <p>Allow agent using mobile app by amount of accounts</p>
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">Amount SCB :</label>
                            <div className="col-sm-8">
                                <div className="input-group">
                                    <input
                                        placeholder="Enter amount accounts"
                                        type="number"
                                        max="3"
                                        onChange={(e) => setScbAcc(e.target.value)}
                                        value={scbAccValue}
                                        name="scbAcc"
                                        className="form-control form-control-sm"
                                        aria-describedby="basic-addon2"
                                    />
                                    <div className="input-group-append">
                                        <button
                                            disabled={prefix === "" || !scbAccValue}
                                            className="btn btn-sm mb-1"
                                            onClick={() =>
                                                dispatch(
                                                    QuotaBankOfAgent(scbAccValue, prefix, handleClose)
                                                )
                                            }
                                        >
                                            <SaveRoundedIcon/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {MeInfo.role === "agent" && (
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs
                            activeKey={tab}
                            defaultActiveKey={tab}
                            onSelect={(k) => setTab(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="withdraw" title="Withdraw account">
                                <div className="col-md-12">
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Account Number
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                maxLength={13}
                                                name="accountNumber"
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                value={AccountNumber}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">BANK</label>
                                        <div className="col-sm-9">
                                            <select
                                                name="bank"
                                                onChange={(e) => setBankCode(e.target.value)}
                                                className="form-control form-control-sm"
                                            >
                                                {BankList.map(({code, name}, index) => (
                                                    <option key={code} value={code}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Fullname EN
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                maxLength={50}
                                                name="fullNameEN"
                                                onChange={(e) => setFullnameEN(e.target.value)}
                                                value={FullnameEN}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Fullname TH
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                name="fullNameTH"
                                                maxLength={50}
                                                onChange={(e) => setFullnameTH(e.target.value)}
                                                value={FullnameTH}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            New member
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => setNewmember(e.target.value)}
                                                    value={Newmember}
                                                    className="form-check-input"
                                                    id="newMember"
                                                    name="newMember"
                                                />
                                                <label className="form-check-label" htmlFor="newMember">
                                                    Check new member
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="deposit" title="Deposit account">
                                <div className="col-md-12">
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Account Number
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                maxLength={13}
                                                name="accountNumber"
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                value={AccountNumber}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">BANK</label>
                                        <div className="col-sm-9">
                                            <select
                                                className="form-control form-control-sm"
                                                name="bank"
                                                onChange={(e) => setBankCode(e.target.value)}
                                                value={Bankcode}
                                            >
                                                {BankList.map(({code, name}, index) => (
                                                    <option key={code} value={code}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Fullname EN
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                maxLength={50}
                                                name="fullNameEN"
                                                onChange={(e) => setFullnameEN(e.target.value)}
                                                value={FullnameEN}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-2 row">
                                        <label className="col-sm-3 col-form-label">
                                            Fullname TH
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                name="fullNameTH"
                                                maxLength={50}
                                                onChange={(e) => setFullnameTH(e.target.value)}
                                                value={FullnameTH}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )}

            {MeInfo.role === "agent" && (
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={submit}>
                        Save changes
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </Modal.Footer>
            )}
        </>
    );
};

export default AccountAg;
