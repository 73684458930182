import React, {useContext, useState} from "react";
import {LoginContext} from "../../context/LoginContext";
import {Redirect} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";

const Login = ({location}) => {
    //console.log(location);

    const [data, setData] = useState({
        username: "",
        password: "",
        errorMessage: "",
        isSubmit: false,
    });

    // const [data, setData] = useState({
    //   username: "testoperator3@TETS",
    //   password: "123456",
    //   errorMessage: "",
    //   isSubmit: false,
    // });

    // const [data, setData] = useState({username: 'admin', password: 'P@ssw0rd', errorMessage: '', isSubmit: false})

    const {auth, state} = useContext(LoginContext);

    if (state.isAuthenticated) {
        return <Redirect to={state.role == "operator" ? "/user" : "/agent"}/>;
    }

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = (event) => {
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });
        auth(data, setData);
        event.preventDefault();
        return false
    };

    return (
        <div className="page-content">
            <div className="content-wrapper">
                <div className="content d-flex justify-content-center align-items-center">
                    <div className="login-form">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    <h3>Log me in</h3>
                                </div>
                                {data.errorMessage && (
                                    <div className="alert alert-danger">{data.errorMessage}</div>
                                )}
                                <form onSubmit={e => handleFormSubmit(e)}>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            onChange={handleInputChange}
                                            value={data.username}
                                            placeholder="Username"
                                        />
                                        <div className="form-control-feedback">
                                            <i className="icon-user text-muted"></i>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-feedback form-group-feedback-left">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            onChange={handleInputChange}
                                            value={data.password}
                                            placeholder="Password"
                                        />
                                        <div className="form-control-feedback">
                                            <i className="icon-lock2 text-muted"></i>
                                        </div>
                                    </div>
                                    {state.isAuthenticated ? (
                                        <>Logined</>
                                    ) : (
                                        <>
                                            <div className="form-group">
                                                <button
                                                    disabled={data.isSubmit}
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {data.isSubmit ? <>Loading..</> : <>Sign in</>}
                                                    <i className="icon-circle-right2 ml-2"></i>
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
