import {combineReducers} from "redux";
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";

import LoginReducers from "./LoginReducer";
import AgentReducers from "./AgentReducer";
import AgentBank from "./tools/AgentBankaccount";
import AgentPromotion from "./tools/AgentPromotion";
import AccountInfo from "./tools/AccountUsers";
import MobileApp from "./tools/MobileApp";
import Trans from "./tools/Transaction";
import Report from "./tools/Report";
import GlobalReducers from "./tools/Global";
import utilityReducer from './tools/Utility'


// export default configureStore({
//   reducer: {
//     login: LoginReducers,
//     accountUser: AccountInfo.reducer,
//     agent: AgentReducers,
//     agentBank: AgentBank.reducer,
//     mobileApp: MobileApp.reducer,
//   },
//   middleware: getDefaultMiddleware({
//     serializableCheck: false,
//   }),
//   devTools: process.env.NODE_ENV !== "production",
// });

const combinedReducer = combineReducers({
    login: LoginReducers,
    accountUser: AccountInfo.reducer,
    agent: AgentReducers,
    globalSet: GlobalReducers.reducer,
    agentBank: AgentBank.reducer,
    agentPromotion: AgentPromotion.reducer,
    mobileApp: MobileApp.reducer,
    trans: Trans.reducer,
    report: Report.reducer,
    utility: utilityReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "logout") {
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware({serializableCheck: false})],
    devTools: process.env.NODE_ENV !== "production",
});
