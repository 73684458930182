import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {cancelDeposit, fetchDepositTransaction, getDepositTotal} from '../../reducers/tools/Transaction'
import ModalApp from "../../components/Modal/ModalApp";
import moment from 'moment';
import Pagination from 'react-js-pagination';
import {initConfigInputLastMonth, initConfigInputTime} from '../../services/config';
import ConfirmDeposit from '../../components/Transaction/ConfirmDeposit';
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import {setOpenModalContent} from "../../reducers/tools/Utility";
import UserProfile from "../../components/User/UserProfile";
import {logoBank, rowRuning} from "../../services/functions";
import AccountBalance from "../../components/AgentAccount/AccountBalance";
import {fetchDepositBankOfAgent, fetchWithdrawBankOfAgent} from "../../reducers/tools/AgentBankaccount";

const TransactionDeposit = () => {
    const dispatch = useDispatch();
    const currentDate = new Date()

    const [selectedStartDate, setSelectedStartDate] = useState(new Date(moment().subtract('months', 1)));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [isReport, setIsReport] = useState(false);
    const [username, setUsername] = useState("");
    const [title, setTitle] = useState("รายการธุระกรรมเติมเงิน");

    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });
    const modalRef = useRef(null);

    const depositList = useSelector((state) => state.trans.depositList);
    const total = useSelector((state) => state.trans.depositTotal);

    useEffect(() => {
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        let params = new URLSearchParams(window.location.search);
        let searchUsername = params.get('username');
        if (typeof searchUsername !== `undefined`) {
            setUsername(searchUsername)
        }

        setSelectedStartDate(start);
        setSelectedEndDate(end);

        dispatch(fetchDepositTransaction(1, itemPerPage, start, end, '', '', searchUsername ?? ''));
    }, [dispatch])

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let foo = params.get('report');

        if (foo == "true") {
            setIsReport(true)
            setTitle("รายงานเติมเงิน")
            onTotalReport()
        } else {
            setIsReport(false)
            setTitle("รายการธุระกรรมเติมเงิน")
        }

    }, [window.location.search])

    function onTotalReport() {
        dispatch(getDepositTotal(moment(selectedStartDate).format(), moment(selectedEndDate).format()))
    }

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }

    function handleCallBackDateRang(start, end, label) {
        setSelectedStartDate(moment(start).format());
        setSelectedEndDate(moment(end).format());
    }

    const handlePageSizeChange = (pageSize) => {
        setItemPerPage(pageSize)
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(fetchDepositTransaction(1, itemPerPage, start, end, '', '', username));
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(fetchDepositTransaction(pageNumber, itemPerPage, start, end, '', '', username));
    }

    function onSubmit() {
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(fetchDepositTransaction(1, itemPerPage, start, end, '', '', username));
        if (isReport)
            onTotalReport()
    }

    function onCancel(id) {

        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        if (window.confirm("ยืนยันการยกเลิก")) {
            dispatch(cancelDeposit(id, activePage, itemPerPage, start, end, '', '', username));
        }
    }

    useEffect((e) => {
        dispatch(fetchDepositBankOfAgent());
    }, [dispatch])

    let allBankListAgent = useSelector((state) => {
        return state.agentBank?.depositBanks || [];
    });

    return (
        <>
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>{title}</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">{title}</span>
                        </div>
                        <a
                            href="/#"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="content">

                {isReport && (
                    <div className="card mb-4">
                        <table className="table table-header">
                            <thead>
                            <tr>
                                <th className="text-center" width="180"></th>
                                <th className="text-center">จำนวน</th>
                                <th className="text-center">ยอดฝาก</th>
                                <th className="text-center">โบนัส</th>
                                <th className="text-center">รวม</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="text-center">
                                    <div className="h4 mb-0">
                                        รวม
                                    </div>
                                </td>
                                <td className="text-center">
                                    {!total.loaded ? "Loading.." : (
                                        <span className="h1 mb-0"><CurrencyFormat value={total?.totalTxn} displayType={'text'} thousandSeparator={true}/></span>)}
                                </td>
                                <td className="text-center">
                                    {!total.loaded ? "Loading.." : (
                                        <span className="h1 mb-0"><CurrencyFormat value={total?.subTotal} displayType={'text'} thousandSeparator={true}/></span>)}
                                </td>
                                <td className="text-center">
                                    {!total.loaded ? "Loading.." : (
                                        <span className="h1 mb-0"><CurrencyFormat value={total?.totalBonus} displayType={'text'} thousandSeparator={true}/></span>)}
                                </td>
                                <td className="text-center">
                                    {!total.loaded ? "Loading.." : (
                                        <span className="h1 mb-0"><CurrencyFormat value={total?.total} displayType={'text'} thousandSeparator={true}/></span>)}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                <div className="card mb-4">
                    <div className="card-body">
                        <h4 className="mb-0">รายการ บัญชีฝาก</h4>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={150}>เลขบัญชี</th>
                                <th className="text-center" width={100}>ธนาคาร</th>
                                <th className="text-center" width={200}>ชื่อบัญชีไทย</th>
                                <th className="text-center" width={200}>ชื่อบัญชีอังกฤษ</th>
                                <th className="text-center" width={150}>ประเภทบัญชี</th>
                                <th className="text-center" width={100}>สถานะ</th>
                                <th className="text-center">คงเหลือ</th>
                            </tr>
                            </thead>
                            <tbody>
                            {allBankListAgent.length > 0 ? (
                                allBankListAgent.map((dp, i) => (
                                    <tr key={dp.id}>
                                        <td className="text-center">
                                            <img src={logoBank(dp?.bank)} onError={`images/bank/no.png`} width={20}/>
                                            <div className="mt-1">{dp.number}</div>
                                        </td>
                                        <td className="text-center">{dp.bank}</td>
                                        <td className="text-center">{dp.nameTH}</td>
                                        <td className="text-center">{dp.nameEN}</td>
                                        <td className="text-center">{dp.type}</td>
                                        <td className="text-center">{dp.status}</td>
                                        <td className="text-center">
                                            {dp?.balance}
                                            <button onClick={() =>
                                                onOpenModalApp(
                                                    {
                                                        title: "รายการ Application",
                                                        size: "xl",
                                                    },
                                                    <AccountBalance detail={dp}/>
                                                )
                                            } className="btn btn-success">รายการบัญชี
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={20} className="text-center">
                                        ไม่พบข้อมูล
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <DateRangePicker
                                    initialSettings={initConfigInputLastMonth}
                                    onCallback={handleCallBackDateRang}
                                >
                                    <input type="text" style={{width: "164px"}} className="form-control form-control-sm"/>
                                </DateRangePicker>
                            </div>
                            <div className="form-group mr-2">
                                <input className="form-control form-control-sm" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="ค้นหาจาก Username"/>
                            </div>
                            <div className="form-group mr-2">
                                <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th>ผู้ใช้งาน</th>
                                <th width={150}>จากบัญชี</th>
                                <th width={150}>เข้าบัญชี</th>
                                <th className="text-center">ก่อน</th>
                                <th className="text-center">จำนวน</th>
                                <th className="text-center">โบนัส</th>
                                <th className="text-center">หลัง</th>
                                <th className="text-center" width={130}>วันเวลา</th>
                                <th className="text-center" width={130}>อัพเดต</th>
                                <th className="text-center">สถานะ</th>
                                <th>หมายเหตุ</th>
                                {!isReport && (<th width={150}></th>)}

                            </tr>
                            </thead>
                            <tbody>
                            {depositList.data.length > 0 ? depositList.data.map((item, i) => (
                                <tr key={item.id}>
                                    <td className="text-center">{rowRuning(i, activePage, depositList?.pageSize)}</td>
                                    <td>
                                        {/*{item.userId}*/}
                                        <a className="pointer text-info font-weight-bold" onClick={() => dispatch(setOpenModalContent({
                                            title: `รายละเอียด ${item.userId}`,
                                            size: `sm`,
                                            content: <UserProfile username={item.userId}/>,
                                        }))}><u>{item.userId}</u></a>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <img src={logoBank(item.fromAccId?.bank)} onError={`images/bank/no.png`} width={20}/>
                                            <div className="ml-1"> {item.fromAccId?.fullName}</div>
                                        </div>

                                    </td>
                                    <td>
                                        {/*{item.toAccId.fullName}*/}
                                        <div className="text-center">
                                            <img src={logoBank(item.toAccId?.bank)} onError={`images/bank/no.png`} width={20}/>
                                            <div className="ml-1"> {item.toAccId?.fullName}</div>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.creditBefore} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>
                                    </td>

                                    <td className="text-center">
                                        <CurrencyFormat value={item.bonusAmount} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.creditAfter} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center"><Moment format="YYYY-MM-DD HH:mm" date={item.txnTime}/>
                                    </td>
                                    <td className="text-center">
                                        <Moment format="YYYY-MM-DD HH:mm" date={item.latestUpdate}/></td>
                                    <td className="text-center">
                                        {item.status === 'failed' &&
                                            <span className="trans-status badge badge-pill badge-danger">Failed</span>}
                                        {item.status === 'success' &&
                                            <span className="trans-status badge badge-pill badge-success">Success</span>}

                                        {item.status === 'pending' &&
                                            <span className="trans-status badge badge-pill badge-warning">Pending</span>}

                                        {item.status === 'canceled' &&
                                            <span className="trans-status badge badge-pill badge-secondary">Cancel</span>}

                                        {(item.status !== 'pending' || item.status !== 'success') ??
                                            <span className="trans-status badge badge-pill badge-danger">{item.status}</span>}
                                    </td>
                                    <td>{item.note}</td>
                                    {!isReport && (
                                        <>
                                            <td className="text-center">
                                                {(item.status === 'pending' || item.status === 'failed') && (
                                                    <>
                                                        <button className="btn btn-success btn-sm" onClick={() =>
                                                            onOpenModalApp(
                                                                {
                                                                    title: "ยืนยันรายการ",
                                                                    size: "sm",
                                                                },
                                                                <ConfirmDeposit pageNumber={activePage} itemPerPage={itemPerPage} start={moment(selectedStartDate).format()} end={moment(selectedEndDate).format()} txnId={item.id} username={item.userId} amount={item.amount}/>
                                                            )
                                                        }>
                                                            Confirm
                                                        </button>
                                                        <button className="btn btn-danger btn-sm" onClick={e => onCancel(item.id)}>Cancel</button>
                                                    </>
                                                )}

                                            </td>
                                        </>
                                    )}

                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={10} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={depositList.pageSize}
                    totalItemsCount={depositList.total > 0 ? depositList.total : 0}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                />
            </div>
        </>
    );
};

export default withRouter(TransactionDeposit);
