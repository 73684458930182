import React, {createContext, useContext, useReducer} from "react";
import {ModalContext} from "./ModalContext";
import UserService from "../services/UserService";
import {USER_ADD, USER_ADD_STATUS, USER_FETCH, USER_UPDATE_KEY} from "../actions/type";
import UserReducer from "../reducers/UserReducer";
import LocalStorageService from "../services/LocalStorageService";
import {NotificationManager} from "react-light-notifications";
import {GlobalContext} from "./GlobalContext";

const localStorageService = LocalStorageService.getService()

const initialState = {
    users: [],
    actionStatus: "",
    page: 1,
    pageSize: 30,
    count: 0,
    search: "",
    loading: 0,
}
export const UserContext = createContext(initialState);

export const UserProvider = ({children}) => {
    const me = localStorageService.getMe();
    const [state, dispatch] = useReducer(UserReducer, initialState);
    const {dispatchModal} = useContext(ModalContext)
    const {dispatchGlobal} = useContext(GlobalContext)

    function getUser(id) {
        dispatchGlobal({type: `LOADING`})
        UserService.getUser(id).then(resp => {
            dispatch({
                type: USER_UPDATE_KEY,
                payload: {
                    id: id,
                    key: 'balance',
                    value: resp.data.balance
                }
            })
            dispatchGlobal({type: `FINISH`})
        }).catch(e => {
            dispatchGlobal({type: `ERROR`})
        })
    }


    function fetchUsers(data) {
        dispatchGlobal({type: `LOADING`})
        UserService.fetchUsers(data).then(resposne => {
            dispatch({
                type: USER_FETCH,
                payload: resposne.data
            })
            dispatchGlobal({type: `FINISH`})
        }).catch(e => {
            console.log(e)
            dispatchGlobal({type: `ERROR`})
        })
    }


    function createUser(data) {
        data.username = me.prefix + data.username
        dispatchGlobal({type: `LOADING`})
        UserService.createUser(data).then(response => {
            dispatch({
                type: USER_ADD,
                payload: response.data
            })
            dispatch({
                type: USER_ADD_STATUS,
                payload: "create_success"
            })
            closeModal()
            dispatchGlobal({type: `SAVED`})
        }).catch(e => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "create_error"
            })
            dispatchGlobal({type: `FINISH`})
        })
    }

    function resetPassword(id, data) {
        dispatchGlobal({type: `LOADING`})
        UserService.resetPassword(id, data).then(() => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "reset_password_success"
            })
            NotificationManager.success({
                title: "Success",
                message: "Reset password success",
            });
            closeModal()
            dispatchGlobal({type: `SAVED`})
        }).catch(() => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "reset_password_error"
            })
            dispatchGlobal({type: `FINISH`})
        })
    }

    function deposit(id, data) {
        dispatchGlobal({type: `LOADING`})
        UserService.deposit(id, data).then(response => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "topup_success"
            })
            dispatch({
                type: USER_UPDATE_KEY,
                payload: {
                    id: id,
                    key: 'balance',
                    value: response.data.balance
                }
            })
            NotificationManager.success({
                title: "Success",
                message: "Deposit Success",
            });
            closeModal()
            fetchUsers({})
            dispatchGlobal({type: `SAVED`})
        }).catch(e => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "topup_error"
            })
            NotificationManager.error({
                title: "Error",
                message: "Deposit Error",
            });
            dispatchGlobal({type: `FINISH`})
        })
    }

    function revoke(id, data) {
        dispatchGlobal({type: `LOADING`})
        UserService.withdraw(id, data).then(response => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "withdraw_success"
            })
            dispatch({
                type: USER_UPDATE_KEY,
                payload: {
                    id: id,
                    key: 'balance',
                    value: response.data.balance
                }
            })
            NotificationManager.success({
                title: "Success",
                message: "Withdraw success",
            });
            closeModal()
            dispatchGlobal({type: `SAVED`})
        }).catch(e => {
            dispatch({
                type: USER_ADD_STATUS,
                payload: "topup_error"
            })
            dispatchGlobal({type: `FINISH`})
        })
    }

    function bank(username, data, handlePageChange) {
        // AccountNumber: accountNumber,
        //     FullNameEN:    fullNameEN,
        //     FullNameTH:    fullNameTH,
        //     Bank:          bankCode,
        dispatchGlobal({type: `LOADING`})
        UserService.bank(username, data).then(resp => {
            // console.log(resp.status)
            NotificationManager.success({
                title: "Success",
                message: "Update bank success",
            });
            handlePageChange(1)
            closeModal()
            dispatchGlobal({type: `SAVED`})
        }).catch(e => {
            NotificationManager.error({
                title: "Error",
                message: "Update bank error",
            });
            dispatchGlobal({type: `FINISH`})
        })

    }

    function getHistory(prefix, username, date, page) {
        dispatchGlobal({type: `LOADING`})
        UserService.getHistory(prefix, username, date, page).then(resp => {
            // console.log(resp.data)
            dispatch({
                type: 'FETCH',
                payload: resp.data
            })

            dispatchGlobal({type: `FINISH`})

        }).catch(e => {
            dispatchGlobal({type: `ERROR`})
        })
    }


    /*Utility*/
    function closeModal() {
        dispatchModal({
            type: 'MODAL_CLOSE',
            payload: true
        })
    }

    function resetActionStatus() {
        dispatch({
            type: USER_ADD_STATUS,
            payload: ""
        })
    }

    return (
        <UserContext.Provider value={{
            userState: state,
            users: state.users,
            actionStatus: state.actionStatus,
            page: state.page,
            search: state.search,
            resetActionStatus,
            fetchUsers,
            getUser,
            createUser,
            resetPassword,
            deposit,
            bank,
            revoke,
            getHistory
        }}>
            {children}
        </UserContext.Provider>
    )

}
