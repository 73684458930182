import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../context/LoginContext";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGOUT } from '../../actions/type';

export default function Logout() {
  const { logout, state } = useContext(LoginContext);
  const dispatch = useDispatch();
  // const {isLoggedIn, setIsLoggedIn} = useState(true);

  useEffect(() => {
    logout();
    dispatch({ type: LOGOUT });
  }, []);

  return <Redirect to="/login" />;
}
