const PageNotFound = () => {
    return (
        <>
            <div className="container my-5 text-center text-white">
                <div className="h1 text-center text-white">404 PAGE NOT FOUND</div>
                <a href="/dashboard" className="text-white"><u>กลับหน้าหลัก</u></a>
            </div>
        </>
    )
}

export default PageNotFound
