import React, {useContext, useEffect, useState} from "react";
import LocalStorageService from "../../services/LocalStorageService";
import {AgentContext} from "../../context/AgentContext";
import {ModalContext} from "../../context/ModalContext";
import {Modal} from "react-bootstrap";
import {scopes} from "../../services/RoleService";
import {RoleContext} from "../../context/RoleContext";
import {NotificationManager} from "react-light-notifications";

const localStorageService = LocalStorageService.getService()


const empRole = [
    "member.all",
    "member.read",
    "member.update",
    "member.create",

    // game settings
    "game.all",
    "game.read",
    "game.update",

    // transaction
    "transaction.all",
    "transaction.read",
    "transaction.create",
    "transaction.update",

    // promotion
    "promotion.all",
    "promotion.read",
    "promotion.create",
    "promotion.update",

    // Game history
    "history.read",

    // Sharing
    "sharing.read",
]

const initDataState = {
    prefix: "",
    username: "",
    password: "",
    fullName: "",
    sharing: 0,
    contact: "",
    role: "operator",
    scopes: empRole,
    games: [],
    submited: false
};

const NewOperator = () => {


    const me = localStorageService.getMe();
    const [data, setData] = useState(initDataState);
    const [disableCheckbox, setDisableCheckbox] = useState(true);
    const {handleClose} = useContext(ModalContext)
    const {roles, getRoles} = useContext(RoleContext)
    const {
        createAgent,
        createAgentStatus,
        setCreatAgentStatus,
    } = useContext(AgentContext)

    useEffect(() => {
        setCreatAgentStatus()
        setData({
            ...data,
            prefix: me.prefix,
            sharing: me.sharing
        })
        getRoles()
    }, [])

    const handleInputChange = event => {
        let value = event.target.value
        setData({
            ...data,
            [event.target.name]: value
        });
    };

    const handleScopes = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked) {
            setData({
                ...data,
                scopes: [...data.scopes, value]
            })
        } else {
            const scopes = data.scopes;
            var index = scopes.indexOf(value)
            if (index !== -1) {
                scopes.splice(index, 1);
                setData({
                    ...data,
                    scopes: scopes
                })
            }
        }
    }

    const handleScope = (e) => {
        const name = e.target.value;

        setDisableCheckbox(true)
        // console.log(name)
        const role = roles.filter(item => {
            return item.name == name
        })
        if (role.length) {
            setData({
                ...data,
                scopes: role[0].scopes
            })
        } else {
            setData({
                ...data,
                scopes: []
            })
        }

        // console.log(role[0])
    }

    const submit = () => {
        setCreatAgentStatus()
        const username = data.username
        if (username.length < 8) {
            NotificationManager.error({
                title: "Error",
                message: "Username 8 ตัวอักษรขึ้นไป",
            });
            return
        }
        const pattern = /^[a-zA-Z0-9]*$/;
        if (!username.match(pattern)) {
            NotificationManager.error({
                title: "Error",
                message: "Username ไม่ถูกต้อง",
            });
            return
        }

        const password = data.password
        if (password.length < 6) {
            NotificationManager.error({
                title: "Error",
                message: "Password 6 ตัวอักษรขึ้นไป",
            });
            return
        }

        if (data.fullName == "") {
            NotificationManager.error({
                title: "Error",
                message: "กรุณากรอก Full Name",
            });
            return
        }

        setData({
            ...data,
            submited: true,
        })
        createAgent(data);
    }

    return (
        <>
            {/*{createAgentStatus == 400 && (*/}
            {/*    <div className="alert alert-danger">ผิดพลาดในการประมวลผล กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง</div>*/}
            {/*)}*/}
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Username</label>
                        <div className="col-sm-9">
                            <input type="text" name="username" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>
                            <small className="text-muted">จำนวน 8 ตัวอักษรขึ้นไป เฉพาะ a-z A-z หรือ 0-9 เท่านั้น</small>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Password</label>
                        <div className="col-sm-9">
                            <input type="password" name="password" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>
                            <small className="text-muted">จำนวน 6 ตัวอักษรขึ้นไป เฉพาะ a-z A-z หรือ 0-9 เท่านั้น</small>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Full Name</label>
                        <div className="col-sm-9">
                            <input type="text" name="fullName" onChange={handleInputChange} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Contact</label>
                        <div className="col-sm-9">
                            <input type="text" name="contact" onChange={handleInputChange} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    {/*<div className="form-group row">*/}
                    {/*    <label className="col-sm-3 col-form-label">Role</label>*/}
                    {/*    <div className="col-sm-9">*/}
                    {/*        <select className="form-control" onChange={handleScope} name="scope">*/}
                    {/*            <option value="">เลือกสิทธิ์การใช้งาน</option>*/}
                    {/*            {roles.map(role => (*/}
                    {/*                <option value={role.name}>{role.name}</option>*/}
                    {/*            ))}*/}
                    {/*            /!*<option value="custom">Custom Role</option>*!/*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {/*<div className="col-lg-7">*/}


                {/*    <div className="h5 mb-2">Custom role</div>*/}
                {/*    <div className={"row " + (disableCheckbox ? "text-muted" : "")}>*/}
                {/*        {scopes.map(scope => (*/}
                {/*            <>*/}
                {/*                <div className="col-lg-4 col-md-6">*/}
                {/*                    <div className="h6 mb-0">{scope.name}</div>*/}
                {/*                    <ul className="list-unstyled list-item list-item-inline">*/}
                {/*                        <li>*/}
                {/*                            <input value={scope.scopes.read} disabled={disableCheckbox} onClick={handleScopes} type="checkbox"/>*/}
                {/*                            <label>Read</label></li>*/}
                {/*                        <li>*/}
                {/*                            <input value={scope.scopes.write} disabled={disableCheckbox} onClick={handleScopes} type="checkbox"/>*/}
                {/*                            <label>Write</label></li>*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && createAgentStatus == 0} onClick={submit} className="btn btn-primary">Save changes</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </>
    )
}


export default NewOperator;
