import { Link, useLocation, withRouter, useHistory } from "react-router-dom";

import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputLastMonth, initConfigInputTime} from "../../services/config";
import Pagination from "react-js-pagination";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getGameHistory, resetGameHistory } from "../../reducers/tools/Report";
import Moment from "react-moment";
import { NotificationManager } from "react-light-notifications";
import CurrencyFormat from "react-currency-format";
import { format } from "date-fns";
import {fetchAllTransaction} from "../../reducers/tools/Transaction";


const GameHistory = ({account = ''}) => {
    const history = useHistory();
    let data = useLocation();

    const dispatch = useDispatch();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date(moment().subtract(60, 'day').format()).setHours(0, 0, 0, 0)));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 0)));
    const [username, setUsername] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);

    // const [initDate, setInitDate] = useState({
    //     ...initConfigInputTime,
    //     startDate: typeof data.state != 'undefined' ? format(new Date(data.state.start), "MM/dd/yyyy") : moment(),
    //     endDate: typeof data.state != 'undefined' ? format(new Date(data.state.end), "MM/dd/yyyy") : moment(),
    // })

    const historyList = useSelector((state) => {
        return state.report.gameHistory || [];
    });

    useEffect(() => {
        return () => {

            if (history.action === "POP") {
                history.replace(history.location.pathname, {
                    startDate: selectedStartDate,
                    endDate: selectedEndDate
                });
            }
        };
    }, [history, selectedEndDate, selectedStartDate])


    useEffect(() => {
        dispatch(resetGameHistory())
    }, [dispatch])

    useEffect(() => {
        // if (typeof data.state != 'undefined') {
        //     setUsername(data.state.user)
        //     setSelectedStartDate(data.state.start)
        //     setSelectedEndDate(data.state.end)
        //     // console.log(data.state.start)
        //     // console.log(data.state.end)
        //     // alert(555)
        //     const ind = {
        //         // ...initConfigInputTime,
        //         startDate: moment(new Date(data.state.start), "MM/dd/yyyy"),
        //         endDate: moment(new Date(data.state.end), "MM/dd/yyyy"),
        //     }
        //     // setInitDate(ind)
        //     // setTimeout(() => {
        //     //     console.log(ind)
        //     // }, 1000)
        //     dispatch(getGameHistory(data.state.user, 1, itemPerPage, data.state.start, data.state.end));
        // }
    }, [dispatch, data])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const username = params.get('username');
        if (typeof username !== 'undefined' && username !== `` && username !== null) {
            handleSearchUserSearch(username)
        }
        // const search = useLocation();
    }, [dispatch])

    useEffect(() => {
        if (typeof account !== `undefined` && account !== ``) {
            handleSearchUserSearch(account)
        }
    }, [account])

    function handleSearchUserSearch (username) {
        setUsername(username)
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getGameHistory(username, 1, itemPerPage, start, end));
    }


    function handleCallBackDateRang(start, end, label) {
        setSelectedStartDate(moment(start).format());
        setSelectedEndDate(moment(end).format());
    }

    const handlePageSizeChange = (pageSize) => {
        setItemPerPage(pageSize)
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getGameHistory(username, 1, itemPerPage, start, end));
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getGameHistory(username, pageNumber, itemPerPage, start, end));
    }


    function onSubmit() {
        if (username == "") {
            NotificationManager.error({
                title: "ผิดพลาด",
                message: "กรุณาระบุ Username",
            });
            return;
        }
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getGameHistory(username, 1, itemPerPage, start, end));
    }

    return (
        <>
            {account === `` && (
                <>
                    <div className="page-header page-header-light">
                        <div className="page-header-content header-elements-md-inline align-items-center">
                            <div className="page-title d-flex">
                                <h4>รายงานการวางเดิมพัน</h4>
                            </div>
                        </div>
                        <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                            <div className="d-flex">
                                <div className="breadcrumb">
                                    <Link to="/" className="breadcrumb-item">
                                        <i className="icon-home2 mr-2"></i> หน้าแรก
                                    </Link>
                                    <span className="breadcrumb-item active">รายงานการวางเดิมพัน</span>
                                </div>
                                <a
                                    href="/#"
                                    className="header-elements-toggle text-default d-md-none"
                                >
                                    <i className="icon-more"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={`content ${account !== `` && `p-0`}`}>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <input type="search" className="form-control form-control-sm" value={username} onChange={e => setUsername(e.target.value)} placeholder="ค้นหาจาก Username" />
                            </div>
                            <div className="form-group mr-2">
                                <DateRangePicker
                                    initialSettings={initConfigInputLastMonth}
                                    onCallback={handleCallBackDateRang}
                                >
                                    <input type="text" style={{ width: "164px" }} className="form-control form-control-sm" />
                                </DateRangePicker>
                            </div>
                            <div className="form-group mr-2">
                                <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                                {historyList.data && historyList.data.length > 0 && (
                                    <tr>
                                        <td colSpan={3} className="text-right font-weight-bold border-bottom-0">รวม</td>
                                        {/*<td className="text-right font-weight-bold">0</td>*/}
                                        <td className={"text-right font-weight-bold h3 border-bottom-0 " + (historyList.sum.bet > 0 ? 'text-success' : 'text-danger')}>
                                            <CurrencyFormat value={historyList.sum.bet} displayType={'text'} decimalScale={2} thousandSeparator={true} />
                                        </td>
                                        <td className={"text-right font-weight-bold h3 border-bottom-0 " + (historyList.sum.win > 0 ? 'text-success' : 'text-danger')}>
                                            <CurrencyFormat value={historyList.sum.win} displayType={'text'} decimalScale={2} thousandSeparator={true} />
                                        </td>
                                        <td className={"text-right font-weight-bold h3 border-bottom-0 " + ((historyList.sum.bet + historyList.sum.win) > 0 ? 'text-success' : 'text-danger')}>
                                            <CurrencyFormat value={historyList.sum.bet + historyList.sum.win} displayType={'text'} decimalScale={2} thousandSeparator={true} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th width={150}>วันที่</th>
                                    <th>เกมส์</th>
                                    <th width={400}>ประเภท</th>
                                    <th className="text-right">Bet</th>
                                    <th className="text-right">Win</th>
                                    <th className="text-right">Win-Loss</th>
                                    <th className="text-right" width={150}>เคดิตหลังเดิมพัน</th>
                                </tr>
                            </thead>
                            <tbody>

                                {historyList.data && historyList.data.length > 0 ? historyList.data.map((item, index) => (
                                    <tr key={index}>
                                        <td><Moment format="YYYY-MM-DD HH:mm" date={item.time} /></td>
                                        <td>{item.provider}</td>
                                        <td>{item.betType}</td>
                                        <td className="text-right">{item.bet}</td>
                                        <td className="text-right">{item.win}</td>
                                        <td className="text-right h5 font-weight-bold">
                                            <div className={(item.winloss > 0 ? 'text-success' : (item.winloss != 0 ? 'text-danger' : ''))}>
                                                <CurrencyFormat value={item.winloss} displayType={'text'} decimalScale={2} thousandSeparator={true} />
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <CurrencyFormat value={item?.creditMeter ?? 0} displayType={'text'} decimalScale={2} thousandSeparator={true} />
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan={7} className="text-center">ไม่พบข้อมูล</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={historyList.pageSize}
                    totalItemsCount={historyList?.total ? historyList?.total : 0}
                    pageRangeDisplayed={5}
                    onChange={e => handlePageChange(e)}
                />
            </div>
        </>
    )
}


export default withRouter(GameHistory);
