import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {AgentContext} from "../../context/AgentContext";
import {NotificationManager} from "react-light-notifications";

export default function ChangMePassword({agent}) {

    const [data, setData] = useState({
        newPassword: "", password: "", confirmPassword: "", submitted: false, match: true,
    });

    const {createAgentStatus, setCreatAgentStatus, changePassword} = useContext(AgentContext)
    const {handleClose} = useContext(ModalContext)

    useEffect(() => {
        setCreatAgentStatus(0)
    }, []);

    const handleInputChange = event => {
        setData({
            ...data, [event.target.name]: event.target.value
        });
    };

    const submit = (e) => {
        e.preventDefault();
        console.log(data)
        if (data.password === "" || data.newPassword === "" || data.confirmPassword === "") {
            NotificationManager.info({
                title: "แจ้งเตือน", message: "กรอกข้อมูลให้ครบ",
            })
            setData({
                ...data, submited: false, match: false,
            })
            return;
        }

        if ((data.newPassword).length < 6 || (data.confirmPassword).length < 6) {
            NotificationManager.info({
                title: "แจ้งเตือน", message: "กรุณาตั้งรหัสผ่าน 6 ตัวอักษรขึ้นไป",
            })
            setData({
                ...data, submited: false, match: false,
            })
            return;
        }

        if (data.confirmPassword != data.newPassword) {
            NotificationManager.info({
                title: "แจ้งเตือน", message: "รหัสผ่านใหม่ไม่ตรงกัน",
            })
            setData({
                ...data, submited: false, match: false,
            })
            return;
        }

        setCreatAgentStatus()
        setData({
            ...data, submited: true, match: true,
        })

        changePassword(data)

    }

    return (<>
            <form onSubmit={e => submit(e)}>
                <div className="form-group row">
                    <label className="col-md-4">รหัสผ่านเดิม</label>
                    <div className="col-md-8">
                        <input type="password" onChange={handleInputChange} name="password" autoComplete="new-password" className="form-control"/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-4">รหัสผ่านใหม่</label>
                    <div className="col-md-8">
                        <input type="password" onChange={handleInputChange} name="newPassword" autoComplete="new-password" className="form-control"/>
                        <small>เฉพาะตัวอักษร A-Z a-z หรือ 0-9 และมีจำนวน 6 ตัวขึ้นไป</small>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-4">ยืนยันรหัสผ่านใหม่:</label>
                    <div className="col-md-8">
                        <input type="password" onChange={handleInputChange} name="confirmPassword" autoComplete="new-password" className="form-control"/>
                    </div>
                </div>
                <Modal.Footer>
                    <button type="submit" disabled={data.submited && createAgentStatus == 0} className="btn btn-primary">เปลี่ยนรหัสผ่าน</button>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>ปิดหน้าต่าง</button>
                </Modal.Footer>
            </form>
        </>)
};
