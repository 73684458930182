import React, {createContext, useContext, useReducer} from "react";
import {useDispatch} from "react-redux";

import AgentReducer from "../reducers/AgentReducer";
import AgentService from "../services/AgentService";
import {
    AGENT_ADD,
    AGENT_ADD_STATUS,
    AGENT_CHECK_PREFIX,
    AGENT_CLEAR,
    AGENT_FETCH,
    AGENT_UPDATE_KEY,
} from "../actions/type";
import {ModalContext} from "./ModalContext";
import AgentBankSlice from "../reducers/tools/AgentBankaccount";
import {NotificationManager} from "react-light-notifications";
import {GlobalContext} from "./GlobalContext";
import {ChangePassword} from "../api/me";
import {setModal} from "../reducers/tools/Utility";

export const initialState = {
    agents: [],
    add: 0,
    submitted: false,
    prefix: false,
};

export const AgentContext = createContext(initialState);
export const AgentProvider = ({children}) => {
    const dispatchGlobalU = useDispatch();

    const [state, dispatch] = useReducer(AgentReducer, initialState);
    const {dispatchModal} = useContext(ModalContext);
    const {dispatchGlobal} = useContext(GlobalContext)

    function createAgent(data) {
        setCreatAgentStatus();

        delete data.games;

        // console.log(data);
        dispatchGlobal({type: `LOADING`})
        AgentService.create(data)
            .then((response) => {
                dispatch({
                    type: AGENT_ADD,
                    payload: response.data,
                });
                closeModal();
                setCreatAgentStatus(200);
                NotificationManager.success({
                    title: "Success",
                    message: "Create new operator success",
                });
                dispatchGlobal({type: `SAVED`})
            })
            .catch((error) => {
                NotificationManager.error({
                    title: "Error",
                    message: "Someting wrong!",
                });
                dispatch({
                    type: AGENT_ADD_STATUS,
                    payload: 400,
                });
                dispatchGlobal({type: `FINISH`})
            });
    }

    function getDownline() {
        dispatchGlobal({type: `LOADING`})
        AgentService.getDownline()
            .then((response) => {
                dispatch({
                    type: AGENT_FETCH,
                    payload: response.data,
                });
                dispatchGlobal({type: `FINISH`})
            })
            .catch((e) => {
                console.log(e);
                dispatchGlobal({type: `ERROR`})
            });
    }

    function getOperator(prefix) {
        dispatchGlobal({type: `LOADING`})
        AgentService.getOperator(prefix)
            .then((response) => {
                dispatch({
                    type: AGENT_FETCH,
                    payload: response.data,
                });
                dispatchGlobal({type: `FINISH`})
            })
            .catch((e) => {
                console.log(e);
                dispatchGlobal({type: `ERROR`})
            });
    }

    function deposit(data) {
        dispatchGlobal({type: `LOADING`})
        AgentService.deposit(data)
            .then((response) => {
                dispatch({
                    type: AGENT_UPDATE_KEY,
                    payload: {
                        id: data.agentId,
                        key: "credit",
                        value: response.data.balance,
                    },
                });
                setActionStatus("topup_success");
                closeModal();
                dispatchGlobal({type: `SAVED`})
            })
            .catch((e) => {
                dispatch({
                    type: AGENT_ADD_STATUS,
                    payload: "topup_error",
                });
                dispatchGlobal({type: `FINISH`})
            });
    }

    function revoke(data) {
        dispatchGlobal({type: `LOADING`})
        AgentService.revoke(data)
            .then((response) => {
                dispatch({
                    type: AGENT_UPDATE_KEY,
                    payload: {
                        id: data.agentId,
                        key: "credit",
                        value: response.data.balance,
                    },
                });
                setActionStatus("revoke_success");
                closeModal();
                dispatchGlobal({type: `SAVED`})
            })
            .catch((e) => {
                dispatch({
                    type: AGENT_ADD_STATUS,
                    payload: "topup_error",
                });
                dispatchGlobal({type: `FINISH`})
            });
    }


    function resetPassword(id, data) {
        dispatchGlobal({type: `LOADING`})
        AgentService.resetPassword(id, data)
            .then(() => {
                setActionStatus("reset_password_success");
                closeModal();
                NotificationManager.success({
                    title: "Success",
                    message: "Reset password success",
                });
                dispatchGlobal({type: `SAVED`})
            })
            .catch((e) => {
                setActionStatus("reset_password_error");
                NotificationManager.error({
                    title: "Error",
                    message: "Reset password error",
                });
                dispatchGlobal({type: `FINISH`})
            });
    }

    function changePassword(data) {
        dispatchGlobal({type: `LOADING`})
        ChangePassword(data)
            .then(() => {
                setActionStatus("reset_password_success");
                dispatchGlobalU(setModal(false))
                NotificationManager.success({
                    title: "Success",
                    message: "Reset password success",
                });
                dispatchGlobal({type: `SAVED`})
            })
            .catch((e) => {
                setActionStatus("reset_password_error");
                if (e?.response?.status == 401) {
                    NotificationManager.error({
                        title: "Error",
                        message: "รหัสผ่านเดิมไม่ถูกต้อง",
                    });
                } else {
                    NotificationManager.error({
                        title: "Error",
                        message: "Change password error",
                    });
                }


                dispatchGlobal({type: `FINISH`})
            });
    }

    async function checkPrefix(prefix) {
        dispatch({
            type: AGENT_CHECK_PREFIX,
            payload: "checking_prefix",
        });

        if (prefix.length !== 4) {
            dispatch({
                type: AGENT_CHECK_PREFIX,
                payload: "check_prefix_error",
            });
            return;
        }

        AgentService.checkPrefix(prefix)
            .then((response) => {
                if (response.data === true) {
                    dispatchGlobalU(
                        AgentBankSlice.actions.currentPrefix({prefix: prefix})
                    );
                    dispatch({
                        type: AGENT_CHECK_PREFIX,
                        payload: "check_prefix_ok",
                    });
                } else {
                    dispatch({
                        type: AGENT_CHECK_PREFIX,
                        payload: "check_prefix_error",
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: AGENT_CHECK_PREFIX,
                    payload: "check_prefix_error",
                });
            });
    }

    /*Utility*/
    function closeModal() {
        dispatchModal({
            type: "MODAL_CLOSE",
            payload: true,
        });
    }

    function setActionStatus(status) {
        dispatch({
            type: AGENT_ADD_STATUS,
            payload: status,
        });
    }

    function setCreatAgentStatus(status = 0) {
        dispatch({
            type: AGENT_ADD_STATUS,
            payload: status,
        });

        if (status === 0) {
            dispatch({
                type: AGENT_CHECK_PREFIX,
                payload: false,
            });
        }
    }

    function clearAgents() {
        dispatch({
            type: AGENT_CLEAR,
            payload: {},
        });
    }

    return (
        <AgentContext.Provider
            value={{
                agents: state.agents,
                createAgentStatus: state.add,
                checkPrefixStatus: state.prefix,
                createAgent,
                getDownline,
                getOperator,
                setCreatAgentStatus,
                deposit,
                revoke,
                changePassword,
                resetPassword,
                checkPrefix,
                clearAgents,
            }}
        >
            {children}
        </AgentContext.Provider>
    );
};
