import {Modal} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ModalContext} from "../../context/ModalContext";
import {UserContext} from "../../context/UserContext";
import {NotificationManager} from "react-light-notifications";

const BankAccount = ({user, handlePageChange}) => {
    console.log(user)
    let [AccountNumber, setAccountNumber] = useState("");
    let [Bankcode, setBankCode] = useState("KBANK");
    let [FullnameTH, setFullnameTH] = useState("");
    let [FullnameEN, setFullnameEN] = useState("");
    let [Newmember, setNewmember] = useState(false);

    useEffect(() => {
        setAccountNumber(user.bankAccount?.accountNumber)
        setBankCode(user.bankAccount?.bank)
        setFullnameTH(user.bankAccount?.fullName)
        setFullnameEN(user.bankAccount?.fullNameEN)
    }, [user])

    const {handleClose} = useContext(ModalContext);
    const {bank} = useContext(UserContext);

    let BankList = useSelector((state) => {
        return state.agentBank?.bankList || [];
    });


    const submit = (e) => {
        e.preventDefault()
        if (Bankcode == "" || FullnameEN == "" || FullnameTH == "" || AccountNumber == "") {
            // NotificationManager.success({
            //     title: "Error",
            //     message: "Please fill all input",
            // });
            // return
        }
        // AccountNumber: accountNumber,
        //     FullNameEN:    fullNameEN,
        //     FullNameTH:    fullNameTH,
        //     Bank:          bankCode,
        // const username = user.username
        bank(user.id, {
            accountNumber: AccountNumber,
            fullNameEN: FullnameEN,
            fullNameTH: FullnameTH,
            bank: Bankcode,
        }, handlePageChange)
    };
    return (
        <>
            <form onSubmit={e => submit(e)}>
                <div className="form-group row">
                    <label className="col-md-3">Bank</label>
                    <div className="col-md-9">
                        <select
                            name="bank"
                            onChange={(e) => setBankCode(e.target.value)}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>เลือกธนาคาร</option>
                            {BankList.map(({code, name}, index) => (
                                <option key={code} value={code} selected={code == Bankcode}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group mb-2 row">
                    <label className="col-sm-3 col-form-label">
                        Account Number
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            maxLength={13}
                            name="accountNumber"
                            onChange={(e) => setAccountNumber(e.target.value)}
                            value={AccountNumber}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="form-group mb-2 row">
                    <label className="col-sm-3 col-form-label">
                        Fullname EN
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            maxLength={50}
                            name="fullNameEN"
                            onChange={(e) => setFullnameEN(e.target.value)}
                            value={FullnameEN}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="form-group mb-2 row">
                    <label className="col-sm-3 col-form-label">
                        Fullname TH
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            name="fullNameTH"
                            maxLength={50}
                            onChange={(e) => setFullnameTH(e.target.value)}
                            value={FullnameTH}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <Modal.Footer>
                    <button type="submit" onSubmit={e => submit(e)} className="btn btn-primary">บันทึก</button>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>ปิดหน้าต่าง</button>
                </Modal.Footer>
            </form>
        </>
    )
}

export default BankAccount
