import http from "../http-common";

function gets() {
    return [
        "PGSOFT",
        "SLOTXO",
        "LIVE22",
        "SEXY",
        "DREAM",
        "SAGAME",
    ]
}

function getGames(provider){
    return http.get("/game?provider=" + provider)
}

export default {
    gets,
    getGames
}
