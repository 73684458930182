import http, {API_TOKEN} from "../http-common";

export const scopes = [
    {
        name: "Member",
        scopes: {
            read: "member.read",
            write: "member.write",
        }
    }, {
        name: "Game",
        scopes: {
            read: "game.read",
            write: "game.write",
        }
    }, {
        name: "Transection",
        scopes: {
            read: "transection.read",
            write: "transection.write",
        }
    }, {
        name: "Promtoion",
        scopes: {
            read: "promtoion.read",
            write: "promtoion.write",
        }
    }, {
        name: "History",
        scopes: {
            read: "history.read",
            write: "history.write",
        }
    }, {
        name: "Sharing",
        scopes: {
            read: "sharing.read",
            write: "sharing.write",
        }
    },
]


const getAll = () => {
    return http.get("/operator/roles");
};

const create = data => {
    return http.post("/operator/roles", data);
};

export default {
    getAll,
    create,
};
