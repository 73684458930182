import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";
import {useDispatch} from "react-redux";
import {UserMeInfo} from "../../reducers/tools/AccountUsers";

const localStorageService = LocalStorageService.getService()
const SidebarItem = ({index, route, currentPath, setActiveRouter, activeRoute, withdrawCount, depositCount}) => {
    // const dispatchGlobal = useDispatch();

    // useEffect(() => {
    //     dispatchGlobal(UserMeInfo());
    // });

    // const [routeActive, setRouteActive] = useState(false);

    // if (route.type === "submenu") {

    // }
    const roleName = route.role
    const me = localStorageService.getMe();
    let isMenu = roleName.indexOf(me?.role) > -1

    switch (route.type) {
        case 'submenu' :
            return (
                <li className={"nav-item nav-item-submenu " + (activeRoute === index || currentPath ? "nav-item-open" : "")}>
                    <Link to="/" className="nav-link" onClick={() => {
                        setActiveRouter(index)
                    }}>
                        <route.icon className="mr-1"/>
                        <span>{route.name}</span>
                    </Link>
                    <ul className={"nav nav-group-sub " + (activeRoute === index || currentPath ? "d-block" : "")} data-submenu-title="Layouts">
                        {route.children.map((subitem, i) => (
                            <li className="nav-item" key={i}>
                                <Link to={`${route.path ? route.path : ''}${subitem.path ? subitem.path : ''}`} onClick={() => setActiveRouter(index)} className="nav-link">{subitem.name}</Link>
                            </li>
                        ))}
                    </ul>
                </li>
            );
        case 'header' :
            return (
                <li className="nav-item-header">
                    <div className="text-uppercase font-size-xs line-height-xs">{route.name}</div>
                </li>
            );
        default:
            if (isMenu) {
                return (
                    <li className={"nav-item top-nav " + (activeRoute === index || currentPath ? "nav-item-open" : "")}>
                        <Link to={route.path} onClick={() => setActiveRouter(index)} className="nav-link">
                            <route.icon className="mr-1"/>
                            <span>{route.name}</span>
                            {route.key == "dashboard" && (
                                <span className="badge badge-danger ml-1">{withdrawCount + depositCount}</span>)}
                            {route.key == "deposit" && depositCount > 0 && (
                                <span className="badge badge-danger ml-1">{depositCount}</span>)}
                            {route.key == "withdraw" && withdrawCount > 0 && (
                                <span className="badge badge-danger ml-1">{withdrawCount}</span>)}
                        </Link>
                    </li>
                );
            }else
                return (<></>)
    }

};


export default SidebarItem;
