import React, {cloneElement} from 'react';
import {LoginProvider} from "./LoginContext";
import {RoleProvider} from "./RoleContext";
import {GameProvider} from "./GameContext";
import {AgentProvider} from "./AgentContext";
import {ModalProvider} from "./ModalContext";
import {UserProvider} from "./UserContext";
import {GPRProvider} from "./GameProviderContext";
import {GLobalProvider} from "./GlobalContext";


function ProviderComposer({contexts, children}) {
    return contexts.reduce(
        (kids, parent) =>
            cloneElement(parent, {
                children: kids,
            }),
        children
    );
}

export default function ContextProvider({children}) {
    return (
        <ProviderComposer
            // add providers to array of contexts
            contexts={[
                <LoginProvider/>,
                <RoleProvider/>,
                <GameProvider/>,
                <AgentProvider/>,
                <UserProvider/>,
                <ModalProvider/>,
                <GPRProvider/>,
                <GLobalProvider/>,
            ]}
        >
            {children}
        </ProviderComposer>
    );
}
