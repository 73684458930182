import React, {useState, useEffect, useContext} from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Workspace from "../components/Workspace/Workspace";
import {Route, Switch, Redirect} from "react-router-dom";
import routes from "../routes";
import {UserMeInfo} from "../reducers/tools/AccountUsers";
import {useDispatch, useSelector} from "react-redux";
import LocalStorageService from "../services/LocalStorageService";
import {GlobalContext} from "../context/GlobalContext";
import {NotificationManager} from "react-light-notifications";


const Dashboard = () => {
    const localStorageService = LocalStorageService.getService()
    const me = localStorageService.getMe();
    const dispatchGlobal = useDispatch();
    const [opened] = useState(true);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [myRoutes, setMyRoutes] = useState(true);
    const {loading} = useContext(GlobalContext)

    let loadingRedux = useSelector((state) => {
        return state.globalSet?.loadingRedux || 0;
    });

    useEffect(() => {
        dispatchGlobal(UserMeInfo());
    }, []);

    useEffect(() => {
        if (localStorageService.getAccessToken() == null || localStorageService.getAccessToken() == ``) {
            window.location.href = `/login`
        }
    }, [localStorageService]);

    useEffect(() => {
        //console.log(loading)
        if (loading == 3) {
            NotificationManager.warning({
                title: "Warning",
                message: "โหลดข้อมูลไม่สำเร็จ",
            });
        }
    }, [loading])

    useEffect(() => {
        // console.log(loadingRedux)
        if (loadingRedux == 3) {
            NotificationManager.warning({
                title: "Warning",
                message: "โหลดข้อมูลไม่สำเร็จ",
            });
        }
    }, [loadingRedux])


    const getRoutes = (
        <Switch>
            {routes.items.map((item, index) =>

                item.type === "external" ? (
                    <Route
                        exact
                        path={item.path}
                        component={item.component}
                        name={item.name}
                        key={index}
                    />
                ) : item.type === "submenu" ? (
                    item.children.map((subItem) => (
                        <Route
                            exact
                            path={`${item.path}${subItem.path}`}
                            component={subItem.component}
                            name={subItem.name}
                            key={index}
                        />
                    ))
                ) : item.type !== "header" ? (
                    item.role.includes(me?.role) ? (
                        <Route
                            exact
                            path={item.path}
                            component={item.component}
                            name={item.name}
                            key={index}
                        />
                    ) : (
                        ""
                    )
                ) : (
                    ""
                )
            )}
            {/*<Redirect to="/404"/>*/}
        </Switch>
    );



    return (
        <div>
            <Header setOpenSideBar={setOpenSideBar} openSideBar={openSideBar}/>
            <div className="page-content">
                <Sidebar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} routes={routes.items} opened={opened}/>
                <Workspace opened={opened}>{getRoutes}</Workspace>
            </div>

            {(loading == 1 || loadingRedux == 1) && (
                <>
                    <div className="page-loading">
                        <div className="loading-icon">
                            <div>
                                <div className="lds-roller">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div>กรุณารอสักครู่</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;
