import React from "react";
import MyModal from "../Utility/MyModal";
import {myModal} from "../../reducers/tools/Utility";
import {useSelector} from "react-redux";

const Workspace = ({children, opened}) => {

    const myModalApp = useSelector(myModal)



    return (
        <div className="content-wrapper bg-grey-100">
            {children}

            <MyModal title={myModalApp?.title} size={myModalApp?.size}>{myModalApp?.content}</MyModal>
        </div>
    );
};

export default Workspace;
