export const LOGIN = "login_authen";
export const LOGIN_ME = "login_me";
export const LOGOUT = "logout";


export const ROLE_FETCH = 'role_fetch';
export const ROLE_ADD = 'role_add';
export const ROLE_EDIT = 'role_edit';
export const ROLE_REMOVE = 'role_remove';
export const ROLE_ACTION_STATUS = 'role_action_status';


export const GAME_FETCH = 'game_fetch';


export const AGENT_FETCH = 'agent_fetch';
export const AGENT_ADD = 'agent_add';
export const AGENT_ADD_STATUS = 'agent_add_status';
export const AGENT_EDIT = 'agent_edit';
export const AGENT_REMOVE = 'agent_remove';
export const AGENT_UPDATE_KEY = 'agent_update_key';
export const AGENT_CHECK_PREFIX = 'agent_check_prefix';
export const AGENT_CLEAR = 'agent_clear';


export const USER_FETCH = 'user_fetch';
export const USER_ADD = 'user_add';
export const USER_ADD_STATUS = 'user_add_status';
export const USER_EDIT = 'user_edit';
export const USER_REMOVE = 'user_remove';
export const USER_UPDATE_KEY = 'user_update_key';
export const USER_UPDATE_BALANCE = 'user_update_balance';


export const GPROVIDER_FETCH = 'gprovider_fetch';
export const GPROVIDER_CLEAR_FETCH = 'gprovider_clear_fetch';
