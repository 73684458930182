import http from "../http-common";


const getAll = (prefix) => {
    return http.get("/operator?prefix=" + prefix);
};
const checkPrefix = (prefix) => {
    return http.get("/operator/checkprefix?prefix=" + prefix);
};

const getDownline = () => {
    return http.get("/operator/downline");
};


const getOperator = (prefix) => {
    return http.get("/operator?prefix=" + prefix);
};


const create = (data) => {
    return http.post("/operator", data);
}

const deposit = (data) => {
    return http.post("/operator/credit/topup", data);
}

const revoke = (data) => {
    return http.post("/operator/credit/revoke", data);
}

const changePassword = (data) => {
    return http.post("/operator/me/password", data);
}
const resetPassword = (id, data) => {
    return http.patch("/operator/" + id + "/resetpassword", data);
}

export default {
    getAll,
    create,
    getDownline,
    getOperator,
    resetPassword,
    changePassword,
    deposit,
    revoke,
    checkPrefix
};
