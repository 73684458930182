import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    DisablePromotionOfAgent,
    fetchPromotionOfAgent,
    UpdatePromotionStatusOfAgent
} from "../../reducers/tools/AgentPromotion";
import {Link, withRouter} from "react-router-dom";
import ModalApp from "../../components/Modal/ModalApp";
import PromotionForm from "../../components/Promotion/PromotionForm";
import CurrencyFormat from "react-currency-format";


const Promotion = () => {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(0)
    const MeInfo = useSelector((state) => {
        return state.accountUser?.accountInfo || {};
    });

    let PromotionList = useSelector((state) => {
        return state.agentPromotion?.promotions || [];
    });
    let TypeList = useSelector((state) => {
        return state.agentPromotion?.type || [];
    });
    let BonusList = useSelector((state) => {
        return state.agentPromotion?.bonus || [];
    });

    useEffect(() => {
        document.title = "โปรโมชั่น";
    }, [])

    useEffect(() => {
       // console.log(MeInfo)
        if (typeof MeInfo.prefix != 'undefined' && load == 0) {
            dispatch(fetchPromotionOfAgent(MeInfo.prefix))
            setLoad(1)
        }
    }, [dispatch, MeInfo])

    const [modalComponent, setModalComponent] = useState(null);
    const modalRef = useRef(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }


    const handleDisablePromotion = (id) => {
        if (window.confirm("ยืนยันการยกเลิก")) {
            dispatch(UpdatePromotionStatusOfAgent(id, MeInfo.prefix))
        }
    }

    return (
        <>
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />

            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>บัญชีเอเยนต์</h4>
                    </div>
                    <div>
                        {MeInfo.role === "agent" && (
                            <button
                                onClick={() =>
                                    onOpenModalApp(
                                        {
                                            title: "เพิ่มโปรโมชั่น",
                                            size: "lg",
                                        },
                                        <PromotionForm/>
                                    )
                                }
                                className="btn btn-warning btn-sm"
                            >
                                เพิ่มโปรโมชั่น
                            </button>
                            // </div>
                        )}
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">เอเยนต์ - โปรโมชั่น</span>
                        </div>
                        <a href="/" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th width="300" className="text-left">โปรโมชั่น</th>
                                <th width="150" className="text-center">ประเภท</th>
                                <th width="150" className="text-center">รูปแบบโบนัส</th>
                                <th width="160" className="text-center">โบนัสสูงสุด</th>
                                <th width="160" className="text-center">ฝากต่ำสุด/สูงสุด</th>
                                <th width="160" className="text-center">ถอนต่ำสุด/สูงสุด</th>
                                <th width="160" className="text-center">ยอดเทิร์น</th>
                                <th width="160" className="text-center">สถานะ</th>
                                {/*<th width="250" className="text-center">แก้ไขเมื่อ</th>*/}
                                <th className="text-center" width="200"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {PromotionList.length > 0 ? (
                                PromotionList.map(item => (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td className="text-center">{TypeList[item.type - 1]?.name}</td>
                                        <td className="text-center">{BonusList[item.bonusType - 1]?.name}</td>
                                        <td className="text-center">
                                            <CurrencyFormat value={item.maxBonus} displayType={'text'} thousandSeparator={true}/>
                                        </td>
                                        <td className="text-center">
                                            <CurrencyFormat value={item.minDeposit} displayType={'text'} thousandSeparator={true}/>/ <CurrencyFormat value={item.maxDeposit} displayType={'text'} thousandSeparator={true}/>
                                        </td>
                                        <td className="text-center">
                                            <CurrencyFormat value={item.minWithdraw} displayType={'text'} thousandSeparator={true}/>/ <CurrencyFormat value={item.maxWithdraw} displayType={'text'} thousandSeparator={true}/>
                                        </td>
                                        <td className="text-center">
                                            <CurrencyFormat value={item.turnOver} displayType={'text'} thousandSeparator={true}/>
                                        </td>
                                        <td className="text-center">
                                            {item.status ? (<span className="badge badge-success">ใช้งาน</span>) : (
                                                <span className="badge badge-danger">ปิดใช้งาน</span>)}
                                        </td>
                                        {/*<td className="text-center">*/}
                                        {/*    {item.latestUpdate}*/}
                                        {/*</td>*/}
                                        <td className="text-center">
                                            {(MeInfo.role === "agent" || MeInfo.role === "operator") && (
                                                <>
                                                    <button
                                                        onClick={() =>
                                                            onOpenModalApp(
                                                                {
                                                                    title: "แก้ไขโปรโมชั่น",
                                                                    size: "lg",
                                                                },
                                                                <PromotionForm promotion={item}/>
                                                            )
                                                        }
                                                        className="btn btn-warning btn-sm mr-1"
                                                    >
                                                        {MeInfo.role === "agent" ? `แก้ไข` : `รายละเอียด`}
                                                    </button>
                                                    {MeInfo.role === "agent" && (
                                                        <>
                                                            {item.status ? (
                                                                <button type="button" onClick={() => handleDisablePromotion(item.id)} className="btn btn-danger btn-sm">ยกเลิก</button>) : (
                                                                <button type="button" onClick={() => handleDisablePromotion(item.id)} className="btn btn-success btn-sm">เปิดใช้งาน</button>)}
                                                        </>
                                                    )}


                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={20} className="text-center">
                                        ไม่พบข้อมูล
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default withRouter(Promotion);
