import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {getAffReport, getSharingReport} from "../../reducers/tools/Report";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputLastMonth} from "../../services/config";
import CurrencyFormat from "react-currency-format";
import {rowRuning} from "../../services/functions";
import Pagination from "react-js-pagination";

const Aff = () => {


    const dispatch = useDispatch();


    const affLists = useSelector((state) => {
        return state.report.affReport || [];
    });

    useEffect(() => {
        dispatch(getAffReport())

    }, []);


    return (
        <>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>รายงาน Affiliate</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">รายงาน Affiliate</span>
                        </div>
                        <a
                            href="/#"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card mb-4">
                    <div className="card-body">
                    </div>

                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th>ID</th>
                                <th className="text-right" width={150}>Profit</th>
                                <th className="text-right" width={150}>Settled</th>
                                <th className="text-right" width={150}>Total</th>

                            </tr>
                            </thead>
                            <tbody>

                            {affLists.data && affLists.data.length > 0 ? affLists.data.map((item, i) => (
                                <tr key={item.username}>
                                    <td className="text-center">{rowRuning(i, 1, 100)}</td>
                                    <td>
                                        <Link class="text-info font-weight-bold" to={`/report/gamehistory?username=${item.username}`}>{item.username}</Link>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.profit} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.settled} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-right">
                                        <CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>
                                    </td>

                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>


                </div>


            </div>


        </>
    )
}

export default Aff
