import React, {useContext, useEffect, useRef, useState} from "react";
import {Modal, Tab, Tabs} from "react-bootstrap";
import {GameContext} from "../../context/GameContext";
import {AgentContext} from "../../context/AgentContext";
import {ModalContext} from "../../context/ModalContext";
import LocalStorageService from "../../services/LocalStorageService";
import {Timer} from "@material-ui/icons";
import {GameProviderContext} from "../../context/GameProviderContext";

const localStorageService = LocalStorageService.getService()

const initDataState = {
    prefix: "",
    username: "",
    password: "",
    fullName: "",
    sharing: 0,
    contact: "",
    role: "agent",
    scopes: [
        "all"
    ],
    games: [],
    submited: false
};

const NewAg = () => {

    const me = localStorageService.getMe();
    const [tab, setTab] = useState(0)

    const [data, setData] = useState(initDataState)
    const {getGames, games} = useContext(GameContext)
    const {getProviders, providers} = useContext(GameProviderContext)
    const {handleClose} = useContext(ModalContext)
    const {
        createAgent,
        createAgentStatus,
        setCreatAgentStatus,
        checkPrefix,
        checkPrefixStatus
    } = useContext(AgentContext)

    useEffect(() => {
        //getGames();
        setCreatAgentStatus();
    }, []);

    useEffect(() => {
        getProviders()
    }, [])

    useEffect(() => {
        // const key = (u === 'agent' || u === 'member') ? 'game' : 'role';
        tabActiveHandler(0)
    }, [])


    const tabActiveHandler = (key) => {
        setTab(key)
    }

    const handleInputChange = event => {

        let value = event.target.value
        if (event.target.name == "sharing")
            value = parseFloat(value)

        if (event.target.name === "prefix") {
            if (me.role !== "admin") {
                if (value.length < 4) {
                    value = (me.prefix).substring(0, 2) + value
                }
            }
        }

        setData({
            ...data,
            [event.target.name]: value
        });
    };


    const handleGame = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked) {
            setData({
                ...data,
                games: [...data.games, value]
            })
        } else {
            const games = data.games;
            var index = games.indexOf(value)
            if (index !== -1) {
                games.splice(index, 1);
                setData({
                    ...data,
                    games: [games]
                })
            }
        }
    }


    const handlePrefix = (e) => {
        e.preventDefault();
        checkPrefix(data.prefix)
    }

    const submit = () => {
        setCreatAgentStatus()
        setData({
            ...data,
            submited: true,
        })
        // console.log(data);
        createAgent(data);
    }

    return (
        <>
            {createAgentStatus == 400 && (
                <div className="alert alert-danger">ผิดพลาดในการปรมวลผล กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง</div>
            )}
            <div className="row">
                <div className="col-lg-12">
                    {/*<div className="form-group mb-2 row">*/}
                    {/*    <label className="col-sm-3 col-form-label">Role</label>*/}
                    {/*    <div className="col-sm-9 d-flex align-items-center">*/}
                    {/*        <div className="d-flex align-items-center mr-3">*/}
                    {/*            <input type="radio" name="role" id="agent" onClick={() => handleRoleChange('agent')} checked={u === "agent"} value=""/>*/}
                    {/*            <label onClick={() => handleRoleChange('agent')} className="form-check-label ml-1">ดาวน์ไลน์</label>*/}
                    {/*        </div>*/}
                    {/*        {me.role !== "admin" && (*/}
                    {/*            <>*/}
                    {/*                <div className="d-flex align-items-center mr-3">*/}
                    {/*                    <input type="radio" name="role" id="operator" onClick={() => handleRoleChange('operator')} checked={u === "operator"} value=""/>*/}
                    {/*                    <label onClick={() => handleRoleChange('operator')} className="form-check-label ml-1">พนักงาน</label>*/}
                    {/*                </div>*/}
                    {/*                <div className="d-flex align-items-center mr-3">*/}
                    {/*                    <input type="radio" name="role" id="member" onClick={() => handleRoleChange('member')} checked={u === "member"} value="oparator"/>*/}
                    {/*                    <label onClick={() => handleRoleChange('member')} className="form-check-label ml-1">สมาชิก</label>*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Prefix</label>
                        <div className="col-sm-9">
                            {me.role === "agent" ? (
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">{(me.prefix).substring(0, 2)}</span>
                                    </div>
                                    <input type="text" className="form-control form-control-sm" name="prefix" onChange={handleInputChange} maxLength={2} placeholder="2 Charecter"/>
                                </div>
                            ) : (
                                <input type="text" className="form-control form-control-sm" name="prefix" onChange={handleInputChange} maxLength={4} placeholder="4 Charecter"/>
                            )}
                            <div className="pt-1">

                                {!checkPrefixStatus && (
                                    <a href="#" onClick={e => handlePrefix(e)}>ตรวจสอบ Prefix</a>)}
                                {checkPrefixStatus === "checking_prefix" && (
                                    <a href="#" onClick={e => handlePrefix(e)}><Timer/> กำลังตรวจสอบ...</a>)}
                                {checkPrefixStatus === "check_prefix_ok" && (
                                    <a href="#" className="text-success" onClick={e => handlePrefix(e)}><i className="icon-check"></i> สามารถใช้งาน prefix ได้</a>)}
                                {checkPrefixStatus === "check_prefix_error" && (
                                    <a href="#" className="text-danger" onClick={e => handlePrefix(e)}><i className="icon-close2"></i> ไม่สามารถใช้งาน prefix ได้</a>)}
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Username</label>
                        <div className="col-sm-9">
                            <input type="text" name="username" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Password</label>
                        <div className="col-sm-9">
                            <input type="password" name="password" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Full Name</label>
                        <div className="col-sm-9">
                            <input type="text" name="fullName" onChange={handleInputChange} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Contact</label>
                        <div className="col-sm-9">
                            <input type="text" name="contact" onChange={handleInputChange} className="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Sharing</label>
                        <div className="col-sm-9">
                            <input type="number" name="sharing" onChange={handleInputChange} className="form-control form-control-sm" placeholder="Max 100"/>
                        </div>
                    </div>


                </div>
                {/* <div className="col-lg-7">
                    <Tabs activeKey={tab} defaultActiveKey={tab} id="uncontrolled-tab-example" onSelect={(key) => tabActiveHandler(key)}> */}
                        {/*<Tab eventKey="game" title="Game ทั้งหมด">*/}
                        {/*    <div className="px-3 pt-0">*/}
                        {/*        <ul className="list-unstyled list-item">*/}
                        {/*            {games.length > 0 ? (*/}
                        {/*                <>*/}
                        {/*                    {games.map(game => (*/}
                        {/*                        <li key={game.id}>*/}
                        {/*                            <input value={game.shortName} onClick={e => handleGame(e)} type="checkbox"/>*/}
                        {/*                            <label>{game.name}</label></li>*/}
                        {/*                    ))}*/}
                        {/*                </>*/}
                        {/*            ) : (*/}
                        {/*                <>No Game</>*/}
                        {/*            )}*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</Tab>*/}


                        {/* {Array.isArray(providers) && providers.map((provider, index) => (
                            <Tab eventKey={index} key={index} title={provider.provider}>
                                <div className="px-3 pt-0">
                                    <ul className="list-unstyled list-item row">
                                        {Array.isArray(provider.games) > 0 && (
                                            <>
                                                {provider.games.map(game => (
                                                    <li className="col-6" key={game.id}>
                                                        <input value={game.shortName} onClick={e => handleGame(e)} type="checkbox"/>
                                                        <label>{game.name}</label>
                                                    </li>
                                                ))}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </Tab>
                        ))} */}

                    {/* </Tabs>
                </div> */}

            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && createAgentStatus == 0} onClick={submit} className="btn btn-primary">Save changes</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </>
    );
};


export default NewAg;
