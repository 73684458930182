import * as http from "../http-common";

export function GetAllTransaction(filter) {
    const page = filter.page >= 1 ? filter.page : 1;
    const pageSize = filter.pageSize >= 15 ? `&pageSize=${encodeURIComponent(filter.pageSize)}` : `&pageSize=15`;
    const from = filter.from ? `&startTime=${encodeURIComponent(filter.from)}` : ``;
    const to = filter.to ? `&endTime=${encodeURIComponent(filter.to)}` : ``;
    const u = filter.username ? `&username=${encodeURIComponent(filter.username)}` : ``;
    const status = (filter.status && typeof filter.status != 'undefined') ? `&status=${filter.status}` : ``;
    return http.get(`transaction?page=${page}${pageSize}${from}${to}${status}${u}`);
}

export function GetTransactionDeposit(_page, _pageSize, _from, _to, _status, firstTime, username = ``) {
    const page = _page >= 1 ? _page : 1;
    const pageSize = _pageSize >= 15 ? `&pageSize=${encodeURIComponent(_pageSize)}` : `&pageSize=15`;
    const from = _from ? `&startTime=${encodeURIComponent(_from)}` : ``;
    const to = _to ? `&endTime=${encodeURIComponent(_to)}` : ``;
    const status = (_status && typeof _status != 'undefined') ? `&status=${_status}` : ``;
    const firstTimeC = firstTime ? `&firstTime=true` : ``
    const u = username ? `&username=${username}` : ``
    return http.get(`transaction/deposit?page=${page}${pageSize}${from}${to}${status}${firstTimeC}${u}`);
}

export function GetTransactionWithdraw(_page, _pageSize, _from, _to, _status, username = ``) {
    const page = _page >= 1 ? _page : 1;
    const pageSize = _pageSize >= 15 ? `&pageSize=${encodeURIComponent(_pageSize)}` : `&pageSize=15`;
    const from = _from ? `&startTime=${encodeURIComponent(_from)}` : ``;
    const to = _to ? `&endTime=${encodeURIComponent(_to)}` : ``;
    const status = (_status && typeof _status != 'undefined') ? `&status=${_status}` : ``;
    const u = username ? `&username=${username}` : ``

    return http.get(`transaction/withdraw?page=${page}${pageSize}${from}${to}${status}${u}`);

}

export function ConfirmWithdraw(txnId) {
    return http.put(`transaction/withdraw/${txnId}`, {});
}

export function ConfirmDeposit(txnId, model) {
    return http.put(`transaction/deposit/${txnId}`, model);
}

export function CancelDeposit(txnId) {
    return http.deleteHttp(`transaction/deposit/${txnId}`);
}

export function CancelWithdraw(txnId) {
    return http.deleteHttp(`transaction/withdraw/${txnId}`);
}

export function GetDepositTotal(from, to) {
    return http.get(`transaction/deposit/total?startTime=${encodeURIComponent(from)}&endTime=${encodeURIComponent(to)}`);
}

export function GetWithdrawTotal(from, to) {
    return http.get(`transaction/withdraw/total?startTime=${encodeURIComponent(from)}&endTime=${encodeURIComponent(to)}`);
}

export function ClearPromotion(prefix, userId) {
    return http.deleteHttp(`user/${prefix}/${userId}/clearpromo`);
}
