import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import ModalApp from "../../components/Modal/ModalApp";
import AccountAg from "../../components/AgentAccount/AccountAg";
import {AgentContext} from "../../context/AgentContext";

import {useDispatch, useSelector} from "react-redux";
import SettingBank from "../../components/AgentAccount/SettingBank";
import AppListDevice from "../../components/AgentAccount/ApplistDevice";
import {
    fetchDepositBankOfAgent,
    fetchWithdrawBankOfAgent,

    EnableBankDepositAgent,
    DisableBankAgentDeposit,

    EnableBankWithdrawAgent,
    DisableBankAgentWithdraw,

} from "../../reducers/tools/AgentBankaccount";

const AccountAgents = () => {
    const dispatch = useDispatch();
    const MeInfo = useSelector((state) => {
        return state.accountUser.accountInfo || {};
    });

    const {setCreatAgentStatus, getDownline, agents, clearAgents} =
        useContext(AgentContext);

    useEffect(
        (e) => {
            document.title = "บัญชีธนาคาร";

            if (MeInfo.role === "admin") {
                setCreatAgentStatus();
                clearAgents();
                getDownline();
            }

            if (MeInfo.role === "agent") {
                dispatch(fetchDepositBankOfAgent());
                dispatch(fetchWithdrawBankOfAgent());
            }
        },
        [MeInfo.role, dispatch]
    );

    const [modalComponent, setModalComponent] = useState(null);

    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });

    const modalRef = useRef(null);

    let allBankListAgent = useSelector((state) => {
        return state.agentBank?.allBankDW || [];
    });

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }

    return (
        <>
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />

            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>บัญชีเอเยนต์</h4>
                    </div>
                    <div>
                        {MeInfo.role === "agent" && (
                            <button
                                onClick={() =>
                                    onOpenModalApp(
                                        {
                                            title: "เพิ่มบัญชีเอเยนต์",
                                            size: "lg",
                                        },
                                        <AccountAg/>
                                    )
                                }
                                className="btn btn-warning btn-sm"
                            >
                                เพิ่มบัญชี
                            </button>
                        )}
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">
                                เอเยนต์ - ข้อมูลบัญชีเอเยนต์
                            </span>
                        </div>
                        <a
                            href="/"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>

                    {MeInfo.role === "agent" && (
                        <div className="table-responsive">
                            <table className="table table-sm table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th className="text-center">เลขบัญชี</th>
                                    <th className="text-center">ธนาคาร</th>
                                    <th className="text-center">ชื่อบัญชีไทย</th>
                                    <th className="text-center">ชื่อบัญชีอังกฤษ</th>
                                    <th className="text-center">ประเภทบัญชี</th>
                                    <th className="text-center">สถานะ</th>
                                    <th className="text-center">ตั้งค่า</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allBankListAgent.length > 0 ? (
                                    allBankListAgent.map((dp) => (
                                        <tr key={dp.id}>
                                            <td>{dp.number}</td>
                                            <td>{dp.bank}</td>
                                            <td>{dp.nameTH}</td>
                                            <td>{dp.nameEN}</td>
                                            <td className="text-center">{dp.type}</td>
                                            <td className="text-center">{dp.status}</td>
                                            <td>

                                                {dp.type === "withdraw" && (<button
                                                    className="btn btn-warning btn-sm mr-1"
                                                    onClick={() =>
                                                        onOpenModalApp(
                                                            {
                                                                title: "ตั้งค่าระบบ",
                                                                size: "sm",
                                                            },
                                                            <SettingBank detail={dp}/>
                                                        )
                                                    }
                                                >
                                                    ConfigApp
                                                </button>)}

                                                <button
                                                    className="btn btn-warning btn-sm mx-1"
                                                    onClick={() =>
                                                        onOpenModalApp(
                                                            {
                                                                title: "รายการ Application",
                                                                size: "xl",
                                                            },
                                                            <AppListDevice detail={dp}/>
                                                        )
                                                    }
                                                >
                                                    App list
                                                </button>

                                                {dp.status === "inactive" && dp.type === "withdraw" && (<button
                                                    className="btn btn-success btn-sm mr-1"
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        let cEnable = confirm("Are you sure enable bank this?");
                                                        if (cEnable) {
                                                            dispatch(EnableBankWithdrawAgent(dp.id))
                                                        }
                                                    }}
                                                >
                                                    เปิดใช้งานบัญชี
                                                </button>)}

                                                {dp.status === "active" && dp.type === "withdraw" && (<button
                                                    className="btn btn-danger btn-sm mr-1"
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        let cDisable = confirm("Are you sure disable bank this?");
                                                        if (cDisable) {
                                                            dispatch(DisableBankAgentWithdraw(dp.id))
                                                        }
                                                    }}
                                                >
                                                    ยกเลิกบัญชี
                                                </button>)}


                                                {dp.status === "inactive" && dp.type === "deposit" && (<button
                                                    className="btn btn-success btn-sm mr-1"
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        let cEnable = confirm("Are you sure enable bank this?");
                                                        if (cEnable) {
                                                            dispatch(EnableBankDepositAgent(dp.id))
                                                        }
                                                    }}
                                                >
                                                    เปิดใช้งานบัญชี
                                                </button>)}

                                                {dp.status === "active" && dp.type === "deposit" && (<button
                                                    className="btn btn-danger btn-sm mr-1"
                                                    onClick={() => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        let cDisable = confirm("Are you sure disable bank this?");
                                                        if (cDisable) {
                                                            dispatch(DisableBankAgentDeposit(dp.id))
                                                        }
                                                    }}
                                                >
                                                    ยกเลิกบัญชี
                                                </button>)}

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={20} className="text-center">
                                            ไม่พบข้อมูล
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {MeInfo.role === "admin" && (
                        <div className="table-responsive">
                            <table className="table table-sm table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>Prefix</th>
                                    <th>ชื่อผู้ใช้</th>
                                    <th>ชื่อเล่น</th>
                                    <th>ระดับ</th>
                                    <th className="text-center">ตั้งค่า</th>
                                </tr>
                                </thead>
                                <tbody>
                                {agents.length > 0 ? (
                                    agents.map((agent) => (
                                        <tr key={agent.id}>
                                            <td>{agent.prefix}</td>
                                            <td>{agent.username}</td>
                                            <td>{agent.fullName}</td>
                                            <td>{agent.role}</td>
                                            <td>
                                                <button
                                                    onClick={() =>
                                                        onOpenModalApp(
                                                            {
                                                                title: "Allow mobile app accounts of agent",
                                                                size: "sm",
                                                            },
                                                            <AccountAg prefix={agent.prefix}/>
                                                        )
                                                    }
                                                    className="btn btn-warning btn-sm mr-2"
                                                >
                                                    Bank agent
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={20} className="text-center">
                                            ไม่พบข้อมูล
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withRouter(AccountAgents);
