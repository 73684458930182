import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    RegisterAppBank,
    ConfirmOTPAppBank,
    SetDeviceId,
} from "../../reducers/tools/MobileApp";

import moment from "moment";
import th from "date-fns/locale/th";

registerLocale("th", th);

const SettingBank = (props) => {
    const dispatch = useDispatch();
    const {handleClose} = useContext(ModalContext);
    const {id} = props?.detail;

    let MobileApp = useSelector((state) => {
        return state.mobileApp || null;
    });

    useEffect(() => {
        setWithdrawId(id);
    }, [id]);

    let [WithdrawId, setWithdrawId] = useState("");
    let [CardId, setCardId] = useState("");
    let [Phone, setPhone] = useState("");
    let [DateOfBirth, setDateOfBirth] = useState(new Date());

    let [otpValue, setOTPValue] = useState("");
    let [pinValue, setPIN] = useState("");

    const submitRegister = () => {
        if (CardId && Phone && MobileApp.appInfo.appId === "") {
            const model = {
                withdrawId: WithdrawId,
                cardId: CardId,
                phone: Phone,
                dateOfBirth: moment(DateOfBirth).format("YYYY-MM-DD"),
            };
            dispatch(RegisterAppBank(model));
        }
    };

    const submitOTPVerify = () => {
        if (MobileApp.appInfo.appId !== "" && MobileApp.appInfo.ref !== "") {
            const model = {
                otp: otpValue,
                pin: pinValue,
            };
            dispatch(
                ConfirmOTPAppBank(MobileApp.appInfo.appId, MobileApp.appInfo.ref, model)
            );
        }
    };

    return (
        <>
            {MobileApp.appInfo.appId === "" && (
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">เลขบัตรประชาชน</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    maxLength={15}
                                    name="CardId"
                                    onChange={(e) => setCardId(e.target.value)}
                                    value={CardId}
                                    className="form-control form-control-sm"
                                    placeholder="เลขบัตรประชาชน 14 หลัก"
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">เบอร์โทร</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="Phone"
                                    maxLength={14}
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={Phone}
                                    className="form-control form-control-sm"
                                    placeholder="เบอร์โทร"
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">วันเกิด</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    locale="th"
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control form-control-sm d-block"
                                    selected={DateOfBirth}
                                    onChange={(date) => setDateOfBirth(date)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {MobileApp.appInfo.appId !== "" && MobileApp.appInfo.ref !== "" && (
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">OTP</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    maxLength={10}
                                    name="CardId"
                                    onChange={(e) => setOTPValue(e.target.value)}
                                    value={otpValue}
                                    className="form-control form-control-sm"
                                    placeholder="กรอกเลข OTP"
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-sm-4 col-form-label">PIN</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="Phone"
                                    maxLength="6"
                                    onChange={(e) => setPIN(e.target.value)}
                                    value={pinValue}
                                    className="form-control form-control-sm"
                                    placeholder="รหัส PIN 6 หลัก"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal.Footer className="d-flex justify-content-center">

                {MobileApp.deviceInfo.deviceId === "" && MobileApp.appInfo.appId === "" && (
                    <button
                        type="button"
                        className="btn btn-primary btn-block px-4"
                        onClick={submitRegister}
                    >
                        Send OTP
                    </button>
                )}

                {MobileApp.appInfo.appId !== "" && MobileApp.appInfo.ref !== "" && (
                    <button
                        type="button"
                        className="btn btn-primary btn-block px-4"
                        onClick={submitOTPVerify}
                    >
                        Next
                    </button>
                )}

                {MobileApp.deviceInfo.deviceId !== "" && (
                    <button
                        type="button"
                        className="btn btn-success btn-block px-4"
                        onClick={() => {
                            dispatch(
                                SetDeviceId(
                                    MobileApp.appInfo.appId,
                                    {deviceId: MobileApp.deviceInfo[0].deviceId},
                                    handleClose
                                )
                            );
                        }}
                    >
                        Finish
                    </button>
                )}
            </Modal.Footer>
        </>
    );
};

export default SettingBank;
