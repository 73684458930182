import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import ModalApp from "../../components/Modal/ModalApp";
import NewOperator from "../../components/Operator/NewOperator";
import {AgentContext} from "../../context/AgentContext";
import LocalStorageService from "../../services/LocalStorageService";
import ChangPassword from "../../components/Agent/ChangePassword";
import NewRole from "../../components/Operator/NewRole";
import {RoleContext} from "../../context/RoleContext";
import Moment from "react-moment";

const localStorageService = LocalStorageService.getService()

const Operator = () => {
    const me = localStorageService.getMe();
    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: '', size: '',
    });

    const {createAgentStatus, setCreatAgentStatus, getOperator, agents, clearAgents} = useContext(AgentContext)
    const roleContext = useContext(RoleContext)

    useEffect(() => {
        document.title = "พนักงาน";
        setCreatAgentStatus()
        clearAgents()
        if (me.prefix)
            getOperator(me.prefix);
    }, []);

    function onOpenModalApp(content, component) {
        if (modalRef.current) {

            setModalComponent(component)
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            })
            modalRef.current.show()

        }
    }

    const modalRef = useRef(null)

    return (
        <>
            <ModalApp component={modalComponent} content={modalConent} ref={modalRef}/>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>พนักงาน</h4>
                    </div>
                    <div>
                        <button onClick={() => onOpenModalApp({
                                title: 'เพิ่มพนักงานใหม่',
                                size: 'sm'
                            },
                            <NewOperator/>)} className="btn btn-success btn-sm mr-2">
                            <i className="icon-plus2"></i> เพิ่มพนักงาน
                        </button>
                        {/*<button onClick={() => onOpenModalApp({*/}
                        {/*        title: 'เพิ่มสิทธิ์ใหม่',*/}
                        {/*        size: 'sm'*/}
                        {/*    },*/}
                        {/*    <NewRole/>)} className="btn btn-warning btn-sm mr-2">*/}
                        {/*    <i className="icon-plus2"></i> เพิ่มสิทธิ์ใหม่*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> หน้าแรก</Link>
                            <span className="breadcrumb-item active">ข้อมูลพนักงาน</span>
                        </div>
                        <a href="/" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                </div>
            </div>
            <div className="content">
                {/*{createAgentStatus === 200 && (*/}
                {/*    <div className="alert alert-success">บันทึกข้อมูลสำเร็จ</div>*/}
                {/*)}*/}
                {/*{createAgentStatus === 'reset_password_success' && (*/}
                {/*    <div className="alert alert-success">เปลี่ยนรหัสผ่านสำเร็จ</div>*/}
                {/*)}*/}

                {/*{roleContext.actionStatus == "role_add_success" && (*/}
                {/*    <div className="alert alert-success">เพิ่มสิทธิ์การใช้งานใหม่ สำเร็จ</div>*/}
                {/*)}*/}

                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">ชื่อผู้ใช้งาน</label>
                                <input type="text" className="form-control form-control-sm" placeholder="ค้นหา"/>
                            </div>
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={100}>Prefix</th>
                                <th className="text-center">ชื่อผู้ใช้</th>
                                <th className="text-center">ชื่อเล่น</th>
                                <th className="text-center">ระดับ</th>


                                <th className="text-center" width={130}>เปลี่ยนรหัสผ่าน</th>
                                <th className="text-center">วันที่สร้าง</th>
                                {/*<th className="text-center">Created By</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {agents.length > 0 ? agents.map(agent => (
                                <tr key={agent.id}>
                                    <td>{agent.prefix}</td>
                                    <td>{agent.username}</td>
                                    <td>{agent.fullName}</td>
                                    <td>{agent.role}</td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({
                                                title: 'เปลี่ยนรหัสผ่าน : ' + agent.username,
                                                size: 'sm'
                                            },
                                            <ChangPassword agent={agent}/>)} className="btn btn-primary btn-sm">
                                            <i className="icon-key"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <Moment format="YYYY-MM-DD HH:mm">{agent.createdTime}</Moment>
                                    </td>
                                    {/*<td>create by</td>*/}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={20} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>

                {/*<MemberList  onOpenModalApp={onOpenModalApp}/>*/}
            </div>
        </>
    )
}


export default withRouter(Operator);
