import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalContext } from "../../context/ModalContext";
import { ChangePIN } from "../../reducers/tools/MobileApp";
import { useDispatch } from "react-redux";

const ChangePINApp = (props) => {
  const dispatch = useDispatch();
  const { appId } = props;

  const { handleClose } = useContext(ModalContext);
  const [pinValue, setPIN] = useState();

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label">Enter PIN:</label>
            <div className="col-sm-9">
              <div className="input-group">
                <input
                  placeholder="Enter new PIN"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  pattern="[0-9]*"
                  type="number"
                  max="10"
                  onChange={(e) => setPIN(e.target.value)}
                  value={pinValue}
                  name="pin"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal.Footer className="row">
        <button
          disabled={!pinValue}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            let cChangePIN = confirm("Are you sure do this?");
            if (cChangePIN) {
              dispatch(ChangePIN(appId, { pin: pinValue }, handleClose));
            }
          }}
        >
          Save changes
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </>
  );
};

export default ChangePINApp;
