import React, {useContext, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {ChangePhone} from "../../reducers/tools/MobileApp";
import {useDispatch} from "react-redux";

const ChangePhoneApp = (props) => {
    const dispatch = useDispatch();
    const {appId} = props;

    const {handleClose} = useContext(ModalContext);
    const [phoneValue, setPhone] = useState();

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">Enter phone:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter new phone"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    pattern="[0-9]*"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phoneValue}
                                    name="phone"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal.Footer className="row">
                <button
                    disabled={!phoneValue}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        let cChangePhone = confirm("Are you sure do this?");
                        if (cChangePhone) {
                            dispatch(
                                ChangePhone(
                                    appId,
                                    {
                                        phone: phoneValue,
                                    },
                                    handleClose
                                )
                            );
                        }
                    }}
                >
                    Save changes
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </>
    );
};

export default ChangePhoneApp;
