import {GPROVIDER_CLEAR_FETCH, GPROVIDER_FETCH} from "../actions/type";

export default function GameProviderReducer(state, action) {
    switch (action.type) {
        case GPROVIDER_FETCH:
            return {
                ...state,
                providers: [...state.providers, action.payload]
            };
        case GPROVIDER_CLEAR_FETCH:
            return {
                ...state,
                providers: []
            };
        default:
            return state;
    }
}
