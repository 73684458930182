import http from "../http-common";

const getUser = (id) => {
    return http.get(`/user/${id}`)
}

export const getUserById = async (id) => {
    return http.get(`/user/${id}`)
}

const getUserByUsername = (username) => {
    return http.get(`/user/${username}`)
}

const fetchUsers = (data) => {
    let params = [];
    if (data) {
        for (let key in data) {
            // console.log(data)
            params.push(key + "=" + encodeURIComponent(data[key]))
        }
        // data.map((key, dt) => {
        //     params.push(key + "=" + dt)
        // })
    }
    return http.get('/user' + (params.length > 0 ? "?" + params.join("&") : ""))
}


const createUser = (data) => {
    return http.post('/user', data)
}


const resetPassword = (id, data) => {
    return http.patch('/user/' + id + '/resetpassword', data)
}

const deposit = (id, data) => {
    return http.post('/user/' + id + '/topup', data)
}

const withdraw = (id, data) => {
    return http.post('/user/' + id + '/withdraw', data)
}

const bank = (id, data) => {
    return http.post(`/user/bankaccount/${id}`, data)
}

const getHistory = (prefix, username, date, page) => {
    return http.get(`/user/gameHistory/${prefix}/${username}?date=${date}&page=${page}`)
}


export default {
    getHistory,
    fetchUsers,
    getUser,
    createUser,
    resetPassword,
    withdraw,
    deposit,
    bank,
    getUserByUsername

}
