import {createSlice} from "@reduxjs/toolkit";
import {GetMe, GetUser} from '../../api/me';
import {ApiGetUserChilds} from "../../api/user";

const AccountUserSlice = createSlice({
    name: "AccountUserSlice",
    initialState: {
        accountInfo: [],
        userInfo: {},
        childs: [],
    },
    reducers: {
        accountUser: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                accountInfo: data,
            });
        },
        userInfo: (state, action) => {
            // const {data} = action.payload;
            // console.log(action.payload.data)
            return Object.assign(state, {
                userInfo: action.payload.data,
            });
        },
        userChilds: (state, {payload}) => {
            return {...state, childs: payload}
        }
    },
});
export default AccountUserSlice;

export const userInfo = (state) => state.accountUser.userInfo

export const UserMeInfo = () => {
    return (dispatch) => {
        return GetMe()
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(AccountUserSlice.actions.accountUser({data: items.data}));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};


export const UserInfo = (username) => {
    return (dispatch) => {
        return GetUser(username)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {

                if (items.status === 200) {

                    dispatch(AccountUserSlice.actions.userInfo({data: items.data}));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const UserLoadChilds = (prefix, userId, page = 1) => {
    return (dispatch) => {
        return ApiGetUserChilds(prefix, userId, page)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(AccountUserSlice.actions.userChilds(items.data));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};

export const userChilds = (state) => state.accountUser.childs
