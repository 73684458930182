import React, {useContext, useEffect, useRef, useState} from "react";
import ModalApp from "../../components/Modal/ModalApp";
import {ModalContext} from "../../context/ModalContext";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
    AppList,
    DisableApp,
    EnableDeposit,
    DisableDeposit,
    SetDefaultWithdraw
} from "../../reducers/tools/MobileApp";

import ChangePINApp from "./ChangePIN";
import ChangePhoneApp from "./ChangePhone";
import SettingDeposit from "./SettingDeposit";
import SettingWithdraw from "./SettingWithdraw";

const AppListDevice = (props) => {
    const dispatch = useDispatch();
    const {handleClose} = useContext(ModalContext);
    const modalRef = useRef(null);
    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });

    const {id, number} = props?.detail;

    const AppListData = useSelector((state) => {
        return state.mobileApp.appList || [];
    });

    useEffect(() => {
        dispatch(AppList(number));
    }, [number, dispatch]);

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }

    return (
        <>
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th>เลขบัญชี</th>
                                <th>ธนาคาร</th>
                                <th>ชื่อบัญชีไทย</th>
                                <th>ชื่อบัญชีอังกฤษ</th>
                                <th>สถานะ</th>
                                <th className="text-center">ตั้งค่า</th>
                            </tr>
                            </thead>
                            <tbody>
                            {AppListData.length > 0 ? (
                                AppListData.map((list) => (
                                    <tr key={list.appId}>
                                        <td>{list.number}</td>
                                        <td>{list.bank}</td>
                                        <td>{list.nameTH}</td>
                                        <td>{list.nameEN}</td>
                                        <td>{list.status}</td>
                                        <td>

                                            <button
                                                className="btn btn-sm btn-success m-1"
                                                onClick={(e) => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    let cDeposit = confirm(
                                                        "Are you sure enable deposit?"
                                                    );
                                                    if (cDeposit) {
                                                        dispatch(EnableDeposit(list.appId, number, id));
                                                    }
                                                }}
                                            >
                                                Enable Deposit
                                            </button>

                                            <button
                                                className="btn btn-sm btn-danger m-1"
                                                onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    let cDisableDeposit = confirm(
                                                        "Are you sure disable deposit?"
                                                    );
                                                    if (cDisableDeposit) {
                                                        dispatch(DisableDeposit(list.appId, number, id));
                                                    }
                                                }}
                                            >
                                                Disable Deposit
                                            </button>

                                            <button
                                                className="btn btn-sm btn-success m-1"
                                                onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    let cDefaultWithdraw = confirm(
                                                        "Are you sure set default withdraw?"
                                                    );
                                                    if (cDefaultWithdraw) {
                                                        dispatch(SetDefaultWithdraw(list.appId, number, id));
                                                    }
                                                }}
                                            >
                                                Set Default withdraw
                                            </button>

                                            <button
                                                className="btn btn-sm btn-primary m-1"
                                                onClick={() =>
                                                    onOpenModalApp(
                                                        {
                                                            title: "เปลี่ยน PIN",
                                                            size: "sm",
                                                        },
                                                        <ChangePINApp appId={list.appId}/>
                                                    )
                                                }
                                            >
                                                ChangePIN
                                            </button>

                                            <button
                                                className="btn btn-sm btn-primary m-1"
                                                onClick={() =>
                                                    onOpenModalApp(
                                                        {
                                                            title: "เปลี่ยนเบอร์โทร",
                                                            size: "sm",
                                                        },
                                                        <ChangePhoneApp appId={list.appId}/>
                                                    )
                                                }
                                            >
                                                ChangePhone
                                            </button>



                                            <button
                                                className="btn btn-sm btn-danger m-1"
                                                onClick={() => {
                                                    // eslint-disable-next-line no-restricted-globals
                                                    let cDisableApp = confirm(
                                                        "Are you sure disable app?"
                                                    );
                                                    if (cDisableApp) {
                                                        dispatch(DisableApp(list.appId, number));
                                                    }
                                                }}
                                            >
                                                Disable App
                                            </button>
                                            {list.status == "active" && (
                                                <>
                                                    <hr/>

                                                    <button
                                                        className="btn btn-sm btn-warning m-1"
                                                        onClick={() =>
                                                            onOpenModalApp(
                                                                {
                                                                    title: "ตั้งค่าฝาก",
                                                                    size: "sm",
                                                                },
                                                                <SettingDeposit appId={list.appId}/>
                                                            )
                                                        }
                                                    >
                                                        Deposit Setting
                                                    </button>
                                                    <button
                                                        className="btn btn-sm btn-warning m-1"
                                                        onClick={() =>
                                                            onOpenModalApp(
                                                                {
                                                                    title: "ตั้งค่าถอน",
                                                                    size: "sm",
                                                                },
                                                                <SettingWithdraw appId={list.appId}/>
                                                            )
                                                        }
                                                    >
                                                        Withdraw Setting
                                                    </button>
                                                </>
                                            )}


                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr key={0}>
                                    <td colSpan={7} className="text-center">
                                        ไม่พบข้อมูล
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppListDevice;
