import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {UserContext} from "../../context/UserContext";

export default function ChangPassword({user}) {

    const [data, setData] = useState({
        newPassword: "",
        confirmPassword: "",
        submitted: false,
        match: true,
    });

    const {resetActionStatus, actionStatus, resetPassword} = useContext(UserContext)
    const {handleClose} = useContext(ModalContext)

    useEffect(() => {
        resetActionStatus()
    }, []);

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    };

    const submit = (e) => {
        e.preventDefault();
        console.log(data.confirmPassword + " " + data.newPassword)
        if (data.newPassword != data.confirmPassword || data.newPassword == "") {
            setData({
                ...data,
                submited: false,
                match: false,
            })
            return;
        }

        resetActionStatus()
        setData({
            ...data,
            submited: true,
            match: true,
        })

        resetPassword(user.id, {newPassword: data.newPassword})

    }

    return (
        <>
            {!data.match && (
                <div className="alert alert-danger">รหัสผ่านไม่ตรงกัน</div>
            )}

            {actionStatus === "reset_password_error" && (
                <div className="alert alert-danger">เปลี่ยนรหัสผ่านไม่สำเร็จ ลองใหม่อีกครั้ง</div>
            )}
            <form onSubmit={e => submit(e)}>
                <div className="form-group row">
                    <label className="col-md-4">รหัสผ่านใหม่</label>
                    <div className="col-md-8">
                        <input type="text" onChange={handleInputChange} name="newPassword" autoComplete="new-password" className="form-control"/>
                        <small>เฉพาะตัวอักษร A-Z a-z หรือ 0-9 และมีจำนวน 6 ตัวขึ้นไป</small>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-4">ยืนยันรหัสผ่านใหม่:</label>
                    <div className="col-md-8">
                        <input type="text" onChange={handleInputChange} name="confirmPassword" autoComplete="new-password" className="form-control"/>
                    </div>
                </div>
                <Modal.Footer>
                    <button type="submit" disabled={data.submited && actionStatus == 0}  className="btn btn-primary">บันทึก</button>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>ปิดหน้าต่าง</button>
                </Modal.Footer>
            </form>
        </>
    )
};
