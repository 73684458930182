import http, {API_TOKEN} from "../http-common";

const getAll = () => {
    return http.get("/operator/games", API_TOKEN);
};


export default {
    getAll,
};
