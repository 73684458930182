import React, {useContext, useEffect, useRef, useState} from "react";
import ModalApp from "../../components/Modal/ModalApp";
import {ModalContext} from "../../context/ModalContext";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
    AppList,
    DisableApp,
    EnableDeposit,
    DisableDeposit,
    SetDefaultWithdraw
} from "../../reducers/tools/MobileApp";

import ChangePINApp from "./ChangePIN";
import ChangePhoneApp from "./ChangePhone";
import SettingDeposit from "./SettingDeposit";
import SettingWithdraw from "./SettingWithdraw";
import {GetBankBalance} from "../../reducers/tools/AgentBankaccount";
import CurrencyFormat from "react-currency-format";

const AccountBalance = (props) => {
    const dispatch = useDispatch();
    const {handleClose} = useContext(ModalContext);
    const modalRef = useRef(null);
    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });

    const {id, number} = props?.detail;

    const AppListData = useSelector((state) => {
        return state.mobileApp.appList || [];
    });

    useEffect(() => {
        dispatch(AppList(number));
    }, [number, dispatch]);

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }

    const checkBalance = (appId) => {
        dispatch(GetBankBalance(appId))
    }

    return (
        <>
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={150}>เลขบัญชี</th>
                                <th className="text-center" width={100}>ธนาคาร</th>
                                <th className="text-center" width={200}>ชื่อบัญชีไทย</th>
                                <th className="text-center" width={200}>ชื่อบัญชีอังกฤษ</th>
                                <th className="text-center" width={100}>สถานะ</th>
                                <th className="text-center" width={150}>ตรวจสอบ</th>
                                <th className="text-center">คงเหลือ</th>
                            </tr>
                            </thead>
                            <tbody>
                            {AppListData.length > 0 ? (
                                AppListData.map((list) => (
                                    <tr key={list.appId}>
                                        <td className="text-center">{list.number}</td>
                                        <td className="text-center">{list.bank}</td>
                                        <td className="text-center">{list.nameTH}</td>
                                        <td className="text-center">{list.nameEN}</td>
                                        <td className="text-center">{list.status}</td>
                                        <td className="text-center">

                                            <button onClick={() => checkBalance(list.appId)} className="btn btn-primary">เช็คยอดคงเหลือ</button>

                                        </td>
                                        <td className="text-center">
                                            <h3 className="text-success">
                                                <CurrencyFormat value={list?.balance} displayType={'text'} thousandSeparator={true}/>
                                            </h3>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr key={0}>
                                    <td colSpan={7} className="text-center">
                                        ไม่พบข้อมูล
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountBalance;
