import axios from "axios";
import LocalStorageService from "./services/LocalStorageService";
import {domain} from "./services/config";

const localStorageService = LocalStorageService.getService();

export const API_TOKEN = {
    accessToken: localStorageService.getAccessToken(),
    refreshToken: localStorageService.getRefreshToken(),
};

const axioClient = axios.create({
    baseURL: domain,
});

axioClient.interceptors.request.use(
    (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axioClient.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            originalRequest.url === "/operator/refresh"
        ) {
            window.location.href = "/login";
            return Promise.reject(error);
        }

        const ACToken = LocalStorageService.getAccessToken();
        const RFToken = LocalStorageService.getRefreshToken();

        if (!ACToken || !RFToken) {
            // window.location.href = "/login";
            // return Promise.reject({error: "Not have token refresh"});
        }

        const model = {
            accessToken: ACToken,
            refreshToken: RFToken,
        };

        // console.log(originalRequest.url)
        // alert(originalRequest.url)

        if (error.response.status === 401 && !originalRequest._retry && (originalRequest.url !== `/operator/signin` && !(originalRequest.url).includes(`/operator/me/password`))) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject});
                })
                    .then((token) => {
                        originalRequest.headers[
                            "Authorization"
                            ] = `Bearer ${model.accessToken}`;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                axios
                    .post(`${domain}/operator/refresh`, model, {
                        headers: {Authorization: `Bearer ${model.accessToken}`},
                    })
                    .then(({data}) => {
                        LocalStorageService.setToken(data);
                        //alert('refresh')
                        window.location.reload()
                        axioClient.defaults.headers.common[
                            "Authorization"
                            ] = `Bearer ${data.accessToken}`;
                        originalRequest.headers[
                            "Authorization"
                            ] = `Bearer ${data.accessToken}`;
                        resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                        console.log("refresh err:", err);

                        processQueue(err, null);

                        LocalStorageService.clearToken();
                        window.location.href = "/login";
                        reject({
                            status: error.response.status,
                            error: error,
                        });
                    })
                    .finally((err) => {
                        isRefreshing = false;
                    });
            });
        } else {
            console.log(error.response)
            // if (error.response.status != 400 && error.response.status != 404)
            //     window.location.href = "/login";
        }

        return Promise.reject(error);
    }
);

// axioClient.interceptors.response.use((response) => {
//     return response;
// }, function (error) {
//     const originalRequest = error.config;

//     if ((error.response.status === 401 || error.response.status === 400) && originalRequest.url === "/operator/refresh") {
//         console.log("Error");
//         // localStorageService.clearToken();
//         // window.location.href = "/login";
//         return Promise.reject(error);
//     }

//     if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         // const refreshToken = localStorageService.getRefreshToken();
//         const data = {
//             "refreshToken": localStorageService.getRefreshToken(),
//             "accessToken": localStorageService.getAccessToken(),
//         };
//         return axioClient.post(`${domain}/operator/refresh`, data).then(response => {
//             console.log(response.data);
//             if (response.status === 200) {
//                 localStorageService.setToken(response.data);
//                 axioClient.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
//                 return axioClient(originalRequest);
//             }
//         })
//     }

//     return Promise.reject({
//         status: error.response.status,
//         error: error
//     });
// });

export default axioClient;

export function get(url) {
    return axioClient.get(`${domain}/${url}`, {
        headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        },
    });
}

export function post(url, model) {
    return axioClient.post(`${domain}/${url}`, model, {
        headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        },
    });
}

export function deleteHttp(url) {
    return axioClient.delete(`${domain}/${url}`, {
        headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        },
    });
}

export function put(url, model) {
    return axioClient.put(`${domain}/${url}`, model, {
        headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        },
    });
}

export function patch(url, model) {
    return axioClient.patch(`${domain}/${url}`, model, {
        headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
        },
    });
}
