import React, {useContext, useEffect, useRef, useState} from "react";
import ModalApp from "../../components/Modal/ModalApp";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Topup from "../../components/User/Topup";
import ChangPassword from "../../components/User/ChangePassword";
import NewUser from "../../components/User/NewUser";
import {UserContext} from "../../context/UserContext";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import BankAccount from "../../components/User/BankAccount";
import {ClearPromotion} from "../../api/transaction";
import {NotificationManager} from "react-light-notifications";
import {setOpenModalContent} from "../../reducers/tools/Utility";
import UserProfile from "../../components/User/UserProfile";
import {useDispatch} from "react-redux";
import GameHistory from "../Report/GameHistory";
import Transaction from "../Transaction/Transaction";
import {rowRuning} from "../../services/functions";


const User = () => {

    const dispatch = useDispatch()
    const {actionStatus, resetActionStatus, users, fetchUsers, userState, getUser} = useContext(UserContext)
    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: '', size: '',
    });
    const [activePage, setActivePage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [search, setSearch] = useState("")

    useEffect(() => {
        document.title = "Users"
        resetActionStatus()
        fetchUsers({
            pageSize: pageSize
        })
    }, [])

    // useEffect(() => {
    //     console.log("updated")
    // }, [users])

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component)
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            })
            modalRef.current.show()
        }
    }

    const handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        fetchUsers({
            page: pageNumber,
            pageSize: pageSize
        })
    }

    const handleSearch = () => {
        fetchUsers({
            page: 1,
            pageSize: pageSize,
            name: search
        })
    }

    const handleBalance = (e, id) => {
        e.preventDefault();
        getUser(id)
    }

    const modalRef = useRef(null)


    const handleClearPromotion = async (prefix, userId) => {
        if (window.confirm("กรุณาเคียร์ยอดเครดิตให้เป็น 0 ก่อนยกเลิกโปรโมชั่น...Confirm??")) {
            // console.log(prefix)
            // console.log(userId)
            try {
                const response = await ClearPromotion(prefix, userId)
                NotificationManager.success({
                    title: "Success",
                    message: "ยกเลิกโปรโมชั่นสำเร็จ",
                });
                handleSearch()
            } catch (e) {
                NotificationManager.error({
                    title: "Error",
                    message: "ผิดพลาด",
                });
            }
        }


    }

    return (
        <>
            <ModalApp component={modalComponent} content={modalConent} ref={modalRef}/>

            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>สมาชิก</h4>
                    </div>
                    <div>
                        <button onClick={() => onOpenModalApp({
                                title: 'เพิ่มผู้ใช้งานใหม่',
                                size: 'sm'
                            },
                            <NewUser/>)} className="btn btn-success btn-sm mr-2">
                            <i className="icon-plus2"></i> เพิ่มสมาชิก
                        </button>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> หน้าแรก</Link>
                            <span className="breadcrumb-item active">สมาชิก - ข้อมูลสมาชิก</span>
                        </div>
                        <a href="/" className="header-elements-toggle text-default d-md-none"><i className="icon-more"></i></a>
                    </div>
                </div>
            </div>

            <div className="content">
                {actionStatus === "create_success" && (
                    <div className="alert alert-success">บันทึกข้อมูลสำเร็จ</div>
                )}
                {actionStatus === 'reset_password_success' && (
                    <div className="alert alert-success">เปลี่ยนรหัสผ่านสำเร็จ</div>
                )}
                {actionStatus === 'topup_success' && (
                    <div className="alert alert-success">เติมเงินสำเร็จ</div>
                )}

                {actionStatus === 'withdraw_success' && (
                    <div className="alert alert-success">ถอนเงินสำเร็จ</div>
                )}
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">ชื่อผู้ใช้งาน</label>
                                <input type="text" onChange={e => setSearch(e.target.value)} className="form-control form-control-sm" placeholder="ค้นหา"/>
                            </div>
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={e => setPageSize(e.target.value)}>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <button type="button" className="btn btn-primary btn-sm" onClick={handleSearch}>ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th className="text-center" width={180}>ชื่อผู้ใช้</th>
                                <th className="text-center" width={180}>ชื่อ</th>
                                <th className="text-center" width={180}>Upline</th>

                                <th className="text-center" width={150}>คงเหลือ</th>
                                <th className="text-center" width={70}>เติม</th>
                                <th className="text-center" width={70}>ถอน</th>
                                <th className="text-center" width={110}>ประวัติเติม-ถอน</th>
                                <th className="text-center" width={100}>ประวัติเดิมพัน</th>
                                <th className="text-center" width={130}>เปลี่ยนรหัสผ่าน</th>
                                <th className="text-center" width={130}>บัญชี</th>
                                <th className="text-center" width={150}>วันที่สร้าง</th>
                                <th>รายละเอียด</th>
                                {/*<th>Created By</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {users.length > 0 ? users.map((user, i) => (
                                <tr key={user.id}>
                                    <td className="text-center">{rowRuning(i, activePage, userState?.pageSize)}</td>
                                    <td>
                                        {/*<div className="h5 mb-0">{user.username}</div>*/}
                                        <a className="pointer text-info font-weight-bold" onClick={() => dispatch(setOpenModalContent({
                                            title: `รายละเอียด ${user.username}`,
                                            size: `lg`,
                                            content: <UserProfile username={user.username}/>,
                                        }))}><u>{user.username}</u></a>
                                    </td>
                                    <td>

                                        {user?.bankAccount && (
                                            <div>{user?.bankAccount?.fullName}</div>
                                        )}
                                        {user.name}
                                        {/*<div>โทร. {user.phone}</div>*/}
                                    </td>
                                    <td className="text-center">
                                        {user?.parents[0] ?? `-`}
                                    </td>

                                    <td className="text-center">
                                        <CurrencyFormat value={user.balance} displayType={'text'} thousandSeparator={true}/>
                                        <div>
                                            <a href="" onClick={e => handleBalance(e, user.id)}><u>ตรวจสอบเครดิต</u></a>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'เติมเงิน', size: 'sm'},
                                            <Topup type="deposit" user={user}/>)} className="btn btn-success btn-sm">
                                            <i className="icon-plus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'ถอนเงิน', size: 'sm'},
                                            <Topup type="revoke" user={user}/>)} className="btn btn-danger btn-sm">
                                            <i className="icon-minus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        {/*<a href={`/transactions?username=${user.username}`} className="btn btn-info btn-sm"><i className="icon-history"></i></a>*/}
                                        <a className="btn btn-info btn-sm" onClick={() => dispatch(setOpenModalContent({
                                            title: `ประวัติวางเดิมพัน ${user.username}`,
                                            size: `xl`,
                                            content: <Transaction account={user?.username}/>,
                                        }))}><i className="icon-history"></i></a>
                                    </td>
                                    <td className="text-center">
                                        {/*<a href={`/report/gamehistory?username=${user.username}`} className="btn btn-info btn-sm"><i className="icon-history"></i></a>*/}
                                        <a className="btn btn-info btn-sm" onClick={() => dispatch(setOpenModalContent({
                                            title: `ประวัติวางเดิมพัน ${user.username}`,
                                            size: `xl`,
                                            content: <GameHistory account={user?.username}/>,
                                        }))}><i className="icon-history"></i></a>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({
                                                title: 'เปลี่ยนรหัสผ่าน : ' + user.username,
                                                size: 'sm'
                                            },
                                            <ChangPassword user={user}/>)} className="btn btn-primary btn-sm">
                                            <i className="icon-key"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({
                                                title: 'บัญชีธนาคาร : ' + user.username,
                                                size: 'lg'
                                            },
                                            <BankAccount handlePageChange={handlePageChange} user={user}/>)} className="btn btn-warning btn-sm">
                                            <i className="icon-file-check"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <Moment format="YYYY-MM-DD HH:mm">
                                            {user.createdTime}
                                        </Moment>
                                    </td>
                                    <td>
                                        {user?.usingPromo && (
                                            <>
                                                {user?.usingPromo?.name}
                                                <div>
                                                    <button onClick={() => handleClearPromotion(user?.usingPromo?.prefix, user.id)} className="btn btn-danger btn-sm">ยกเลิกโปรโมชั่น</button>
                                                </div>
                                            </>
                                        )}
                                    </td>
                                    {/*<td>create by</td>*/}
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={20} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>

                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={userState.pageSize}
                    totalItemsCount={userState.count ? userState.count : 0}
                    pageRangeDisplayed={5}
                    onChange={e => handlePageChange(e)}
                />

                {/*<MemberList  onOpenModalApp={onOpenModalApp}/>*/}
            </div>
        </>
    )
}


export default User;
