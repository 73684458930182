import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {userChilds, userInfo, UserInfo} from "../../reducers/tools/AccountUsers";
import CurrencyFormat from "react-currency-format";
import {setModal} from "../../reducers/tools/Utility";
import {Modal} from "react-bootstrap";
import UserChilds from "./UserChilds";
import UserTurnover from "./UserTurnover";
import LocalStorageService from "../../services/LocalStorageService";

const UserProfile = ({username}) => {

    const dispatch = useDispatch()

    const user = useSelector(userInfo)
    const [name, setName] = useState(username)
    const childs = useSelector(userChilds)
    const [bankName, setBankName] = useState(``)



    let BankList = useSelector((state) => {
        return state.agentBank?.bankList || [];
    });

    useEffect(() => {
        if (typeof name !== `undefined`) {
            // alert(user)
            dispatch(UserInfo(name))
        }
    }, [dispatch])
    useEffect(() => {
        if (user) {
            let bank = BankList.find(v => v.code === user?.bankAccount?.bank)
            setBankName(bank)
        }


    }, [user])

    return (
        <>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">ไอดี :</div>
                <div className="col-md-8">
                    {user?.username ?? '-'}
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">ชื่อบัญชี :</div>
                <div className="col-md-8">
                    {user?.bankAccount?.fullName ?? '-'}
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">เลขบัญชี :</div>
                <div className="col-md-8">
                    {user?.bankAccount?.accountNumber ?? '-'}
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">ธนาคาร :</div>
                <div className="col-md-8">
                    {bankName?.name ?? '-'}
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">เครดิต :</div>
                <div className="col-md-8">
                    <CurrencyFormat value={user?.balance ?? 0} displayType={'text'} thousandSeparator={true}/>
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">รับโปรโมชั่น :</div>
                <div className="col-md-8">
                    {typeof user?.promotion !== `undefined` ? (
                        <>
                            {user?.promotion ? `รับโปร` : `ไม่รับ`}
                        </>
                    ) : (
                        <>-</>
                    )}
                </div>
            </div>


            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">โปรโมชั่น :</div>
                <div className="col-md-8">
                    {user?.usingPromo?.name ?? '-'}
                </div>
            </div>
            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">Turn Over :</div>
                <div className="col-md-8">
                    {user?.turnover ?? '-'}
                </div>
            </div>

            <div className="form-group mb-2 row align-items-center">
                <div className="col-md-4 font-weight-bold">จำนวนสมาชิก :</div>
                <div className="col-md-8">
                    {childs?.count ?? 0}
                </div>
            </div>

            <div className="mt-4">
                <UserChilds userId={user.id}/>
            </div>

            <div className="mt-4">
                <UserTurnover userId={user.id}/>
            </div>

            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => dispatch(setModal(false))}>
                    ปิดหน้าต่าง
                </button>
            </Modal.Footer>


        </>
    )
}

export default UserProfile
