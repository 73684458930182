import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {createStore} from "redux";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Switch, Router} from "react-router-dom";
import ContextProvider from "./context/ContextProvider";
import Dashboard from "./containers/Dashboard";
import history from "./history";

import {
    NotificationContainer,
    NotificationManager
} from "react-light-notifications";
import "react-light-notifications/lib/main.css";

import store from "./reducers";

//Page
import Login from "./pages/Authentication/Login";
import Logout from "./pages/Authentication/Logout";

// Bootstrap
import "jquery/dist/jquery.min";

import "bootstrap/dist/js/bootstrap.js";

//Themes
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap-limitless.min.css";
import "./assets/css/icons/icomoon/styles.min.css";
import "./assets/css/components.min.css";
import "./assets/css/layout.min.css";
import "./assets/css/colors.min.css";
import "./assets/css/custom.css";
import PageNotFound from "./pages/PageNotFound";

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ContextProvider>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/404" component={PageNotFound}/>
                        <Route exact path="/logout" component={Logout}/>
                        <Route path="/" component={Dashboard}/>
                    </Switch>
                </BrowserRouter>
                <NotificationContainer/>
            </ContextProvider>
        </Router>
    </Provider>,
    document.getElementById("root")
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Router history={history}>
//       <ContextProvider>
//         <BrowserRouter basename={process.env.PUBLIC_URL}>
//           <Switch>
//             <Route exact path="/login" component={Login} />
//             <Route exact path="/logout" component={Logout} />
//             <Route path="/" component={Dashboard} />
//           </Switch>
//         </BrowserRouter>
//       </ContextProvider>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
