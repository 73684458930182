import {createContext, useReducer} from "react";
import GameProviderReducer from "../reducers/GameProviderReducer";
import GameProviderService from "../services/GameProviderService";
import {GPROVIDER_CLEAR_FETCH, GPROVIDER_FETCH} from "../actions/type";


const initialState = {
    providers: [],
}


export const GameProviderContext = createContext(initialState);

export const GPRProvider = ({children}) => {
    const [state, dispatch] = useReducer(GameProviderReducer, initialState);

    function getProviders() {
        dispatch({
            type: GPROVIDER_CLEAR_FETCH,
        })
        const response = GameProviderService.gets()

        response.map(v => {
            // GameProviderService.getGames(v).then(res => {
            //     dispatch({
            //         type: GPROVIDER_FETCH,
            //         payload: res.data
            //     })
            //     // console.log(typeof providers)
            //     // console.log(providers)
            // }).catch(() => {
            //
            // })
        })


    }

    return (
        <GameProviderContext.Provider
            value={{
                providers: state.providers,
                getProviders
            }}
        >
            {children}
        </GameProviderContext.Provider>
    )

};
