import {Link, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllTransaction} from "../../reducers/tools/Transaction";
import CurrencyFormat from "react-currency-format";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputLastMonth} from "../../services/config";
import Moment from "react-moment";
import moment from "moment";
import Pagination from "react-js-pagination";
import {setOpenModalContent} from "../../reducers/tools/Utility";
import UserProfile from "../../components/User/UserProfile";
import {logoBank, rowRuning, startDateTimeFormat} from "../../services/functions";

const Transaction = ({account = ``}) => {
    const dispatch = useDispatch()
    const [username, setUsername] = useState()
    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 15,
        from: startDateTimeFormat(60),
        to: moment().format()
    })

    const transactions = useSelector((state) => state.trans.transactionList);
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const username = params.get('username');
        let f = {...filter}
        if (typeof username !== 'undefined' && username !== `` && username !== null) {
            f = {...filter, username: username}
            setUsername(username)
            setFilter(f)
        }
        // const search = useLocation();
        if (account === ``)
            dispatch(fetchAllTransaction(f))
    }, [dispatch])


    useEffect(() => {
        if (typeof account !== `undefined` && account !== ``) {
            let f = {...filter, username: account}
            setUsername(account)
            setFilter(f)
            dispatch(fetchAllTransaction(f))
        }
    }, [account])

    const handlePageSizeChange = (pageSize) => {
        let f = {...filter, pageSize: pageSize}
        setFilter(f)
        dispatch(fetchAllTransaction(f));

    }

    function handleCallBackDateRang(start, end) {
        setFilter({
            ...filter,
            from: moment(start).format(),
            to: moment(end).format()
        })
    }

    const handlePageChange = (pageNumber) => {
        let f = {...filter, page: pageNumber}
        setFilter(f)
        dispatch(fetchAllTransaction(f));
    }

    const onSubmit = () => {
        let f = {...filter}
        if (typeof username !== `undefined` && username !== '') {
            f = {...filter, username: username}
        }
        dispatch(fetchAllTransaction(f));
    }

    return (
        <>
            {account === `` && (
                <>
                    <div className="page-header page-header-light">
                        <div className="page-header-content header-elements-md-inline align-items-center">
                            <div className="page-title d-flex">
                                <h4>รายการเงินเข้า - ออก</h4>
                            </div>
                        </div>
                        <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                            <div className="d-flex">
                                <div className="breadcrumb">
                                    <Link to="/" className="breadcrumb-item">
                                        <i className="icon-home2 mr-2"></i> หน้าแรก
                                    </Link>
                                    <span className="breadcrumb-item active">รายการเงินเข้า - ออก</span>
                                </div>
                                <a
                                    href="/#"
                                    className="header-elements-toggle text-default d-md-none"
                                >
                                    <i className="icon-more"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={`content ${account !== `` && `p-0`}`}>


                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <DateRangePicker
                                    initialSettings={initConfigInputLastMonth}
                                    onCallback={handleCallBackDateRang}
                                >
                                    <input type="text" style={{width: "164px"}} className="form-control form-control-sm"/>
                                </DateRangePicker>
                            </div>
                            <div className="form-group mr-2">
                                <input className="form-control form-control-sm" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="ค้นหาจาก Username"/>
                            </div>
                            <div className="form-group mr-2">
                                <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th width={120}>ผู้ใช้งาน</th>
                                <th width={60}>ประเภท</th>
                                <th width={150} className="text-center">จากบัญชี</th>
                                <th width={150} className="text-center">เข้าบัญชี</th>
                                <th className="text-center" width={55}>ก่อน</th>
                                <th className="text-center" width={55}>จำนวน</th>
                                <th className="text-center" width={55}>โบนัส</th>
                                <th className="text-center" width={55}>หลัง</th>
                                <th className="text-center" width={130}>วันเวลา</th>
                                <th className="text-center" width={130}>อัพเดต</th>
                                <th className="text-center">สถานะ</th>
                                <th style={{minWidth: `219px`}}>หมายเหตุ</th>

                            </tr>
                            </thead>
                            <tbody>
                            {transactions.data.length > 0 ? transactions.data.map((item, i) => (
                                <tr key={item.id}>
                                    <td className="text-center">{rowRuning(i, filter?.page, filter?.pageSize)}</td>
                                    <td>
                                        <a className="pointer text-info font-weight-bold" onClick={() => dispatch(setOpenModalContent({
                                            title: `รายละเอียด ${item.userId}`,
                                            size: `sm`,
                                            content: <UserProfile username={item.userId}/>,
                                        }))}><u>{item.userId}</u></a>
                                    </td>
                                    <td className="text-center">
                                        {item?.txnType == 'withdraw' ? (
                                            <span className="bade badge-danger badge-pill">ถอน</span>
                                        ) : (
                                            <span className="bade badge-success badge-pill">ฝาก</span>
                                        )}
                                    </td>
                                    <td>
                                        {/*{item.fromAccId.fullName}*/}
                                        <div className="text-center">
                                            <img src={logoBank(item.fromAccId?.bank)} onError={`images/bank/no.png`} width={20}/>
                                            <div className="ml-1"> {item.fromAccId?.fullName}</div>
                                        </div>
                                    </td>
                                    <td>
                                        {/*{item.toAccId.fullName}*/}
                                        <div className="text-center">
                                            <img src={logoBank(item.toAccId?.bank)} onError={`images/bank/no.png`} width={20}/>
                                            <div className="ml-1"> {item.toAccId?.fullName}</div>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.creditBefore} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>
                                    </td>

                                    <td className="text-center">
                                        <CurrencyFormat value={item.bonusAmount} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center">
                                        <CurrencyFormat value={item.creditAfter} displayType={'text'} thousandSeparator={true}/>
                                    </td>
                                    <td className="text-center"><Moment format="YYYY-MM-DD HH:mm" date={item.txnTime}/>
                                    </td>
                                    <td className="text-center">
                                        <Moment format="YYYY-MM-DD HH:mm" date={item.latestUpdate}/></td>
                                    <td className="text-center">
                                        {item.status === 'failed' &&
                                            <span className="trans-status badge badge-pill badge-danger">Failed</span>}
                                        {item.status === 'success' &&
                                            <span className="trans-status badge badge-pill badge-success">Success</span>}

                                        {item.status === 'pending' &&
                                            <span className="trans-status badge badge-pill badge-warning">Pending</span>}

                                        {item.status === 'canceled' &&
                                            <span className="trans-status badge badge-pill badge-secondary">Cancel</span>}

                                        {(item.status !== 'pending' || item.status !== 'success') ??
                                            <span className="trans-status badge badge-pill badge-danger">{item.status}</span>}
                                    </td>
                                    <td>{item.note}</td>

                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={25} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    activePage={filter.page}
                    itemsCountPerPage={transactions.pageSize}
                    totalItemsCount={transactions.total > 0 ? transactions.total : 0}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                />
            </div>
        </>
    )
}

export default withRouter(Transaction)
