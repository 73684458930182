export default function GlobalReducer(state, action) {
    //console.log(action.type)
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                loading: 1
            }
        case 'FINISH':
            return {
                ...state,
                loading: 2
            }
        case 'WAIT':
            return {
                ...state,
                loading: 0
            }
        case 'ERROR':
            return {
                ...state,
                loading: 3
            }
        case 'SAVED':
            return {
                ...state,
                loading: 4
            }
    }
}
