import http from "../http-common";


function me() {
    return http.get("/operator/me");
}

function login(username, password) {
    return http.post("/operator/signin", {
        username: username,
        password: password
    });
}

export default {
    me,
    login,
};
