import {
  AGENT_ADD,
  AGENT_ADD_STATUS,
  AGENT_CHECK_PREFIX,
  AGENT_CLEAR,
  AGENT_EDIT,
  AGENT_FETCH,
  AGENT_UPDATE_KEY,
} from "../actions/type";

import { initialState } from "../context/AgentContext";

export default function AgentReducer(state = initialState, action) {
  switch (action.type) {
    case AGENT_FETCH:
      return {
        ...state,
        agents: action.payload,
      };
    case AGENT_ADD:
      const ag = {
        ...state,
        agents: [...state.agents, action.payload],
      };
      // console.log("=============================");
      // console.log(ag);
      return ag;
    case AGENT_ADD_STATUS:
      // console.log(action.payload);
      return {
        ...state,
        add: action.payload,
      };
    case AGENT_EDIT:
      const updatedAgent = action.payload;

      const updatedAgents = state.agents.map((agent) => {
        if (agent.id === updatedAgent.id) {
          return updatedAgent;
        }
        return agent;
      });

      return {
        ...state,
        agents: updatedAgents,
      };
    case AGENT_UPDATE_KEY:
      const updatedKeyAgent = action.payload;
      const updatedKeyAgents = state.agents.map((agent) => {
        if (agent.id === updatedKeyAgent.id) {
          agent[updatedKeyAgent.key] = updatedKeyAgent.value;
          return agent;
        }
        return agent;
      });

      return {
        ...state,
        agents: updatedKeyAgents,
      };
    case AGENT_CHECK_PREFIX:
      return {
        ...state,
        prefix: action.payload,
      };
    case AGENT_CLEAR:
      return {
        ...state,
        agents: [],
      };
    default:
      return state;
  }
}
