import {LOGIN, LOGIN_ME, LOGOUT} from "../actions/type";
import LocalStorageService from "../services/LocalStorageService";

import {initialState} from "../context/LoginContext";

const localStorageService = LocalStorageService.getService();

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            // localStorage.setItem("accessToken", action.payload.accessToken);
            // localStorage.setItem("refreshToken", action.payload.refreshToken);
            // // console.log('dispatch: ' + LOGIN);
            localStorageService.setToken(action.payload);
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refeshToken: action.payload.refreshToken,
            };
        case LOGIN_ME:
            localStorageService.setMe(action.payload);
            return {
                ...state,
                isAuthenticated: true,
                role: action.payload.role,
            }
            break;
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                refeshToken: null,
            };
        default:
            return state;
    }
}
