import React, {createContext, useReducer} from "react";
import LoginReducer from "../reducers/LoginReducer";

import {LOGIN, LOGIN_ME, LOGOUT} from "../actions/type";
import LoginService from "../services/LoginService";
import AccountUserSlice from "../reducers/tools/AccountUsers";
import {useDispatch} from "react-redux";
import {NotificationManager} from "react-light-notifications";

let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "";
let refeshToken = localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : "";

export const initialState = {
    isAuthenticated: (accessToken && refeshToken) || false,
    accessToken: null || accessToken,
    refeshToken: null || refeshToken,
    role: "",
};

// console.log(initialState);

export const LoginContext = createContext(initialState);
export const LoginProvider = ({children}) => {
    const [state, dispatch] = useReducer(LoginReducer, initialState);

    function auth(data, setData) {

        LoginService.login(data.username, data.password).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: LOGIN,
                    payload: {
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken,
                    },
                });
            }
            me();
        }).catch(e => {

            NotificationManager.error({
                title: "ผิดพลาด",
                message: "ไม่สามารถเข้าสู่ระบบ",
            });
            // setData({
            //     ...data,
            //     isSubmitting: false,
            //     errorMessage: "Login fails",
            // });
        });

    }

    function me() {
        LoginService.me()
            .then((response) => {
                dispatch({
                    type: LOGIN_ME,
                    payload: response.data,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function logout() {
        dispatch({
            type: LOGOUT,
        });
    }

    // const loginContext = {}

    return (
        <LoginContext.Provider
            value={{
                state,
                auth,
                logout,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};
