import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputTime} from "../../services/config";
import Pagination from "react-js-pagination";
import {getBonusReport} from "../../reducers/tools/Report";
import CurrencyFormat from "react-currency-format";


const Bonus = () => {

    const dispatch = useDispatch();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 0)));
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [username, setUsername] = useState("");


    const bonusList = useSelector((state) => state.report.bonusReport);

    useEffect(() => {
        dispatch(getBonusReport(1, 15, moment(selectedStartDate).format(), moment(selectedEndDate).format(), username));
    }, [dispatch])


    function handleCallBackDateRang(start, end, label) {
        setSelectedStartDate(moment(start).format());
        setSelectedEndDate(moment(end).format());
    }

    const handlePageSizeChange = (pageSize) => {
        setItemPerPage(pageSize)
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getBonusReport(1, pageSize, start, end, username));
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getBonusReport(pageNumber, itemPerPage, start, end, username));
    }

    function onSubmit() {
        const start = moment(selectedStartDate).format();
        const end = moment(selectedEndDate).format();
        dispatch(getBonusReport(1, itemPerPage, start, end, username));
    }


    return (
        <>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>รายงานการรับโบนัส</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">
                                รายงานการรับโบนัส
                              </span>
                        </div>
                        <a
                            href="/#"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card mb-4">
                    <table className="table table-header">
                        <thead>
                        <tr>
                            <th className="text-center" width="180">บัญชีเกมส์</th>
                            <th className="text-center">จำนวนรายการ</th>
                            <th className="text-center">ยอดรวมเงินฝาก</th>
                            <th className="text-center">ยอดรวมโบนัส</th>
                            <th className="text-center">รวม</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-center">
                                <div className="h4 mb-0">
                                    รวม
                                </div>
                            </td>
                            <td className="text-center">
                                <span className="h1 mb-0"><CurrencyFormat value={bonusList.total} displayType={'text'} thousandSeparator={true}/></span>
                            </td>
                            <td className="text-center">
                                <span className="h1 mb-0"><CurrencyFormat value={bonusList.totalAmount - bonusList.totalBonus} displayType={'text'} thousandSeparator={true}/></span>
                            </td>
                            <td className="text-center">
                                <span className="h1 mb-0"><CurrencyFormat value={bonusList.totalBonus} displayType={'text'} thousandSeparator={true}/></span>
                            </td>
                            <td className="text-center">
                                <span className="h1 mb-0"><CurrencyFormat value={bonusList.totalAmount} displayType={'text'} thousandSeparator={true}/></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="form-inline">
                            <div className="form-group mr-2">
                                <label className="pr-1">รายการต่อหน้า</label>
                                <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div className="form-group mr-2">
                                <input type="search" className="form-control form-control-sm" value={username} onChange={e => setUsername(e.target.value)} placeholder="ค้นหาจาก Username" />
                            </div>
                            <div className="form-group mr-2">
                                <DateRangePicker
                                    initialSettings={initConfigInputTime}
                                    onCallback={handleCallBackDateRang}
                                >
                                    <input type="text" style={{width: "164px"}} className="form-control form-control-sm"/>
                                </DateRangePicker>
                            </div>
                            <div className="form-group mr-2">
                                <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th>ผู้ใช้งาน</th>
                                <th>จากบัญชี</th>
                                <th>เข้าบัญชี</th>
                                <th className="text-center">จำนวน</th>
                                <th className="text-center">วันเวลา</th>
                                <th className="text-center">สถานะ</th>
                                <th>หมายเหตุ</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bonusList.data.length > 0 ? bonusList.data.map(item => (
                                <tr key={item.id}>
                                    <td>{item.userId}</td>
                                    <td>{item.fromAccId.fullName}</td>
                                    <td>{item.toAccId.fullName}</td>
                                    <td className="text-center">{item.amount}</td>
                                    <td className="text-center">{item.txnTime}</td>
                                    <td className="text-center">
                                        {item.status === 'success' &&
                                        <span className="trans-status badge badge-pill badge-success">Success</span>}

                                        {item.status === 'pending' &&
                                        <span className="trans-status badge badge-pill badge-warning">Pending</span>}

                                        {item.status === 'canceled' &&
                                        <span className="trans-status badge badge-pill badge-danger">Cancel</span>}

                                        {(item.status !== 'pending' || item.status !== 'success') ??
                                        <span className="trans-status badge badge-pill badge-danger">{item.status}</span>}
                                    </td>
                                    <td>{item.note}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={15} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={bonusList.pageSize}
                    totalItemsCount={bonusList.total > 0 ? bonusList.total : 0}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                />
            </div>
        </>
    )
}


export default withRouter(Bonus)
