import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalContext } from "../../context/ModalContext";
import { confirmWithdraw } from "../../reducers/tools/Transaction";
import { useDispatch } from "react-redux";

const ConfirmWithdraw = (props) => {
  const dispatch = useDispatch();
  const { pageNumber, itemPerPage, start, end, txnId, username, amount } = props;
  const { handleClose } = useContext(ModalContext);
  const [amountValue, setAmount] = useState(0);

  useEffect(() => {
    setAmount(amount)
  }, [dispatch, amount]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group mb-2 row">
            <label className="col-sm-4 col-form-label">Username:</label>
            <div className="col-sm-8 col-form-label">
              {username}
            </div>
          </div>
          <div className="form-group mb-2 row">
            <label className="col-sm-4 col-form-label">Amount:</label>
            <div className="col-sm-8 col-form-label">
              {amount}
            </div>
          </div>
        </div>
      </div>

      <Modal.Footer className="row">
        <button
          disabled={!amountValue}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            let camountValue = confirm("Are you sure do this?");
            if (camountValue) {
              dispatch(
                confirmWithdraw(
                  txnId,
                  handleClose,
                  pageNumber,
                  itemPerPage,
                  start,
                  end
                )
              );
            }
          }}
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </>
  );
};

export default ConfirmWithdraw;
