import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {GameContext} from "../../context/GameContext";
import {UserContext} from "../../context/UserContext";
import LocalStorageService from "../../services/LocalStorageService";

const localStorageService = LocalStorageService.getService()

const initDataState = {
    username: "",
    password: "",
    name: "",
    games: [],
    submited: false,
}

const NewUser = () => {
    const me = localStorageService.getMe()
    const {handleClose} = useContext(ModalContext)
    const {createUser, actionStatus} = useContext(UserContext)
    const {getGames, games} = useContext(GameContext)
    const [data, setData] = useState(initDataState)

    useEffect(() => {
        // getGames()
    }, [])

    const handleInputChange = event => {
        let value = event.target.value
        setData({
            ...data,
            [event.target.name]: value
        });
    };

    const handleGame = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        if (checked) {
            setData({
                ...data,
                games: [...data.games, value]
            })
        } else {
            const games = data.games;
            var index = games.indexOf(value)
            if (index !== -1) {
                games.splice(index, 1);
                setData({
                    ...data,
                    games: [games]
                })
            }
        }
    }

    const submit = () => {
        setData({
            ...data,
            submited: true,
        })
        createUser(data)
    }
    return (
        <>
            {actionStatus == "create_error" && (
                <div className="alert alert-danger">ผิดพลาด: กรุณาตรวจสอบข้อมูลของท่านอีกครั้ง</div>
            )}
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Username</label>
                        <div className="col-sm-9">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{me.prefix}</span>
                                </div>
                                <input type="text" className="form-control form-control-sm" name="username" onChange={handleInputChange}/>
                            </div>
                            {/*<input type="text" name="username" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>*/}
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Password</label>
                        <div className="col-sm-9">
                            <input type="password" name="password" onChange={handleInputChange} autoComplete="new-password" className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Name</label>
                        <div className="col-sm-9">
                            <input type="text" name="name" onChange={handleInputChange} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    {/* <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label">Games</label>
                        <div className="col-sm-9">
                            <ul className="list-unstyled list-item pt-2">
                                {games.length > 0 ? (
                                    <>
                                        {games.map(game => (
                                            <li key={game.id}>
                                                <input value={game.shortName} onClick={e => handleGame(e)} type="checkbox"/>
                                                <label>{game.name}</label></li>
                                        ))}
                                    </>
                                ) : (
                                    <>No Game</>
                                )}
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && actionStatus == 0} onClick={submit} className="btn btn-primary">Save changes</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </>
    )
}

export default NewUser;
