import {USER_ADD, USER_ADD_STATUS, USER_EDIT, USER_FETCH, USER_UPDATE_BALANCE, USER_UPDATE_KEY} from "../actions/type";

export default function UserReducer(state, action) {
    switch (action.type) {
        case USER_FETCH:
            return {
                ...state,
                users: action.payload.users,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                count: action.payload.count,
            };
        case USER_ADD:
            const ag = {
                ...state,
                users: [...state.users, action.payload]
            };
            return ag;
        case USER_ADD_STATUS:
            return {
                ...state,
                actionStatus: action.payload
            };
        case USER_EDIT:
            const updatedUser = action.payload;

            const updatedUsers = state.users.map(user => {
                if (user.id === updatedUser.id) {
                    return updatedUser;
                }
                return user;
            });

            return {
                ...state,
                users: updatedUsers
            };
        case USER_UPDATE_KEY:
            const updatedKeyUser = action.payload;
            const updatedKeyUsers = state.users.map(user => {
                if (user.id === updatedKeyUser.id) {
                    user[updatedKeyUser.key] = updatedKeyUser.value;
                    user['updated'] = new Date();
                    return user;
                }
                return user;
            });

            return {
                ...state,
                users: updatedKeyUsers
            };
        default:
            return state;
    }
}
