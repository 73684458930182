import {createSlice} from "@reduxjs/toolkit";
import {NotificationManager} from "react-light-notifications";
import {
    fetchDepositBankOfAgent,
    fetchWithdrawBankOfAgent,
} from "./AgentBankaccount";

import {
    ApiRegisterNew,
    ApiConfirmOTP,
    ApiSetDeviceId,
    ApiGetAppList,
    ApiGetAppInfo,
    ApiDisableApp,
    ApiChangePIN,
    ApiEnableDeposit,
    ApiDisableDeposit,
    ApiChangePhone,
    ApiDefaultWithdraw, ApiSettingDeposit, ApiSettingWithdraw, ApiGetSettingDeposit, ApiGetSettingWithdraw
} from "../../api/agent";

const initStateMobileApp = {
    appList: [],
    deviceInfo: {
        userId: "",
        deviceId: "",
        mobileNo: "",
        brand: "",
        model: "",
        os: "",
        jailbreak: "",
        tcAccept: null,
        registerDate: "",
        deviceToken: "",
        tokenType: "",
        tilesVersion: null,
        tag: null,
    },
    appInfo: {
        appId: "",
        ref: "",
    },
    depositSetting: {},
    withdrawSetting: {},
};

const MobileAppSlice = createSlice({
    name: "MobileAppSlice",
    initialState: initStateMobileApp,
    reducers: {
        appMobList: (state, action) => {
            const {data, bankNumber} = action.payload;

            const bankaccountOfApp = data.filter((m) => m.number === bankNumber);

            return Object.assign(state, {
                appList: bankaccountOfApp,
            });
        },
        setBankBalance: (state, {payload}) => {
            // console.log(payload)
            return {
                ...state,
                appList: state.appList.map(v => v.appId == payload.id ? {...v, balance: payload.balance} : v)
            }
        },
        registerMobNew: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                appInfo: data,
            });
        },
        confirmOtpDone: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                deviceInfo: data,
            });
        },
        SetDeviceIdDone: (state, action) => {
            return Object.assign(state, {
                deviceInfo: initStateMobileApp.deviceInfo,
                appInfo: initStateMobileApp.appInfo,
            });
        },
        getDepositSetting: (state, action) => {
            const {data} = action.payload;
            // console.log(action.payload)
            return Object.assign(state, {
                depositSetting: data,
            });
        },
        getWithdrawSetting: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                withdrawSetting: data,
            });
        },
    },
});
export default MobileAppSlice;

export const RegisterAppBank = (model) => {
    return (dispatch) => {
        return ApiRegisterNew(model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Next to enter OTP and PIN",
                    });

                    dispatch(MobileAppSlice.actions.registerMobNew({data: items.data}));
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const ConfirmOTPAppBank = (appId, ref, model) => {
    return (dispatch) => {
        return ApiConfirmOTP(appId, ref, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Confirm OTP Success",
                    });

                    dispatch(MobileAppSlice.actions.confirmOtpDone({data: items.data}));
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const SetDeviceId = (appId, model, handleClose) => {
    return (dispatch) => {
        return ApiSetDeviceId(appId, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Finish setting app",
                    });

                    dispatch(MobileAppSlice.actions.SetDeviceIdDone());

                    handleClose();
                }
            })
            .catch((e) => {
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const AppList = (bankNumber) => {
    return (dispatch) => {
        return ApiGetAppList()
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        MobileAppSlice.actions.appMobList({
                            data: items.data,
                            bankNumber: bankNumber,
                        })
                    );
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const DisableApp = (appId, numberAccount) => {
    return (dispatch) => {
        return ApiDisableApp(appId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Success for disable app",
                    });

                    dispatch(AppList(numberAccount));
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const ChangePIN = (appId, model, handleClose) => {
    return (dispatch) => {
        return ApiChangePIN(appId, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Change PIN success",
                    });

                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());

                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const ChangePhone = (appId, model, handleClose) => {
    return (dispatch) => {
        return ApiChangePhone(appId, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Change PIN success",
                    });

                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());

                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const EnableDeposit = (appId, numberAccount, depositId) => {
    console.log("dispatch enable deposit:", appId, numberAccount, depositId);
    return (dispatch) => {
        return ApiEnableDeposit(appId, depositId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Enabled deposit success",
                    });

                    dispatch(AppList(numberAccount));
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const DisableDeposit = (appId, numberAccount, depositId) => {
    return (dispatch) => {
        return ApiDisableDeposit(appId, depositId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Disable deposit success",
                    });

                    dispatch(AppList(numberAccount));
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const SetDefaultWithdraw = (appId, number, id) => {
    return (dispatch) => {
        return ApiDefaultWithdraw(appId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {

                    dispatch(AppList(number));

                    NotificationManager.success({
                        title: "Success",
                        message: "Set default withdraw success",
                    });

                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const ChangeSettingDeposit = (appId, model, handleClose) => {
    return (dispatch) => {
        return ApiSettingDeposit(appId, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Update Deposit Setting success",
                    });
                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const ChangeSettingWithdraw = (appId, model, handleClose) => {
    return (dispatch) => {
        return ApiSettingWithdraw(appId, model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Update Withdraw Setting success",
                    });
                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};


export const GetDepositSetting = (appId) => {
    return (dispatch) => {
        return ApiGetSettingDeposit(appId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                // console.log(items.data)
                if (items.status === 200) {
                    dispatch(
                        MobileAppSlice.actions.getDepositSetting(items)
                    );
                }
            })
            .catch((e) => {
                dispatch(
                    MobileAppSlice.actions.getDepositSetting({})
                );
            });
    };
};

export const GetWithdrawSetting = (appId) => {
    return (dispatch) => {
        return ApiGetSettingWithdraw(appId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        MobileAppSlice.actions.getWithdrawSetting(items)
                    );
                }
            })
            .catch((e) => {
                dispatch(
                    MobileAppSlice.actions.getWithdrawSetting({})
                );
            });
    };
};
