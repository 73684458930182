import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { userChilds, UserLoadChilds } from "../../reducers/tools/AccountUsers";
import { getTurnoverReport } from "../../reducers/tools/Report";
import LocalStorageService from "../../services/LocalStorageService";
import { rowRuning } from "../../services/functions";
import Moment from "react-moment";

const UserChilds = ({ userId }) => {
  const dispatch = useDispatch();
  const childs = useSelector(userChilds);
  const localStorageService = LocalStorageService.getService();
  const me = localStorageService.getMe();
  const turnover = useSelector((state) => {
    return state.report.turnoverReport || [];
  });

  console.log(turnover);

  useEffect(() => {
    if (typeof userId !== `undefined`) {
      dispatch(UserLoadChilds(me.prefix, userId, 1));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    // console.log(childs)
  }, [childs]);

  useEffect(() => {
    if (typeof userId !== "undefined") {
      dispatch(getTurnoverReport(userId));
    }
  }, [userId, dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(UserLoadChilds(me.prefix, userId, pageNumber));
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">บริการ</th>
            <th scope="col">ยอดเดิมพัน</th>
          </tr>
        </thead>
        <tbody>
          {turnover.detail
            ? Object.keys(turnover.detail).map((service, index) => (
                <tr>
                  <th scope="row" key={index}>
                    {service}
                  </th>
                  <th scope="row">{turnover.detail[service]}</th>
                </tr>
              ))
            : "Loading..."}
          <tr>
            <th scope="row">ยอดเดิมพันทั้งหมด</th>
            <th scope="row">{turnover ? turnover.total : "Loading..."}</th>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default UserChilds;
