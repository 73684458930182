import {createSlice} from "@reduxjs/toolkit";
import {NotificationManager} from "react-light-notifications";
import {AddPromotion, GetPromotions, StatusUpdatePromotion, UpdatePromotion} from "../../api/promotion";
import {useContext} from "react";
import {GlobalContext} from "../../context/GlobalContext";
import GlobalSlice from "./Global";


const AgentPromotionSlice = createSlice({
    name: "AgentPromotionSlice",
    initialState: {
        type: [
            {name: 'ฝากทั้งวัน', code: 'DEFAULT', n: 1},
            {name: 'ฝากแรกของวัน', code: 'FIRST_DATE', n: 2},
            {name: 'นาทีทอง', code: 'BONUS_TIME', n: 3},
            {name: 'สมัครสมาชิกใหม่', code: 'NEW_ACCOUNT', n: 4},
            {name: 'คืนยอดเสีย', code: 'RETURN_LOSS', n: 6},
            // {name: 'ชวนเพือน', code: 'INVITE', n: 5},
        ],
        bonus: [
            {name: 'เปอร์เซ็น (%)', code: 'PERCENT', n: 1},
            {name: 'ค่าคงที่', code: 'FIX', n: 2},
        ],
        promotions: [],
        loading: 0,
    },
    reducers: {
        getPromotions: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                promotions: data,
            });
        },

    },
});

export default AgentPromotionSlice;

export const AddPromotionOfAgent = (model, prefix, handleClose) => {

    return (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        return AddPromotion(model, prefix)
            .then(response => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Add Promotion success",
                    });
                    dispatch(fetchPromotionOfAgent(prefix))
                    handleClose();
                }
                dispatch(GlobalSlice.actions.setOnSuccess())
            })
            .catch((e) => {
                console.log(e.message);
                NotificationManager.error({
                    title: "Error",
                    message: "Unknow error",
                });
                dispatch(GlobalSlice.actions.setOnSuccess())
            });
    }
}

export const UpdatePromotionOfAgent = (id, model, prefix, handleClose) => {

    return (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        return UpdatePromotion(id, model, prefix)
            .then(response => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Update Promotion success",
                    });
                    dispatch(fetchPromotionOfAgent(prefix))
                    handleClose();
                }
                dispatch(GlobalSlice.actions.setOnSuccess())
            })
            .catch((e) => {
                console.log(e.message);
                NotificationManager.error({
                    title: "Error",
                    message: "Unknow error",
                });
                dispatch(GlobalSlice.actions.setOnSuccess())
            });
    }
}

export const fetchPromotionOfAgent = (prefix) => {
    return (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        return GetPromotions(prefix)
            // .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        AgentPromotionSlice.actions.getPromotions({data: items.data})
                    );
                }
                dispatch(GlobalSlice.actions.setOnSuccess())
            }).catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
                dispatch(GlobalSlice.actions.setOnSuccess())
            });
    }
}

export const fetchPromotionOfAgentNoLoading = (prefix) => {
    return (dispatch) => {
        return GetPromotions(prefix)
            // .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        AgentPromotionSlice.actions.getPromotions({data: items.data})
                    );
                }
            }).catch((e) => {
                console.log(e);
            });
    }
}

export const UpdatePromotionStatusOfAgent = (id, prefix) => {

    return (dispatch) => {
        dispatch(GlobalSlice.actions.setOnLoading())
        return StatusUpdatePromotion(id, prefix)
            .then(response => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Update Promotion Status success",
                    });
                    dispatch(fetchPromotionOfAgent(prefix))
                }
                dispatch(GlobalSlice.actions.setOnSuccess())
            })
            .catch((e) => {
                console.log(e.message);
                NotificationManager.error({
                    title: "Error",
                    message: "Unknow error",
                });
                dispatch(GlobalSlice.actions.setOnSuccess())
            });
    }
}
