import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {userChilds, UserLoadChilds} from "../../reducers/tools/AccountUsers";
import LocalStorageService from "../../services/LocalStorageService";
import {rowRuning} from "../../services/functions";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";

const UserChilds = ({userId}) => {
    const dispatch = useDispatch()
    const childs = useSelector(userChilds)

    const localStorageService = LocalStorageService.getService()
    const me = localStorageService.getMe();

    useEffect(() => {
        if (typeof userId !== `undefined`) {
            dispatch(UserLoadChilds(me.prefix, userId, 1))
        }
    }, [dispatch, userId])

    useEffect(() => {
        // console.log(childs)
    }, [childs])

    const handlePageChange = (pageNumber) => {
        dispatch(UserLoadChilds(me.prefix, userId, pageNumber))
    }


    return (
        <>
            <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered">
                    <thead>
                    <tr>
                        <th className="text-center" width={60}>ลำดับ</th>
                        <th className="text-center" width={180}>ชื่อผู้ใช้</th>
                        <th className="text-center" width={150}>วันที่สร้าง</th>
                    </tr>
                    </thead>
                    <tbody>
                    {typeof childs?.users !== `undefined` && childs?.users?.length > 0 ? childs?.users?.map((user, i) => (
                        <tr key={user.id}>
                            <td className="text-center">{rowRuning(i, childs?.page, childs?.pageSize)}</td>
                            <td>
                                {user.username}
                            </td>
                            <td className="text-center">
                                <Moment format="YYYY-MM-DD HH:mm">
                                    {user.createdTime}
                                </Moment>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={20} className="text-center">ไม่พบข้อมูล</td>
                        </tr>
                    )}

                    </tbody>
                </table>
            </div>

            <div className="my-4">
                <Pagination
                    activePage={childs?.page ?? 1}
                    itemsCountPerPage={childs?.pageSize ?? 15}
                    totalItemsCount={childs?.count ?? 0}
                    pageRangeDisplayed={5}
                    onChange={e => handlePageChange(e)}
                />
            </div>
        </>
    )
}

export default UserChilds
