import React, {
    createContext,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useReducer,
    useState,
} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext, ModalProvider} from "../../context/ModalContext";
import uuid from "react-uuid";

const ModalApp = ({component, content}, ref) => {
    const [modal, setModal] = useState(false);
    const [modalClose, setModalClose] = useState(false);
    const {closeModal, dispatchModal} =
        useContext(ModalContext);

    useImperativeHandle(
        ref,
        () => {
            return {
                show: () => {
                    handleShow();
                },
            };
        },
        []
    );

    const handleShow = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
        dispatchModal({
            type: "MODAL_CLOSE",
            payload: false,
        });
    };

    useEffect(() => {
        if (closeModal) {
            handleClose();
        }
    }, [closeModal]);

    return (
        <div>
            <Modal
                show={modal}
                size={content.size}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{content.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalProvider
                        handleClose={handleClose}
                        dispatchModal={dispatchModal}
                        closeModal={closeModal}
                    >
                        {component}
                    </ModalProvider>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default forwardRef(ModalApp);
