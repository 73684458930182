import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {AgentContext} from "../../context/AgentContext";
import uuid from "react-uuid";
import CurrencyFormat from "react-currency-format";

export default function Topup({agent, type}) {

    const [data, setData] = useState({
        agentId: agent.id,
        amount: 0,
        ref: uuid(),
        note: "",
        submited: false
    });

    const {deposit, revoke, createAgentStatus, setCreatAgentStatus} = useContext(AgentContext)
    const {handleClose} = useContext(ModalContext)

    useEffect(() => {
        setCreatAgentStatus(0)
    }, []);


    const handleInputChange = event => {
        let value = event.target.value;
        if (event.target.name == 'amount')
            value = parseFloat(value);
        setData({
            ...data,
            [event.target.name]: value
        });
    };

    const submit = () => {
        setCreatAgentStatus()
        setData({
            ...data,
            submited: true
        })
        if (type == 'deposit') {
            deposit(data)
        } else {
            revoke(data)
        }
    }

    return (
        <>
            {createAgentStatus == 'topup_error' && (
                <div className="alert alert-danger">ผิดพลาด กรุณาลองใหม่อีกครั้ง</div>
            )}
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">สมาชิก</label>
                <div className="col-md-8">
                    <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={agent.username}/>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">แต้มที่เหลือ</label>
                <div className="col-md-8">
                    <div className="pt-2">
                        <CurrencyFormat value={agent.credit} displayType={'text'} thousandSeparator={true}/>
                    </div>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">จำนวน</label>
                <div className="col-md-8">
                    <input type="text" name="amount" onChange={handleInputChange} className="form-control"/>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4">หมายเหตุ</label>
                <div className="col-md-8">
                    <textarea name="note" onChange={handleInputChange} value={data.note} className="form-control" rows="3"></textarea>
                </div>
            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && createAgentStatus == 0} onClick={submit} className="btn btn-primary">บันทึก</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>ปิดหน้าต่าง</button>
            </Modal.Footer>
        </>
    );
}
