import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ModalContext} from "../../context/ModalContext";
import {Delete} from "@material-ui/icons";
import {Modal} from "react-bootstrap";
import {AddPromotionOfAgent, UpdatePromotionOfAgent} from "../../reducers/tools/AgentPromotion";
import {UpdatePromotion} from "../../api/promotion";
import {NotificationManager} from "react-light-notifications";

const TimeOptionSelector = ({i, selected}) => {
    const clock = ("0" + i).slice(-2) + ":00"
    return (
        <option value={clock} selected={selected == clock}>{clock}</option>
    )
}


const OptionsTable = ({options, delOption}) => {
    const MeInfo = useSelector((state) => {
        return state.accountUser?.accountInfo || {};
    });
    return (
        <table className="table table-striped table-bordered">
            <thead>
            <tr>
                <th className="text-center">ต่ำสุด</th>
                <th className="text-center">สูงสุด</th>
                <th className="text-center">โบนัส</th>
                <th className="text-center" width={100}></th>
            </tr>
            </thead>
            <tbody>
            {options && options.length > 0 ? (
                <>
                    {options.map((item, index) => (
                        <tr>
                            <td className="text-center">{item.min}</td>
                            <td className="text-center">{item.max}</td>
                            <td className="text-center">{item.bonus}</td>
                            <td className="text-center">
                                {MeInfo.role === "agent" && (
                                    <button onClick={() => delOption(index)} className="btn btn-danger btn-sm"><Delete/>
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </>
            ) : (
                <tr>
                    <td colSpan={4} className="text-center">กรุณาเพิ่มเงื่อนไข</td>
                </tr>
            )}
            </tbody>
        </table>
    )
}


const PromotionForm = ({promotion}) => {
    const dispatch = useDispatch();
    const {handleClose} = useContext(ModalContext);
    const MeInfo = useSelector((state) => {
        return state.accountUser.accountInfo || {};
    });


    let TypeList = useSelector((state) => {
        return state.agentPromotion?.type || [];
    });
    let BonusList = useSelector((state) => {
        return state.agentPromotion?.bonus || [];
    });
    let [inputData, setInputData] = useState({})
    let [options, setOptions] = useState([]);
    let [showTime, setShowTime] = useState(false);
    let [isStakeTurn, setIsStakeTurn] = useState(false);
    let [description, setDescription] = useState(false);

    const defaultOption = {
        min: '',
        max: '',
        bonus: '',
    }
    let [option, setOption] = useState(defaultOption);


    useEffect(() => {
        if (promotion) {
            let dt = {...promotion}
            setOptions(dt.options)
            delete dt.options
            // console.log(dt)
            setInputData(dt)

            if (dt.type == 3) {
                setShowTime(true)
            }

        }
    }, [promotion])
    
    const handleSetData = (e) => {
        const {value, name} = e.target;

        let v = value

        if (isNaN(v) && name !== `name` ) {
            v = 0
        }

        switch (name) {
            case 'maxBonus':
            case 'maxDeposit':
            case 'minDeposit':
            case 'maxWithdraw':
            case 'minWithdraw':
            case 'turnOver':
            case 'type':
            case 'bonusType':
                v = v * 1
                break
        }

        if (e.target.name == "type") {
            if (v == 3) {
                setShowTime(true)
            } else {
                setShowTime(false)
            }
        }

        setInputData({
            ...inputData,
            [e.target.name]: v
        })

    }

    const handleSetTurn = (e) => {
        let isChecked = e.target.checked
        setIsStakeTurn(isChecked)

        setInputData({
            ...inputData,
            [e.target.name]: isChecked
        })
    }

    const handleSetDesc = (e) => {
        const desc = e.target.value
        setDescription(desc)

        setInputData({
            ...inputData,
            [e.target.name]: desc
        })
    }

    const handleChangeFile = (event) => {
        let files = event.target.files;
        if (typeof files[0]?.size == 'undefined') {
            setInputData({
                ...inputData,
                base64Img: ''
            })
            return false
        }
        if (files[0].size / 1024 > 2048) {
            NotificationManager.error({
                title: "Error",
                message: "ขนาดไฟล์ใหญ่เกินกว่าที่กำหนด",
            });
            return false
        }

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            // console.log(e.target.result)
            setInputData({
                ...inputData,
                base64Img: e.target.result
            })
        }
    }


    const handleSetOption = (e) => {
        
        let v = e.target.value * 1;
        setOption({
            ...option,
            [e.target.name]: v
        })
    }

    const delOption = (i) => {
        let optionsArray = [...options]
        optionsArray.splice(i, 1)
        setOptions(optionsArray)
    }

    const handleSetOptions = (e) => {
        if (option.max == "" || option.min == "" || option.bonus == "") {
            return;
        }
        if (typeof e == 'undefined' || e.charCode == 13) {
            setOption(defaultOption)
            setOptions([
                ...options, option
            ])
        }
    }

    const submit = () => {
        const model = {
            ...inputData,
            options: options
        }
        if (model.options.length < 1) {
            NotificationManager.warning({
                title: "Error",
                message: "กรุณาระบุเงื่อนไข",
            });
            return
        }
        if (typeof inputData.id != 'undefined') {
            dispatch(UpdatePromotionOfAgent(inputData.id, model, MeInfo.prefix, handleClose))
        } else {
            dispatch(AddPromotionOfAgent(model, MeInfo.prefix, handleClose))
        }
    }

    // const {id} = props?.id
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group prom-type">
                        <label>ประเภทโปรโมชั่น</label>
                        <select name="type" onChange={e => handleSetData(e)} className="form-control">
                            <option value={0}>เลือก</option>
                            {TypeList.map(({n, name}, index) => (
                                <option key={n} selected={inputData?.type == n} value={n}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>ชื่อโปรโมชั่น</label>
                        <input type="text" name="name" value={inputData?.name} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
            </div>
            {showTime && (
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>เวลาเริ่มต้น</label>
                            <select name="startTime" onChange={e => handleSetData(e)} className="form-control">
                                {[...Array(24)].map((x, i) =>
                                    <TimeOptionSelector selected={inputData?.startTime} i={i}/>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>เวลาสิ้นสุด</label>
                            <select name="endTime" onChange={e => handleSetData(e)} className="form-control">
                                {[...Array(24)].map((x, i) =>
                                    <TimeOptionSelector selected={inputData?.endTime} i={i}/>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>ประเภทโบนัส</label>
                        <select name="bonusType" onChange={e => handleSetData(e)} className="form-control">
                            <option value={0}>เลือก</option>
                            {BonusList.map(({n, name}, index) => (
                                <option key={n} selected={inputData?.bonusType == n} value={n}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-6 d-none">
                    <div className="form-group">
                        <label>โบนัส</label>
                        <input type="text" name="bonus" value={inputData?.bonus} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>โบนัสสูงสุด</label>
                        <input type="text" name="maxBonus" value={inputData?.maxBonus} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>ฝากต่ำสุด</label>
                        <input type="text" name="minDeposit" value={inputData?.minDeposit} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>ฝากสูงสุด</label>
                        <input type="text" name="maxDeposit" value={inputData?.maxDeposit} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="form-group">
                        <label>ถอนเงินขั้นต่ำ</label>
                        <input type="text" name="minWithdraw" value={inputData?.minWithdraw} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>ถอนเงินสูงสุด</label>
                        <input type="text" name="maxWithdraw" value={inputData?.maxWithdraw} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group">
                        <label>TURN OVER</label>
                        <input type="text" name="turnOver" value={inputData?.turnOver} onChange={e => handleSetData(e)} className="form-control"/>
                    </div>
                </div>
                <div className="col-lg-3 d-flex align-items-center">
                    <div className="form-group d-flex align-items-center my-0" style={{'width': 'fit-content'}}>
                        <input type="checkbox" name="isStakeTurn" checked={inputData?.isStakeTurn} onChange={(e) => handleSetTurn(e)} className="form-control mr-2" style={{'width':'auto'}}/>
                        <label className="m-0">Turnover แบบยอดแทง</label>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>รายละเอียด</label>
                        <textarea type="text" name="description" value={inputData?.description} onChange={e => handleSetDesc(e)} className="form-control"/>
                    </div>
                </div>
                {MeInfo.role === "agent" && (
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>เลือกรูปภาพ (ไม่เกิน 200KB)</label>
                            <input type="file" name="image" onChange={e => handleChangeFile(e)} className="form-control"/>
                        </div>
                        {inputData?.base64Img && (
                            <>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <img src={inputData?.base64Img} className="w-100" alt=""/>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                )}
            </div>
            <hr/>
            <div className="h5">เงื่อนไข</div>
            {MeInfo.role === "agent" && (
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label>ต่ำสุด</label> <span className="text-muted"><i>e.g. "100"</i></span>
                            <input type="text" name="min" value={option.min} className="form-control" onChange={e => handleSetOption(e)}/>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label>สูงสุด</label> <span className="text-muted"><i>e.g. "200"</i></span>
                            <input type="text" name="max" value={option.max} className="form-control" onChange={e => handleSetOption(e)}/>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label>โบนัส </label> <span className="text-muted"><i>e.g. "50"</i></span>
                            <input type="text" name="bonus" onKeyPress={e => handleSetOptions(e)} value={option.bonus} className="form-control" onChange={e => handleSetOption(e)}/>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <button type="button" onClick={e => handleSetOptions()} className="btn btn-success" style={{'margin-top': '26px'}}>เพิ่ม</button>
                        </div>
                    </div>
                </div>
            )}
            <OptionsTable delOption={delOption} options={options}/>

            <Modal.Footer>
                {MeInfo.role === "agent" && (
                    <button type="button" className="btn btn-primary" onClick={submit}>
                        Save changes
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    Close
                </button>
            </Modal.Footer>
        </>
    )
}

export default PromotionForm
