import {GAME_FETCH} from "../actions/type";

export default function GameReducer(state, action) {
    switch (action.type) {
        case GAME_FETCH:
            //console.log(action.payload)
            return {
                ...state,
                games: action.payload
            };
        default:
            return state;
    }
}
