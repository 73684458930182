import * as http from "../http-common";

export function GetMe() {
    return http.get(`operator/me`);
}

export const GetUser = (username) => {
    return http.get(`user/username/${username}`)
}
export const ChangePassword = (data) => {
    return http.patch(`operator/me/password`, data)
}
