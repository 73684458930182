import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchWithdrawTransaction} from '../../reducers/tools/Transaction';
import ModalApp from "../../components/Modal/ModalApp";
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ConfirmWithdraw from '../../components/Transaction/ConfirmWithdraw';
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";

const Withdraw = ({status}) => {
    const dispatch = useDispatch();

    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);

    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: "",
        size: "",
    });
    const modalRef = useRef(null);


    const withdrawList = useSelector((state) => state.trans.withdrawList);

    useEffect(() => {
        dispatch(fetchWithdrawTransaction(1, 100, '', '', status));
    }, [dispatch])

    function onOpenModalApp(content, component) {
        if (modalRef.current) {
            setModalComponent(component);
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            });
            modalRef.current.show();
        }
    }


    return (

        <div className="table-responsive">
            <ModalApp
                component={modalComponent}
                content={modalConent}
                ref={modalRef}
            />
            <table className="table table-sm table-striped table-bordered">
                <thead>
                <tr>
                    <th width={150}>วันเวลา</th>
                    <th>ผู้ใช้งาน</th>
                    <th>รายละเอียด</th>
                    <th className="text-right">จำนวน</th>
                    <th>สถานะ</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {withdrawList?.data.length > 0 ? withdrawList?.data.map(item => (
                    <tr key={item.id}>
                        <td className="text-center"><Moment format="YYYY-MM-DD HH:mm">{item.txnTime}</Moment></td>
                        <td>{item.userId}</td>
                        <td>
                            <div><strong>จากบช :</strong> {item.fromAccId.fullName}</div>
                            <div><strong>เข้าบช :</strong> {item.toAccId.fullName}</div>
                            <div><strong>หมายเหตุ :</strong> {item.note}</div>
                        </td>
                        <td className="text-right">
                            <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            {item.status === 'success' &&
                                <span className="trans-status badge badge-pill badge-success">Success</span>}
                            {item.status === 'pending' &&
                                <span className="trans-status badge badge-pill badge-warning">Pending</span>}
                            {item.status === 'failed' &&
                                <span className="trans-status badge badge-pill badge-danger">Failed</span>}

                            {item.status === 'canceled' &&
                                <span className="trans-status badge badge-pill badge-secondary">Cancel</span>}

                            {(item.status !== 'pending' || item.status !== 'success') ??
                            <span className="trans-status badge badge-pill badge-danger">{item.status}</span>}
                        </td>
                        <td>
                            {(item.status === 'pending' || item.status === 'failed') && (
                                <button className="btn btn-warning btn-sm" onClick={() =>
                                    onOpenModalApp(
                                        {
                                            title: "ยืนยันรายการ",
                                            size: "sm",
                                        },
                                        <ConfirmWithdraw pageNumber={activePage} itemPerPage={itemPerPage} start={moment(selectedStartDate).format()} end={moment(selectedEndDate).format()} txnId={item.id} username={item.userId} amount={item.amount}/>
                                    )
                                }>
                                    Confirm
                                </button>)}

                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    );
};

export default Withdraw;
