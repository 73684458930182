import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import uuid from "react-uuid";
import CurrencyFormat from "react-currency-format";
import {UserContext} from "../../context/UserContext";
import {useDispatch, useSelector} from "react-redux";
import {fetchPromotionOfAgent} from "../../reducers/tools/AgentPromotion";

export default function Topup({user, type}) {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        amount: 0,
        uuid: uuid(),
        promoId: ``,
        note: "",
        submited: false
    });

    const {deposit, revoke, actionStatus, resetActionStatus} = useContext(UserContext)
    const {handleClose} = useContext(ModalContext)

    let PromotionList = useSelector((state) => {
        return state.agentPromotion?.promotions || [];
    });
    const MeInfo = useSelector((state) => {
        return state.accountUser?.accountInfo || {};
    });

    useEffect(() => {

        if (typeof MeInfo.prefix != 'undefined' && PromotionList.length < 1) {
            // alert(PromotionList.length)
            dispatch(fetchPromotionOfAgent(MeInfo.prefix))
        }
    }, [MeInfo])

    useEffect(() => {
        resetActionStatus()
    }, []);


    const handleInputChange = event => {
        let value = event.target.value;
        if (event.target.name == 'amount')
            value = parseFloat(value);
        setData({
            ...data,
            [event.target.name]: value
        });
    };

    const submit = () => {
        resetActionStatus()
        setData({
            ...data,
            submited: true,
            uuid: uuid(),
        })
        // console.log(type)
        if (type == 'deposit') {
            deposit(user.id, data)
        } else {
            revoke(user.id, data)
        }
    }

    return (
        <>
            {/*{actionStatus == 'topup_error' && (*/}
            {/*    <div className="alert alert-danger">ผิดพลาด กรุณาลองใหม่อีกครั้ง</div>*/}
            {/*)}*/}
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">สมาชิก</label>
                <div className="col-md-8">
                    <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value={user.username}/>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">แต้มที่เหลือ</label>
                <div className="col-md-8">
                    <div className="pt-2">
                        <CurrencyFormat value={user.balance} displayType={'text'} thousandSeparator={true}/>
                    </div>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4 col-form-label">จำนวน</label>
                <div className="col-md-8">
                    <input type="text" name="amount" onChange={handleInputChange} className="form-control"/>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-sm-4 col-form-label">Promotion:</label>
                <div className="col-sm-8 col-form-label">
                    <select className="form-control" name="promoId" onChange={e => handleInputChange(e)}>
                        <option value="">เลือกโปรโมชั่น</option>
                        {PromotionList?.length > 0 && PromotionList?.map(item => (
                            <>
                                {item.status && <option key={item.id} value={item.id}>{item.name}</option>}
                            </>
                        ))}
                    </select>
                </div>
            </div>
            <div className="form-group mb-2 row">
                <label className="col-md-4">หมายเหตุ</label>
                <div className="col-md-8">
                    <textarea name="note" onChange={handleInputChange} value={data.note} className="form-control" rows="3"></textarea>
                </div>
            </div>
            <Modal.Footer>
                <button type="button" disabled={data.submited && actionStatus == 0} onClick={() => submit()} className="btn btn-primary">บันทึก</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>ปิดหน้าต่าง</button>
            </Modal.Footer>
        </>
    );
}
