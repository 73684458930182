import React, {useContext, useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import {ModalContext} from "../../context/ModalContext";
import {confirmDeposit} from "../../reducers/tools/Transaction";
import {useDispatch, useSelector} from "react-redux";
import {fetchPromotionOfAgent} from "../../reducers/tools/AgentPromotion";
import {NotificationManager} from "react-light-notifications";

const ConfirmDeposit = (props) => {
    const dispatch = useDispatch();
    const {pageNumber, itemPerPage, start, end, txnId, username, amount} = props;
    const {handleClose} = useContext(ModalContext);
    const [amountValue, setAmount] = useState(0);
    const [promId, setPromId] = useState(``);
    const [userCur, setUserCur] = useState(username);
    const MeInfo = useSelector((state) => {
        return state.accountUser?.accountInfo || {};
    });


    let PromotionList = useSelector((state) => {
        return state.agentPromotion?.promotions || [];
    });

    useEffect(() => {
        // console.log(PromotionList?.length)
        if (typeof MeInfo.prefix != 'undefined' && PromotionList.length < 1) {
            dispatch(fetchPromotionOfAgent(MeInfo.prefix))
        }
    }, [MeInfo])

    useEffect(() => {
        setAmount(amount)
    }, [dispatch, amount]);

    useEffect(() => {
        if (typeof username !== `undefined` && username !== ``) {
            setUserCur(username)
        }
    }, [username]);

    const handleSetUsername = e => {
        const {value} = e.target
        let v = value.replaceAll(/\s/g, '')
        setUserCur(v)
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">Username:</label>
                        <div className="col-sm-8 col-form-label">
                            <input type="text" className="form-control" placeholder="กรุณาระบุ Username" value={userCur} onChange={e => handleSetUsername(e)}/>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">Promotion:</label>
                        <div className="col-sm-8 col-form-label">
                            <select className="form-control" onChange={e => setPromId(e.target.value)}>
                                <option value="">เลือกโปรโมชั่น</option>
                                {PromotionList?.length > 0 && PromotionList?.map(item => (
                                    <>
                                        {item.status && <option key={item.id} value={item.id}>{item.name}</option>}
                                    </>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group mb-2 row">
                        <label className="col-sm-4 col-form-label">Amount:</label>
                        <div className="col-sm-8">
                            <div className="input-group">
                                <input
                                    placeholder="Enter amount"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    pattern="[0-9]*"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) => setAmount(e.target.value)}
                                    value={amountValue}
                                    name="amount"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal.Footer className="row">
                <button
                    disabled={!amountValue}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        let camountValue = confirm("Are you sure do this?");
                        if (camountValue) {
                            if (userCur === `` || userCur === null) {
                                NotificationManager.info({
                                    title: `แจ้งเตือน`,
                                    message: `กรุณากรอก Username`
                                })
                                return
                            }
                            dispatch(
                                confirmDeposit(
                                    txnId,
                                    {
                                        username: userCur,
                                        amount: parseFloat(amountValue),
                                        promoId: promId,
                                    },
                                    handleClose,
                                    pageNumber,
                                    itemPerPage,
                                    start,
                                    end
                                )
                            );
                        }
                    }}
                >
                    Confirm
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    Cancel
                </button>
            </Modal.Footer>
        </>
    );
};

export default ConfirmDeposit;
